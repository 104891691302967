import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectCommunityData, selectStatus, selectZipCode, setZipCode } from "../../store/communityHealthSlice";
import { fetchByZipCode, fetchZipCode } from "../../store/communityHealthSlice";
import LocationIcon from "../../images/location.png";
import WeatherIcon from "../../images/weather.png";
import { useEffect } from "react";

export const Info = () => {
    const dispatch = useDispatch()
    const inputRef = useRef()
    const firstUpdate = useRef(true);
    const zipCode = useSelector(selectZipCode)
    const data = useSelector(selectCommunityData)
    const status = useSelector(selectStatus)

    useEffect(() => { //initially there is no data, automatically fetch it
        if (status === "idle") {
            dispatch(fetchZipCode())
        }
    }, [dispatch, status])

    useEffect(() => {
        if (!firstUpdate.current) { //only fetch data if zipcode changes and it's not the initial mount of Info
            dispatch(fetchByZipCode(zipCode))
        } else {
            firstUpdate.current = false
        }
        inputRef.current.value = zipCode || ""
    }, [zipCode, dispatch])

    let newDate = new Date();
    let day = newDate.toLocaleString("en-us", { weekday: "long" });
    let date = newDate.getDate();
    let month = newDate.toLocaleString("en-us", { month: "short" });

    return (
        <div className="info">
            <div className="info-left">
                {day}<br></br>
                {`${month} ${date}`}
            </div>
            <div className="info-center">
                <img src={WeatherIcon} className="weatherIcon" alt="weathericon" />
                <div id="temperatures">
                    <p className={`temp-f ${status}`}>
                        {data ? Math.round(data.temp_fahrenheit) : 0}°F
                    </p>
                    <p className={`temp-c ${status}`}>
                        {data ? Math.round(data.temp_celsius) : 0}°C
                    </p>
                </div>
            </div>
            <div className="info-right">
                <div className="zipcode-row">
                    <img
                        src={LocationIcon}
                        className="locationIcon"
                        alt="location"
                        onClick={() => dispatch(fetchZipCode())}
                    />
                    <input
                        className={(status === 'fulfilled' && !data) && 'error'}
                        ref={inputRef}
                        type="text"
                        placeholder="Zip Code"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        onClick={() => inputRef.current.classList.remove('error')}
                        onKeyUp={e => {
                            if (inputRef.current.value.length === 5) { //auto search when 5 digits is inputted
                                inputRef.current.blur() //hides keyboard
                                dispatch(setZipCode(e.target.value))
                            }
                        }}
                        maxLength="5"
                        onBlur={() => {
                            if (inputRef.current.value.length !== 5) { //incomplete query, return to original zipcode if there is one
                                if (zipCode) {
                                    inputRef.current.value = zipCode
                                    if (!data) { //zipcode was invalid and is still invalid, alert users
                                        inputRef.current.classList.add('error')
                                    }
                                } else {
                                    inputRef.current.value = ""
                                    inputRef.current.classList.add('error')
                                }

                            }
                        }}
                    />
                </div>
                <strong className={status}>{data ? data.county_name : "Enter A Valid ZIP Code"}</strong>
            </div>
        </div>
    )
}