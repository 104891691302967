import "../index.css";
import { useContext, useState } from "react";
import { QuestionContext } from "../../../context/question.context";
import { majorList } from "./MajorFeelingForm";
import EditForm from "./components/EditForm";

const SummaryForm = (props) => {
  const { resultsList, setIsQuestionOne } = useContext(QuestionContext);
  const [editMajor, setEditMajor] = useState(false);
  const [editAssociated, setEditAssociated] = useState(false);
  const [editThoughts, setEditThoughts] = useState(false);
  const [editBodySensation, setEditBodySensation] = useState(false);
  const [editAction, setEditAction] = useState(false);
  const { reachedLimit, setReachedLimit, removeOpacity, applyOpacity, opacityDark, setOpacityDark, setGlossaryOpen, setIsAlertOpen } =
    props;

  // Grabbing the 5 question answers stored from resultsList
  const primaryFeeling = resultsList.find((result) => result.question === 1);

  // get icon and background color from question 1
  const iconToDisplay = majorList.find((list) => list.mood === primaryFeeling.answer);

  const associatedFeelings = resultsList.find((result) => result.question === 2);

  const thoughts = resultsList.find((result) => result.question === 3);

  const bodySensations = resultsList.find((result) => result.question === 4);

  const actions = resultsList.find((result) => result.question === 5);

  // Close form popup when user clicks save within EditForm
  const handleEdit = (formToClose) => {
    console.log("Closing this form: ", formToClose);
    switch (formToClose) {
      case "MajorFeelingForm":
        setEditMajor(!editMajor);
        removeOpacity();
        setOpacityDark(false);
        break;
      case "AssociatedFeelingForm":
        setEditAssociated(!editAssociated);
        removeOpacity();
        setOpacityDark(false);
        break;
      case "ThoughtForm":
        setEditThoughts(!editThoughts);
        removeOpacity();
        setOpacityDark(false);
        break;
      case "BodySensationForm":
        setEditBodySensation(!editBodySensation);
        removeOpacity();
        setOpacityDark(false);
        break;
      case "ActionForm":
        setEditAction(!editAction);
        removeOpacity();
        setOpacityDark(false);
        break;
      default:
      //do nothing
    }
  };

  return (
    <div>
      <div className="ff-header-container" style ={{marginLeft: "20px"}}>
        <p className="ff-header" style={{fontSize: "25px"}}>My Feelings Flow Tracker</p>
      </div>
      <div className="ff-split"></div>

      <div className="ff-subheader-container">
        <h2 className="ff-subheader">Overview</h2>
      </div>

      <p className="ff-question">View and edit your selections before moving to the next step.</p>

      {/* Primary Feeling Section */}
      <div className="summary-primary-container" style={{ opacity: opacityDark ? 0.4 : 1 }}>
        <div className="summary-primary-header">
          <h5 className="summary-section-title">My Primary Feeling:</h5>
          {/* <a className="summary-edit-btn" onClick={() => changeQuestion(1)}>
            Edit
          </a> */}
          <a
            className="summary-edit-btn"
            onClick={() => {
              setEditMajor(!editMajor);
              setOpacityDark(true);
            }}
          >
            {/* {editMajor ? <SaveButton /> : "Edit"} */}
            Edit
          </a>
        </div>

        <div className="summary-primary-feeling-container">
          <div className="ff-icon-container" style={{ backgroundColor: `${iconToDisplay.activeColor}` }}>
            <img src={iconToDisplay.icon} className="ff-list-icon"></img>
          </div>
          <div className="primary-feeling-btn">{primaryFeeling.answer}</div>
        </div>
      </div>

      {/* Associated Feelings Section - Testing Edits within Summary Page*/}
      <div className="summary-primary-container" style={{ opacity: opacityDark ? 0.4 : 1 }}>
        <div className="summary-primary-header">
          <h5 className="summary-section-title">My Associated Feeling(s):</h5>
          <a
            className="summary-edit-btn"
            onClick={() => {
              setEditAssociated(!editAssociated);
              setOpacityDark(true);
            }}
          >
            Edit
          </a>
        </div>

        <div className="summary-primary-feeling-container">
          <ul className="summary-list">
            {associatedFeelings.answer.map((feeling, idx) => {
              return (
                <li className="summary-list-item" key={idx}>
                  {feeling}
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {/* My Thoughts Section - Testing Edits within Summary Page*/}
      <div className="summary-primary-container" style={{ opacity: opacityDark ? 0.4 : 1 }}>
        <div className="summary-primary-header">
          <h5 className="summary-section-title">My Thought(s):</h5>
          <a
            className="summary-edit-btn"
            onClick={() => {
              setEditThoughts(!editThoughts);
              setOpacityDark(true);
            }}
          >
            Edit
          </a>
        </div>

        <div className="summary-text-container">
          <span className="summary-text">{thoughts.answer}</span>
        </div>
      </div>

      {/* My Body Sensations Section - Navigate to original*/}
      <div className="summary-primary-container" style={{ opacity: opacityDark ? 0.4 : 1 }}>
        <div className="summary-primary-header">
          <h5 className="summary-section-title">My Body Sensation(s):</h5>
          <a
            className="summary-edit-btn"
            onClick={() => {
              setEditBodySensation(!editBodySensation);
              setOpacityDark(true);
            }}
          >
            Edit
          </a>
        </div>

        <div className="summary-primary-feeling-container">
          <ul className="summary-list">
            {bodySensations.answer.map((feeling, idx) => {
              return (
                <li className="summary-list-item" key={idx}>
                  {feeling}
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      {/* My Actions Section - Testing Edits within Summary Page */}
      <div className="summary-primary-container" style={{ opacity: opacityDark ? 0.4 : 1 }}>
        <div className="summary-primary-header">
          <h5 className="summary-section-title">My Action(s):</h5>
          <a
            className="summary-edit-btn"
            onClick={() => {
              setEditAction(!editAction);
              setOpacityDark(true);
            }}
          >
            Edit
          </a>
        </div>
        <div className="summary-text-container">
          <span className="summary-text">{actions.answer}</span>
        </div>
      </div>

      {/* RENDER FORM POPUP EDIT, MOVE TO THE TOP USING ABSOLUTE - Pass in formType to match the form component to render(i.e. <ActionForm />), form header desc., and callback function to update edit state to close popup */}
      {editMajor ? (
        <EditForm
          formType={"MajorFeelingForm"}
          formHeader={"My Primary Feeling:"}
          handleEdit={handleEdit}
          displayProgress={false}
          applyOpacity={applyOpacity}
          questionNum={1}
          setIsQuestionOne={setIsQuestionOne}
          setGlossaryOpen={setGlossaryOpen}
        />
      ) : null}
      {editAssociated ? (
        <EditForm
          formType={"AssociatedFeelingForm"}
          formHeader={"My Associated Feeling(s):"}
          handleEdit={handleEdit}
          displayProgress={false}
          reachedLimit={reachedLimit}
          setReachedLimit={setReachedLimit}
          applyOpacity={applyOpacity}
          questionNum={2}
          setIsQuestionOne={setIsQuestionOne}
          style={{ zIndex: 100 }}
          setGlossaryOpen={setGlossaryOpen}
          setIsAlertOpen={setIsAlertOpen}
        />
      ) : null}
      {editThoughts ? (
        <EditForm
          formType={"ThoughtForm"}
          formHeader={"My Thought(s):"}
          handleEdit={handleEdit}
          displayProgress={false}
          applyOpacity={applyOpacity}
          questionNum={3}
          setIsQuestionOne={setIsQuestionOne}
          setGlossaryOpen={setGlossaryOpen}
        />
      ) : null}
      {editBodySensation ? (
        <EditForm
          formType={"BodySensationForm"}
          formHeader={"My Body Sensation(s):"}
          handleEdit={handleEdit}
          displayProgress={false}
          reachedLimit={reachedLimit}
          setReachedLimit={setReachedLimit}
          applyOpacity={applyOpacity}
          questionNum={4}
          setIsQuestionOne={setIsQuestionOne}
          setGlossaryOpen={setGlossaryOpen}
          setIsAlertOpen={setIsAlertOpen}
        />
      ) : null}
      {editAction ? (
        <EditForm
          formType={"ActionForm"}
          handleEdit={handleEdit}
          formHeader={"My Action(s):"}
          displayProgress={false}
          applyOpacity={applyOpacity}
          questionNum={5}
          setIsQuestionOne={setIsQuestionOne}
          setGlossaryOpen={setGlossaryOpen}
        />
      ) : null}
    </div>
  );
};

export default SummaryForm;
