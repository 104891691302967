import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "aws-amplify";

const initialState = {
    status: "idle",
    zipCode: "",
    data: ""
}

//wrapper for navigator.geolocation to convert it to promise based
function getPosition(options) {
    return new Promise((resolve, reject) => 
        navigator.geolocation.getCurrentPosition(resolve, reject, options)
    );
}

export const fetchByZipCode = createAsyncThunk(
    'communityHealth/fetchByZipCode',
    async (zipCode) => {
        const payload = {
            body: { ZIP: zipCode },
        };
        const response = await API.post('civAPI', '/county', payload)
        if (response.error) {
            return ""
        }
        return response.payload
    }
)

export const fetchZipCode = createAsyncThunk(
    'communityHealth/fetchZipCode',
    async () => {

        const position = await getPosition()
        const latLong = position.coords.latitude + ',' + position.coords.longitude
        const payload = {
            body: { latLong: latLong },
        }
        const response = await API.post('civAPI', '/zipcode', payload)
        if(response.error){
            return ""
        } else {
            return response.payload
        }

    }
)

export const communityHealthSlice = createSlice({
    name: 'communityHealth',
    initialState,
    reducers: {
        setZipCode: (state, action) => {
            state.zipCode = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchByZipCode.pending, (state) => {
            state.status = "pending"
        })
        builder.addCase(fetchByZipCode.fulfilled, (state, action) => {
            state.data = action.payload
            state.status = "fulfilled"
        })
        builder.addCase(fetchByZipCode.rejected, (state, action) => {
            state.data = ""
            state.status = "rejected"
        })
        builder.addCase(fetchZipCode.fulfilled, (state, action) => {
            state.zipCode = action.payload
        })
        builder.addCase(fetchZipCode.rejected, (state, action) => {
            state.zipCode = 0
        })
    }
})

export default communityHealthSlice.reducer
export const {setZipCode} = communityHealthSlice.actions

export const selectZipCode = state => state.communityHealth.zipCode
export const selectCommunityData = state => state.communityHealth.data
export const selectStatus = state => state.communityHealth.status