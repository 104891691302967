import { useRef, useState } from "react";
import { useIntersection } from 'react-use';
import { gsap } from 'gsap';
import { InView } from 'react-intersection-observer';
import { useInView } from 'react-intersection-observer';
import './scrollMenu.scss';
import { useEffect } from 'react'

export function Slider({ count }) {
    //define section ref useRef in see section to 
    //it can point to each menu item
    const sectionRef = useRef(null)
    const containerRef = useRef()
    const [inView, setInView] = useState('')


    // function createObserver() {
    //     let observer;

    //     let options = {
    //         root: sectionRef.current,
    //         rootMargin: "0px",
    //         threshold: 1.0,
    //     };
                                                                                                                                                                        
    //     observer = new IntersectionObserver((entry => {
    //         return console.log('entry',entry.target.children)
    //         // setInView(!inView)
            
            
    //     }), options);

    //     observer.observe(sectionRef.current)

    // }

    // createObserver()

    // const { ref, inView, entry } = useInView();
    const intersection = useIntersection(sectionRef, {
        root: sectionRef.current,
        rootMargin: '0px',
        threshold: 1,
        delay: 1,
        // initialInView: false
    })

    const fadeIn = element => {
        gsap.to(element, {
            duration: 2,
            opacity: 1,
            y: -1,
            color: '#FFC0CB',
            fontSize: '20px'
        })

    }
    const fadeOut = element => {
        gsap.to(element, {
            duration: 1,
            opacity: 0,
            y: -1
        })

    }
    // console.log(intersection)
    useEffect(() => {
        intersection?.intersectionRatio < 1 ? console.log('inview') : 
        fadeIn('.fadeIn')
    }, [intersection])


    //  entry.intersectionRatio === 0 ?
    //     fadeOut(".fadeOut") : fadeIn('.fadeIn')

    return (
        <div ref={sectionRef}
            className='feelings'>
            {count?.map((item, i) => {
                return (<p className={`${inView ? 'fadeIn' : 
                'fadeOut'}`} >{item[2]}% {item[0]}</p>
                )
            }
            )}
        </div>
    )


}





