import GLOSSARY_DATA from "../../glossary-data";
import { useState, useEffect } from "react";
import GreyInfoIcon from "../../../../../images/grey-info-icon.svg";

export const BodySensationGlossary = (props) => {
  const { listAtoF, listGtoL, listMtoR, listStoZ, setGlossaryOpen } = props;
  const [displayList, setDisplayList] = useState(listAtoF);
  const [chosenRange, setChosenRange] = useState("listAtoF");
  const [sensationClicked, setSensationClicked] = useState("Achy");
  const [textObj, setTextObj] = useState({});

  console.log("PROPS GLOSSARIES: ", listAtoF, listGtoL, listMtoR, listStoZ);
  //OPEN/CLOSE DEFINITION POPUP
  const popupCallback = () => {
    //close inside MajorFeelingForm
    props.handlePopup();
    setGlossaryOpen(false);
  };

  //Change Chosen Item text color and display definitions, synonym, and sentence
  const handleClick = (word) => {
    setSensationClicked(word);
  };

  useEffect(() => {
    console.log("Finding corresponding text for: ", sensationClicked);

    let glossaryText;

    // const glossaryTextAtoF = GLOSSARY_DATA[2].items[0].text;
    // const glossaryTextGtoL = GLOSSARY_DATA[2].items[1].text;
    // console.log("glossaryTextAtoF: ", glossaryTextAtoF);
    // console.log("glossaryTextGtoL: ", glossaryTextGtoL);

    console.log("CHOSENRANGE: ", chosenRange);
    // TODO set glossaryText here
    if (chosenRange === "listAtoF") glossaryText = GLOSSARY_DATA[2].items[0].text;
    else if (chosenRange === "listGtoL") glossaryText = GLOSSARY_DATA[2].items[1].text;
    else if (chosenRange === "listMtoR") glossaryText = GLOSSARY_DATA[2].items[2].text;
    else if (chosenRange === "listStoZ") glossaryText = GLOSSARY_DATA[2].items[3].text;

    console.log("glossaryText: ", glossaryText);

    const matchedKey = Object.keys(glossaryText).find((key) => key === sensationClicked);
    setTextObj(glossaryText[matchedKey]);
  }, [sensationClicked, chosenRange]);

  // console.log("TextObj: ", textObj);

  return (
    <div className="body-glossary-container">
      <div className="body-glossary-header-container">
        <div className="flex">
          <div className="ff-img-container">
            <svg width="21" height="20">
              <image href={GreyInfoIcon} />
            </svg>
          </div>
          <span className="glossary-header">Body Sensation Definitions</span>
        </div>
        <span className="glossary-close" onClick={popupCallback}>
          X
        </span>
      </div>

      {/* Sensation Ranges */}
      <div className="glossary-alphabet-list-container">
        <span
          onClick={() => {
            setDisplayList(listAtoF);
            setChosenRange("listAtoF");
            setSensationClicked("Achy");
          }}
          className={chosenRange === "listAtoF" ? "alphabet-range-chosen" : "alphabet-range"}
        >
          A-F
        </span>
        <span
          onClick={() => {
            setDisplayList(listGtoL);
            setChosenRange("listGtoL");
            setSensationClicked("Gentle");
          }}
          className={chosenRange === "listGtoL" ? "alphabet-range-chosen" : "alphabet-range"}
        >
          G-L
        </span>
        <span
          onClick={() => {
            setDisplayList(listMtoR);
            setChosenRange("listMtoR");
            setSensationClicked("Nauseous");
          }}
          className={chosenRange === "listMtoR" ? "alphabet-range-chosen" : "alphabet-range"}
        >
          M-R
        </span>
        <span
          onClick={() => {
            setDisplayList(listStoZ);
            setChosenRange("listStoZ");
            setSensationClicked("Sensitive");
          }}
          className={chosenRange === "listStoZ" ? "alphabet-range-chosen" : "alphabet-range"}
        >
          S-Z
        </span>
      </div>

      <ul className="body-glossary-list-container">
        {displayList.map((word, index) => {
          return (
            <li
              className={sensationClicked === word ? "glossary-list-item-clicked" : "glossary-list-item"}
              key={index}
              onClick={() => handleClick(word)}
            >
              {word}
            </li>
          );
        })}
      </ul>

      <div className="glossary-split-container">
        <span className="glossary-split"></span>
      </div>

      <div className="body-sensation-choice-container">
        <div className="body-sensation-triangle"></div>
        {/* User Option */}
        <div className="body-glossary-feeling">{sensationClicked}</div>
      </div>

      <div className="glossary-split-container">
        <span className="glossary-split"></span>
      </div>

      {/* Display Clicked Item */}
      <div className="associated-glossary-bottom-container">
        <div className="associated-glossary-definition">
          <span className="glossary-text-header">Definition:</span>
          <span className="glossary-text">{textObj.definition}</span>
        </div>

        <div className="glossary-split-container-full">
          <span className="glossary-split"></span>
        </div>

        {/* Synonym */}
        <div className="associated-glossary-synonym">
          <span className="glossary-text-header">Synonym(s):</span>
          <span className="glossary-text">{textObj.synonym2}</span>
        </div>

        <div className="glossary-split-container-full">
          <span className="glossary-split"></span>
        </div>

        {/* Example Sentence */}
        <div className="associated-glossary-sentence">
          <span className="glossary-text-header">Example Sentence:</span>
          <span className="glossary-text">{textObj.sentence}</span>
        </div>
      </div>
    </div>
  );
};

export default BodySensationGlossary;
