import { useSelector } from "react-redux"
import { selectStatus } from "../../../store/communityHealthSlice"
import { getGradientColorFromValue } from "./getColorFromValue"

export const SeeCircle = ({ data, label, handleClick }) => {
    const status = useSelector(selectStatus)
    return (
        <div className="text-center">
            <p
                onClick={handleClick}
                className={`${typeof data == 'number' ? 'circle' 
                    : typeof data == 'string' || typeof data == 'object' ? 'emotion': ''} fw-bold ${status}`}
                style={{
                    background: status === "fulfilled" ? getGradientColorFromValue(data) : `hsl(0 0% 90%)`,
                    cursor: 'pointer',
                    width: typeof data == 'number' ? data * 0.8 + 64 : 64,
                    height: typeof data == 'number' ? data * 0.8 + 64 : 64
                }}
            >
                {typeof data == 'number' ? Math.round(data).toString() //Can be modified to show more than just first answer (data[0])
                    : typeof data == 'string' ? data : typeof data == 'object' ? data[0] : 0 } 
            </p>
            <label className={`${status}`}> {label} </label>
        </div>
    )
}