import { useContext, useState, useEffect } from "react";
import { QuestionContext } from "../../../context/question.context";
import Bodysensationsicons from "./progressbar/Bodysensationsicons";
import NavigationButtons from "./components/buttons/NavigationButtons";
import CVLIcon from "./../../../images/cvl-icon.svg";
import BodySensationGlossary from "./components/Glossary/BodySensationGlossary";
import GLOSSARY_DATA from "./glossary-data";
import InfoIcon from "./../../../images/info-icon.svg";

const bodyList = {
  listAtoF: [
    "Achy",
    "Airy",
    "Blocked",
    "Breathless",
    "Bruised",
    "Burning",
    "Buzzy",
    "Clammy",
    "Clenched",
    "Cold",
    "Constricted",
    "Contained",
    "Contracted",
    "Dizzy",
    "Drained",
    "Dull",
    "Electric",
    "Empty",
    "Expanded",
    "Flowing",
    "Fluid",
    "Fluttery",
    "Frozen",
    "Full",
  ],
  listGtoL: ["Gentle", "Hard", "Heavy", "Hollow", "Hot", "Icy", "Itchy", "Jumpy", "Knotted", "Light", "Loose"],
  listMtoR: ["Nauseous", "Numb", "Pain", "Pounding", "Prickly", "Pulsing", "Queasy", "Radiating", "Relaxed", "Releasing", "Rigid"],
  listStoZ: [
    "Sensitive",
    "Settled",
    "Shaky",
    "Shivery",
    "Slow",
    "Smooth",
    "Soft",
    "Sore",
    "Spacey",
    "Spacious",
    "Sparkly",
    "Stiff",
    "Still",
    "Suffocated",
    "Sweaty",
    "Tender",
    "Tense",
    "Throbbing",
    "Tight",
    "Tingling",
    "Trembly",
    "Twitchy",
    "Vibrating",
    "Warm",
    "Wobbly",
    "Wooden",
  ],
};

// If this mood is in the list then render mood background blue
const isSensationInList = (sensationList, sensation) => {
  // console.log(sensationList.length);

  return sensationList.includes(sensation) ? true : false;
};

const BodySensationForm = (props) => {
  const glossaryData = GLOSSARY_DATA[2].items;

  const listAtoF = glossaryData[0].listAtoF;
  const listGtoL = glossaryData[1].listGtoL;
  const listMtoR = glossaryData[2].listMtoR;
  const listStoZ = glossaryData[3].listStoZ;

  // const [currentList, setCurrentList] = useState(listGtoL);
  const { reachedLimit, setReachedLimit, displayProgress, glossaryOpen, setGlossaryOpen, setIsAlertOpen } = props;
  const [displayList, setDisplayList] = useState(listAtoF);
  const [sensationList, setSensationList] = useState([]);
  const { addResultToList, currentQuestion, resultsList } = useContext(QuestionContext);
  const [chosenRange, setChoseRange] = useState("A-F");

  //OPEN/CLOSE DEFINITIONS POPUP
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // Initialize currently chosen options based on resultsList
  useEffect(() => {
    if (resultsList.length > 3) {
      let currentAnswer = resultsList[3].answer;
      setSensationList(currentAnswer);
    }
  }, []);

  //OPEN/CLOSE DEFINITION POPUP - Passing this function as prop to PrimaryGlossary and call it within child to change state here
  const handlePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setGlossaryOpen(true);
  };

  const handleClick = (bodySensation) => {
    const answer = bodySensation;

    //If sensation is in list then we will remove it, else add it to the end
    const sensationToRemove = sensationList.find((mood) => {
      return mood === bodySensation;
    });

    //REMOVE ITEM - this will change choice back to grey on second click
    if (sensationToRemove) {
      // console.log(`Removing ${associatedMood} from list.`);
      const updatedList = sensationList.filter((item) => item !== sensationToRemove);

      setSensationList(updatedList);
      updatedList.length > 0 ? addResultToList({ answer: updatedList, question: 4 }) : resultsList.splice(currentQuestion - 1, 1);

      // once number of moods is below limit, the item colors are back
      if (sensationList.length < 11) {
        setReachedLimit(false);
        setIsAlertOpen(false);
      }
    } else {
      //NOT IN LIST - ADD MOOD
      // console.log(`${associatedMood} is not in list.`);

      // once number of moods is over limit, the item colors become opaque 12 limit
      if (sensationList.length >= 12) {
        //setPopupOpen(true);
        setReachedLimit(true);
        //showAlert();
        setIsAlertOpen(true);
        return;
      }

      setSensationList([...sensationList, answer]);
      addResultToList({ answer: [...sensationList, answer], question: 4 });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // const questionResults = { answer: sensationList, question: 4 };
    // Save user choice in choice context

    // THIS HAS BEEN REMOVED
    // Is it necessary?
    // if (sensationList.length !== 0) {
    //   //addResultToList(questionResults);
    //   // Increment Question to render next question
    //   changeQuestion(currentQuestion + 1);
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="ff-header-container" style={{ opacity: glossaryOpen ? 0.6 : 1, marginLeft: "20px"}}>
        <p className="ff-header" style={{fontSize: "25px"}}>My Feelings Flow Tracker</p>
        <div className="ff-img-container" style={{marginRight: "20px"}}>
          <svg width="21" height="20">
            <image href={CVLIcon} style={{ opacity: glossaryOpen ? 0.6 : 1 }} />
          </svg>
        </div>
      </div>

      {displayProgress ? <Bodysensationsicons /> : null}

      <div className="ff-split"></div>

      <div>
        <div className="ff-subheader-container">
          <h2 className="ff-subheader">Body Sensation(s)</h2>
          <div className="ff-img-container">
            {/* Opens Definition */}
            <svg width="24" height="24" className="info-icon" onClick={() => handlePopup()}>
              <image href={InfoIcon} width="24" height="24" />
            </svg>
          </div>
        </div>

        <p className="ff-question">
          How were those feelings experienced by your body?
          <span style={{ color: "#999999" }}> [select up to 12]</span>
        </p>
      </div>

      <ul className="sensation-list">
        {sensationList.map((sensation) => {
          return (
            <div key={sensation} className="sensation-item-container">
              <div className="sensation-item-remove" onClick={() => handleClick(sensation)}>
                X
              </div>
              <li className="sensation-list-item">{sensation}</li>
            </div>
          );
        })}
      </ul>

      <div className="alphabet-list-container">
        <span
          onClick={() => {
            setDisplayList(listAtoF);
            setChoseRange("A-F");
          }}
          className={chosenRange === "A-F" ? "alphabet-range-chosen" : "alphabet-range"}
        >
          A-F
        </span>
        <span
          onClick={() => {
            setDisplayList(listGtoL);
            setChoseRange("G-L");
          }}
          className={chosenRange === "G-L" ? "alphabet-range-chosen" : "alphabet-range"}
        >
          G-L
        </span>
        <span
          onClick={() => {
            setDisplayList(listMtoR);
            setChoseRange("M-R");
          }}
          className={chosenRange === "M-R" ? "alphabet-range-chosen" : "alphabet-range"}
        >
          M-R
        </span>
        <span
          onClick={() => {
            setDisplayList(listStoZ);
            setChoseRange("S-Z");
          }}
          className={chosenRange === "S-Z" ? "alphabet-range-chosen" : "alphabet-range"}
        >
          S-Z
        </span>
      </div>

      <ul className="alphabet-list">
        {displayList.map((word, index) => {
          return (
            <li
              className={`${isSensationInList(sensationList, word) ? "body-list-item-clicked" : "body-list-item"}`}
              key={index}
              onClick={() => handleClick(word)}
              // CHANGE THIS TO ENTIRE DIV
              // style={{ opacity: reachedLimit ? 0.5 : 1 }}
            >
              {word}
            </li>
          );
        })}
      </ul>
      {/* Render based on which list user clicks */}

      <div>
        {/* Your other components or code */}
        <NavigationButtons
          reachedLimit={reachedLimit}
          setReachedLimit={setReachedLimit}
          sensationList={sensationList}
          glossaryOpen={glossaryOpen}
          setIsAlertOpen={setIsAlertOpen}
        />
      </div>

      {isPopupOpen ? (
        <BodySensationGlossary
          listAtoF={listAtoF}
          listGtoL={listGtoL}
          listMtoR={listMtoR}
          listStoZ={listStoZ}
          handlePopup={handlePopup}
          setGlossaryOpen={setGlossaryOpen}
        />
      ) : null}
    </form>
  );
};

export default BodySensationForm;
