import { Ad } from './Ad';
import LinktreeLogo from '../../images/linktree_logo.png';

function DetailFooter() {
    return (
        <div className="blue" id="bottom-blue">
            <Ad />
            <footer>
                <ul>
                    <li><a href="https://linktr.ee/Civilience" target="_blank">
                        <img src={LinktreeLogo} alt="Linktree Logo" />
                    </a></li>
                </ul>
                <ul>
                    <li><a>Privacy</a></li>
                    <li><a>Disclaimers</a></li>
                    <li><a>Legal</a></li>
                </ul>
            </footer>
        </div>
    );
}

export default DetailFooter;