import OrangeAd from '../../images/orangeAd.png'

export const Ad = () => {
    return(
        
        <div className="ad-container">
            <p>Ad</p>
            <img src={OrangeAd} alt="advertisement" className="ad"/>
        </div>
    )
}