import { Ad } from "../Shared/Ad";
import { Slogan } from "../Shared/Slogan";

function DetailHeader({setShowVerticals, showVerticals}) {
    return (
        <header >
            <nav className="blue">
                <div className="top-row">
                    <Slogan />
                    <p 
                        onClick={() => setShowVerticals(prev => !prev)} 
                        id="vertical-select"
                    >
                        HEALTH
                        <div className={`gradient chevron-container ${showVerticals ? 'up' : 'down'}`} />
                    </p>
                </div>
                <Ad />
            </nav>
        </header>
    );
}

export default DetailHeader;
