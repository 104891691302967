import './carousel.scss'
import React from 'react'
import { useState, useEffect } from 'react'
import left from '../../../../images/chevron-left-solid.svg'
import right from '../../../../images/chevron-right.svg'
import { cloneElement, Children } from 'react';
import { Bubble } from './bubble'
import uuid from 'react-uuid';



export const CarouselItem = ({ children, key, width }) => {
  return (
    <div
     key={key} className="carousel-item" style={{ width: width  }}>
      {children}
    </div>
  );
};

const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = 0;
    }
    else if (newIndex >= children.length) {
      newIndex = children.length - 1
    }
    setActiveIndex(newIndex)
  }
  console.log('children',children)
  const width ='100%'
  return (
    <div className="carousel">

      <div
        className="inner"
        style={{transform: `translateX(-${activeIndex * 100}%)`}}>,
        
        {children.map((child, index) => {
          return cloneElement(child, {
            child: child,
            width: width
          })
        })} 
      </div>

      <div className='indicators'>

        <img src={left} style={{ color: "#ffffff;" }} alt='left-arrow'
          onClick={() => {
            updateIndex(activeIndex - 1)
          }} />

        <img onClick={() => {
          updateIndex(activeIndex + 1)
        }}
          src={right} alt='right-arrow'
        />
      </div>

      <div className='bubbles'>

        {children.map((item,) => (
          <Bubble className='bubbles' key={item} activeIndex={activeIndex} num={children.length} />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
