// REMOVE CONTEXT ONCE LOGIC IS TRANSFERRED TO REDUX STORE
import { createContext, useState } from "react";

//adds user's question answer to a list, if it exists -> overwrite previous answer, if it doesn't exist - adds it to the end of the list
const addToList = (resultsList, questionResult) => {
  // Checking if user has already answered this question and has been stored in resultList
  const alreadyInList = resultsList.find((result) => {
    return result.question === questionResult.question;
  });

  // User's answer is already in the list for this question, so we only update the 'answer' value
  if (alreadyInList) {
    const updatedList = resultsList.map((result) => {
      return result.question === questionResult.question ? { ...questionResult, answer: questionResult.answer } : result;
    });

    return updatedList;
  } else {
    // User hasn't answered this question, add it to the end of the list
    return [...resultsList, { ...questionResult }];
  }
};

// Holds users choice for questions Flow tracker questions
export const QuestionContext = createContext({
  resultsList: [],
  feeling: 0,
  currentQuestion: 1,
  isQuestionOne: false,
  addResultToList: () => {},
  resetAssociated: () => {},
  resetResultsList: () => {},
});

export const QuestionProvider = ({ children }) => {
  const [resultsList, setResultsList] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [feeling, setFeeling] = useState(0);
  const [isQuestionOne, setIsQuestionOne] = useState(false);

  // Add question answer to a list we can access
  const addResultToList = (resultToAdd) => {
    setResultsList((prevResultsList) => addToList(prevResultsList, resultToAdd));
  };

  // Reset the answers of associated to an empty array IF USER CHANGES THE PRIMARY MOOD
  const resetAssociated = () => {
    const updatedList = addToList(resultsList, { answer: [], question: 2 });

    setResultsList(updatedList);
  };

  const resetResultsList = () => {
    setResultsList([]);
  };

  // Increment/Decrement question value
  const changeQuestion = (questionNumber) => {
    setCurrentQuestion(questionNumber);
  };

  // Needed as a prop for associated feeling(question 2)
  const setMajorFeeling = (feeling) => {
    setFeeling(feeling);
  };

  // useEffect(() => {
  //   // console.log("resultsList: ", resultsList);
  // }, [resultsList]);

  // Pass list and functions as props to our forms
  const value = {
    feeling,
    setMajorFeeling,
    resultsList,
    addResultToList,
    currentQuestion,
    changeQuestion,
    isQuestionOne,
    setIsQuestionOne,
    resetAssociated,
    resetResultsList,
  };

  return <QuestionContext.Provider value={value}>{children}</QuestionContext.Provider>;
};
