import GLOSSARY_DATA from "../../glossary-data";
import { useState, useEffect } from "react";
import { majorList } from "../../MajorFeelingForm";
import UpArrow from "../../../../../images/up-arrow.svg";
import DownArrow from "../../../../../images/down-arrow.svg";
// Basic visual component to ensure data placed in glossary is correct - Update once glossary-data is complete

export const PrimaryGlossary = (props) => {
  //description OR altdescription - will switch
  const [displayData, setDisplayData] = useState("description");
  const [colorId, setColorId] = useState(1);
  const [scaleColor, setScaleColor] = useState(1);
  // const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [activeDescription, setActiveDescription] = useState("description");

  // All Primary Glossary Items
  // const primaryGlossary = GLOSSARY_DATA[0].items;

  //Matching definition to display by using majorList.id where each of the 18 colors match the id within GLOSSARY_DATA 1-18
  const glossaryData = GLOSSARY_DATA[0].items.find((item) => item.id === colorId);

  //OPEN/CLOSE DEFINITION POPUP
  const popupCallback = () => {
    // setIsPopupOpen(!isPopupOpen);
    //close inside MajorFeelingForm
    props.handlePopup();
  };

  //MATCH DEFINITION TO DISPLAY & SCALE COLOR ICON
  const handleColorChange = (id) => {
    setColorId(id);
  };

  //DISPLAY DEFINITION, SYNONYM, EXAMPLE SENTENCE FOR FIRST/SECOND FEELING OPTIONS
  const handleChange = (infoToDisplay) => {
    // console.log("infoToDisplay: ", infoToDisplay);
    infoToDisplay === "description" ? setDisplayData("description") : setDisplayData("altdescription");
  };

  return (
    <div className="primary-glossary-container">
      <div className="glossary-header-container">
        <span className="glossary-header">Primary Feelings Definitions</span>
        <span className="glossary-close" onClick={popupCallback}>
          X
        </span>
      </div>

      <div className="glossary-split-container">
        <span className="glossary-split"></span>
      </div>

      {/* Left-Right Container */}
      <div className="glossary-item-container">
        {/* Left - Arrows */}
        <div className="glossary-arrow-container">
          <div className="glossary-up-arrow-container">
            <span className="glossary-arrow-text">Pleasant Feelings</span>
            <svg width="4" height="135">
              <image href={UpArrow} />
            </svg>
          </div>
          <div className="glossary-down-arrow-container">
            <span className="glossary-arrow-text">Unpleasant Feelings</span>
            <svg width="4" height="135">
              <image href={DownArrow} />
            </svg>
          </div>
        </div>

        {/* Left - Display Colors */}
        <div className="glossary-left-container">
          {majorList.map((color) => {
            return (
              <div
                className={`glossary-color ${scaleColor === color.id ? "glossary-scale" : ""}`}
                style={{ backgroundColor: `${color.activeColor}` }}
                key={color.id}
                onClick={() => {
                  handleColorChange(color.id);
                  setScaleColor(color.id);
                }}
              ></div>
            );
          })}
        </div>

        {/* Right */}
        <div className="glossary-right-container">
          {/* Description */}
          <div className="glossary-description-container">
            <div
              className={"glossary-description-first"}
              style={{
                backgroundColor: `${
                  activeDescription === "description" || typeof glossaryData.altdefinition === "undefined"
                    ? glossaryData.activeColor
                    : ""
                }`,
                border: `1px solid ${glossaryData.activeColor}`,
                fontWeight: `${activeDescription === "description" ? "600" : "normal"}`,
                color: `${glossaryData.id === 18 || glossaryData.id === 17 ? "#FFFFFF" : "#000000"}`,
                // color: `${glossaryData.id > 16 || glossaryData.id === 5 ? "#FFFFFF" : "#000000"}`,
              }}
              onClick={() => {
                handleChange("description");
                setActiveDescription("description");
              }}
            >
              <span className="glossary-description">{glossaryData.description}</span>
            </div>

            {typeof glossaryData.altdescription !== "undefined" ? (
              <div
                className="glossary-description-second"
                style={{
                  backgroundColor: `${activeDescription === "altdescription" ? glossaryData.activeColor : ""}`,
                  border: `1px solid ${glossaryData.activeColor}`,
                  fontWeight: `${activeDescription === "altdescription" ? "600" : "normal"}`,
                  color: `${glossaryData.id === 18 || glossaryData.id === 17 ? "#FFFFFF" : "#000000"}`,
                  // color: `${glossaryData.id > 16 || glossaryData.id === 5 ? "#FFFFFF" : "#000000"}`,
                }}
                onClick={() => {
                  handleChange("altdescription");
                  setActiveDescription("altdescription");
                }}
              >
                <span>{glossaryData.altdescription}</span>
              </div>
            ) : null}
          </div>

          {/* Definition */}
          <div className="glossary-definition-container">
            <strong className="glossary-text-header">Definition:</strong>
            <span className="glossary-text">
              {/* || typeof glossaryData.altdefinition === "undefined" -> sets data to first if user changes color after previously choosing an alt definition */}
              {displayData === "description" || typeof glossaryData.altdefinition === "undefined"
                ? glossaryData.definition
                : glossaryData.altdefinition}
            </span>
          </div>

          <span className="glossary-split" style={{ margin: "24px 0px", width: "93%" }}></span>

          {/* Synonym */}
          <div className="glossary-synonym-container">
            <span className="glossary-text-header">Synonym(s)</span>
            <span className="glossary-text">
              {displayData === "description" || typeof glossaryData.altdefinition === "undefined"
                ? glossaryData.synonym
                : glossaryData.altsynonym}
            </span>
          </div>

          <span className="glossary-split" style={{ margin: "24px 0px", width: "93%" }}></span>

          {/* Example Sentence */}
          <div className="glossary-sentence-container">
            <strong className="glossary-text-header">Example Sentence</strong>
            <span className="glossary-text">
              {displayData === "description" || typeof glossaryData.altdefinition === "undefined"
                ? glossaryData.sentence
                : glossaryData.altsentence}
            </span>
          </div>
          {/* <div>
            <a href={glossaryData.link} target="_blank">
              More Info
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PrimaryGlossary;
