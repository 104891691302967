import transitionBackground from '../../../../images/ftt-process/info.jpg'
import { motion } from 'framer-motion'
import React, { useState, useEffect } from 'react';

const TypewriterEffect = () => {
    const textLines = [
        'Emotional intelligence starts with identifying, by labelling, the various parts of your emotional process. You have just completed this step.',
        'The next step is a review of the emotional process flow: how your feelings arise from thoughts and in turn give rise to body sensations and to actions.',
        'Together, both steps support your emotional regulation and well-being.'
    ];

    const [currentLineIndex, setCurrentLineIndex] = useState(0);
    const [currentText, setCurrentText] = useState('');
    let currentIndex = 0;
    useEffect(() => {
        const line = textLines[currentLineIndex];

        const timer = setInterval(() => {
            setCurrentText((prevText) => prevText + line[currentIndex]);
            
            currentIndex++;
            if (currentIndex === line.length) {
                clearInterval(timer);
                setTimeout(() => {
                    setCurrentText('');
                    setCurrentLineIndex((prevIndex) => (prevIndex + 1) % textLines.length);
                }, 2000); // Delay before moving to the next line
            }
        }, 100); // Speed of typing

        return () => {
            clearInterval(timer);
        };
    }, [currentLineIndex, textLines, currentIndex]);

    return (
        <div>
            <h1>{currentText}</h1>
        </div>
    );
};


const Transition = ({ emotionalData }) => {

    return (
        <motion.div
            initial={{ x: 250, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="myftt">
            <div className='header'>
                <h2>My Feelings Flow Tracker</h2>
                <div className="colorLine" style={{ width: "100%", height: "6px" }} />
            </div>
            <div className='process-container'
                style={{
                    backgroundImage: `url(${transitionBackground})`, backgroundSize: "cover",
                    // margin: 5, zIndex: -10
                }}
            > {/* <div className='header-container'>
                    <p>Process: Thoughts</p>
                </div> */}
                <div className='body-container-info'>
                    <p className='body-description'>Awareness of the Emotional Process</p>
                    <div className='body-summary-info'>
                        {/* Add input from redux */}
                        <span className="type clip step" >
                            {/* <TypewriterEffect/> */}
                            Emotional intelligence starts with identifying, by labelling, the various parts of your emotional process. You have just completed this step. <br /><br />
                            The next step is a review of the emotional process flow: how your feelings arise from thoughts and in turn give rise to body sensations and to actions.<br /><br />
                            Together, both steps support your emotional regulation and well-being.
                        </span>
                    </div>
                </div>
            </div>

        </motion.div>
    )
}
export default Transition;