import DetailHeader from "../components/LearnDetail/DetailHeader";
import DetailFooter from "../components/Shared/DetailFooter";
import { DetailTitle } from "../components/LearnDetail/DetailTitle";
import { useLocation } from "react-router-dom";
import FeelingsFlowQuestion from "../components/PublicHealth/FeelingsFlowQuestion";

function Survey() {
  const location = useLocation();

  const {
    countyData,
    countyLabel,
    stateData,
    stateLabel,
    countryData,
    countryLabel,
    options,
    comingSoonOptions,
    headerTitle,
    minuteCardOneHeader,
    minuteCardOneText,
    minuteCardTwoHeader,
    minuteCardTwoText,
    sayComponentText,
    saySectionLabel,
    tokenPayload,
  } = location.state;

  return (
    <div className="LearnDetail">
      <DetailHeader
      //   setShowVerticals={setShowVerticals}
      //   showVerticals={showVerticals}
      />

      <DetailTitle
        headerTitle={headerTitle}
        //   setShowVerticals={setShowVerticals}
        //    setPage={setPage}
      />
      {
        headerTitle === "Community Health" && <p>CH</p>
        // <DiseaseSurvey disease={disease} setDisease={setDisease} showVerticals={showVerticals} page={page} setPage={setPage} />
      }
      {headerTitle === "Emotional Health" && <FeelingsFlowQuestion tokenPayload={tokenPayload} />}
      <DetailFooter />
    </div>
  );
}

export default Survey;
