import { useContext, useState, useEffect } from "react";
import { QuestionContext } from "../../../context/question.context";
import AlivenessJoyIcon from "./../../../images/aliveness-joy-icon.svg";
import GratefulIcon from "./../../../images/grateful-icon.svg";
import CourageousPowerfulIcon from "./../../../images/courageous-powerful-icon.svg";
import ConnectedLovingIcon from "./../../../images/connected-loving-icon.svg";
import AcceptingOpenIcon from "./../../../images/accepting-open-icon.svg";
import HopefulIcon from "./../../../images/hopeful-icon.svg";
import CuriousIcon from "./../../../images/curious-icon.svg";
import TenderIcon from "./../../../images/tender-icon.svg";
import DisconnectedNumbIcon from "./../../../images/disconnected-numb-icon.svg";
import StressedTenseIcon from "./../../../images/stressed-tense-icon.svg";
import UnsettledDoubtIcon from "./../../../images/unsettled-doubt-icon.svg";
import FragileIcon from "./../../../images/fragile-icon.svg";
import DespairSadIcon from "./../../../images/despair-sad-icon.svg";
import EmbarassedShameIcon from "./../../../images/embarassed-shame-icon.svg";
import AngryAnnoyedIcon from "./../../../images/angry-annoyed-icon.svg";
import GuiltIcon from "./../../../images/guilt-icon.svg";
import FearIcon from "./../../../images/fear-icon.svg";
import PowerlessIcon from "./../../../images/powerless-icon.svg";
import PrimaryIcons from "./progressbar/PrimaryIcons";
import NavigationButtons from "./components/buttons/NavigationButtons";
import "../index.css";
import CVLIcon from "./../../../images/cvl-icon.svg";
import PrimaryGlossary from "./components/Glossary/PrimaryGlossary";
import ChevronDown from "./../../../images/chevron_down_white.png";
import PrimaryGlossaryV2 from "./components/Glossary/PrimaryGlossaryV2";
import zIndex from "@material-ui/core/styles/zIndex";

export const majorList = [
  { mood: "Aliveness/Joy", icon: AlivenessJoyIcon, inactiveColor: "#F7F0F7", activeColor: "#F0E3F0", id: 1 },
  { mood: "Grateful", icon: GratefulIcon, inactiveColor: "#FAE1FA", activeColor: "#F6C5F6", id: 2 },
  { mood: "Courageous/Powerful", icon: CourageousPowerfulIcon, inactiveColor: "#EABEE6", activeColor: "#D57DCE", id: 3 },
  { mood: "Connected/Loving", icon: ConnectedLovingIcon, inactiveColor: "#D895CA", activeColor: "#B22C96", id: 4 },
  { mood: "Accepting/Open", icon: AcceptingOpenIcon, inactiveColor: "#9A92C1", activeColor: "#362584", id: 5 },
  { mood: "Hopeful", icon: HopefulIcon, inactiveColor: "#BAD8F1", activeColor: "#77B1E4", id: 6 },
  { mood: "Curious", icon: CuriousIcon, inactiveColor: "#9DC7F7", activeColor: "#3C90F0", id: 7 },
  { mood: "Tender", icon: TenderIcon, inactiveColor: "#D6F6BE", activeColor: "#AEEE7E", id: 8 },
  { mood: "Disconnected/Numb", icon: DisconnectedNumbIcon, inactiveColor: "#F9F5AB", activeColor: "#F3ED58", id: 9 },
  { mood: "Stressed/Tense", icon: StressedTenseIcon, inactiveColor: "#EFEDA3", activeColor: "#E0DC48", id: 10 },
  { mood: "Unsettled/Doubt", icon: UnsettledDoubtIcon, inactiveColor: "#F1BF97", activeColor: "#E57F30", id: 11 },
  { mood: "Fragile", icon: FragileIcon, inactiveColor: "#DFA790", activeColor: "#C15023", id: 12 },
  { mood: "Despair/Sad", icon: DespairSadIcon, inactiveColor: "#E0A790", activeColor: "#C25022", id: 13 },
  { mood: "Embarassed/Shame", icon: EmbarassedShameIcon, inactiveColor: "#ECA49F", activeColor: "#D94941", id: 14 },
  { mood: "Angry/Annoyed", icon: AngryAnnoyedIcon, inactiveColor: "#E99790", activeColor: "#D33022", id: 15 },
  { mood: "Guilt", icon: GuiltIcon, inactiveColor: "#C3B9BA", activeColor: "#897476", id: 16 },
  { mood: "Fear", icon: FearIcon, inactiveColor: "#A19F9E", activeColor: "#44403D", id: 17 },
  { mood: "Powerless", icon: PowerlessIcon, inactiveColor: "#6C6C6C", activeColor: "#000002", id: 18 },
];

//Pass in data from DB
const MajorFeelingForm = ({ displayProgress }) => {
  const { feeling, setMajorFeeling, resultsList, addResultToList, currentQuestion, changeQuestion, resetAssociated } =
    useContext(QuestionContext);

  //OPEN/CLOSE DEFINITIONS POPUP
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [isGlossaryOpen, setIsGlossaryOpen] = useState(false);

  // Used to match our glossary mood to render upon clicking chevron within one of the primary moods
  const [displayGlossary, setDisplayGlossary] = useState(null);

  // Keep track of user clicking button - change color
  const [active, setActive] = useState("");

  // Initialize currently chosen options based on resultsList
  useEffect(() => {
    if (resultsList.length > 0) {
      let currentAnswer = resultsList[0].answer;
      setActive(currentAnswer);
    }
  }, []);

  // Set State of Choice upon user click
  const handleClick = (answer) => {
    const feelingChoice = { answer, question: 1 };

    // USER ANSWERED ASSOCIATED SO WE CAN RESET IT TO EMPTY
    if (resultsList.length >= 2) {
      // RESETS ASSOCIATED ANSWER TO EMPTY
      resetAssociated();
      // UPDATE QUESTION 1 RESULT
      addResultToList(feelingChoice);

      // ADD BORDER COLOR TO FIRST QUESTION
      setActive(answer);
      return;
    } else {
      //Update feeling state to display associated items
      setMajorFeeling(feelingChoice);

      // This updates our result list data AND controls whether user can move to next question
      addResultToList(feelingChoice);

      // Add border color to the item that was clicked
      setActive(answer);
    }
  };

  //OPEN/CLOSE DEFINITION POPUP - Passing this function as prop to PrimaryGlossary and call it within child to change state here
  const handlePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleGlossaryPopup = () => {
    setIsGlossaryOpen(!isGlossaryOpen);
  };

  // Resets DisplayGlossary to return primary feelings back to normal
  const handleDisplayGlossary = () => {
    setDisplayGlossary(null);
  };

  // Once user hits submit/replace with next then we will
  const handleSubmit = (e) => {
    e.preventDefault();

    // Passing Context User's Choice
    if (feeling) {
      console.log(`Q1: Adding ${feeling} to question 1 result list`);

      // Increment Question to render next question
      changeQuestion(currentQuestion + 1);
    }
  };

  // Opening Glossary on click WITHOUT choosing the choice/result
  const handleGlossary = (event, mood) => {
    event.stopPropagation();
    event.preventDefault();
    // handle the click event for the absolute item here

    // TODO - set glossaryId so we know it will be active
    setDisplayGlossary(mood);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="major-feeling-container">
      <div className="ff-header-container" style={{ marginLeft: "20px"}}>
        <p className="ff-header" style={{fontSize: "25px"}}>My Feelings Flow Tracker</p>
        <div className="ff-img-container" style={{marginRight: "20px"}}>
            <svg width="21" height="20">
              <image href={CVLIcon} />
            </svg>
          </div>
        </div>

        {displayProgress ? <PrimaryIcons /> : null}

        <div className="ff-split"></div>

        <div>
          <div className="ff-subheader-container">
            <h2 className="ff-subheader">My Primary Feeling</h2>
          </div>

          <p className="ff-question">
            What best describes your feelings over the past 24 hours? <span style={{ color: "#999999" }}>[select only one]</span>
          </p>
        </div>

        <div className="primary-list">
          {majorList.map(({ mood, icon, inactiveColor, activeColor, id }) => {
            // IF mood === active i.e.("powerless")
            if (mood === displayGlossary) {
              return (
                <PrimaryGlossaryV2
                  handleGlossaryPopup={handleGlossaryPopup}
                  handleDisplayGlossary={handleDisplayGlossary}
                  mood={mood}
                  id={id}
                />
              );
            } else {
              return (
                <div
                  className="primary-item-container"
                  key={id}
                  onClick={() => {
                    handleClick(mood, icon, id);
                  }}
                  // Blue Border Change on Active
                  style={{ border: `2px solid ${mood === active ? "#2449a7" : "#FFF"}` }}
                >
                  <div className="ff-icon-container" style={{ backgroundColor: `${activeColor}` }}>
                    <img src={icon} className="ff-list-icon" alt="Feeling Icon"></img>
                  </div>
                  <div type="button" name="username" value={mood} className="primary-list-item">
                    {mood}
                  </div>
                  <img
                    src={ChevronDown}
                    alt="Chevron"
                    className="primary-chevron-up"
                    style={{ backgroundColor: `${activeColor}` }}
                    onClick={(event) => handleGlossary(event, mood)}
                  />
                </div>
              );
            }
          })}
        </div>

        <div>
          <NavigationButtons />
        </div>

        {isPopupOpen ? <PrimaryGlossary handlePopup={handlePopup} /> : null}
      </form>
    </>
  );
};

export default MajorFeelingForm;
