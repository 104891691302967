import React from "react";

export const SayCategoryForm = ({category, setCategory}) => {
    const categories = ['Civilience Picks', 'Reader Picks', 'All']
    return (
        <form id="say-categories-form">
            {categories.map((cat, index) => 
                <div 
                    key={`category${index}`} 
                    className="say-category"
                    onClick={() => setCategory(cat)}
                >
                    {cat}
                    <span className="cvl-container">CVL</span>
                    <span className="coming-soon-message">Coming soon</span>
                </div>
            )}
        </form>
    );
}