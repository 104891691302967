import feelings from "../../../../images/ftt-process/feelings.jpg";
import { motion } from "framer-motion";
import summary from "../../../../images/ftt-process/summary.jpg";
import chevronUp from "../../../../images/chevron_up.png";
import chevrondown from "../../../../images/chevron_down.png";
import { Menu as MenuIcon } from "@material-ui/icons";
import calendar from "../../../../images/icons8-black-calendar-48.png";
import arrow from "../../../../images/ftt-process/arrow.png";
import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Dropdown } from "../../../LearnDetail/DetailContent/Dropdown";
const Summary = ({
  emotionalData,
  value,
  onChange,
  options,
  date1,
  date2,
  selectedDate,
}) => {
  const [showPage, setShowPage] = useState(false);

  //TODO
  //Once redux is setup, dispatch a function to grab most-recently inputted data.

  //TODO
  //Insert loading screen to wait until everything is loaded.

  const loader = () => {
    setTimeout(setShowPage(true), 3000);
  };

  const [calPopUp, setCalPopUp] = useState(false);
  const [menuPopUp, setMenuPopUp] = useState(false);

  const [expand, setExpand] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [curItem, setCurItem] = useState(options[0]);

  function truncate(string, num) {
    return string?.length > num ? string.substr(0, num - 1) + "..." : string;
  }

  // TODO: Function to expand text contnt when chevron arrow is clicked. Opposite of truncate function above.
  // function expand(e){
  //     return(
  //         e.preventDefault()
  //         )
  // }
  useEffect(() => {
    console.log("date", date1, date2);
  }, [value]);

  //Code block to convert Date value to month day style (Feb 1)
  // const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  // const month = value[0]?.getMonth()
  // const month2 = value[1]?.getMonth()
  // const day = value[0]?.getDate()
  // const day2 = value[1]?.getDate()

  // const date1 = months[month] + ' ' + day
  // const date2 = months[month2] + ' ' + day2

  // const selectedDate = date1 + ' - ' + date2

  return (
    <>
      <motion.div
        // className={expand ? 'dark-overlay':'myftt'}
        className={"myftt summary"}
        // style={ showPage ? display: 'block': display: 'none'}
        initial={{ x: 250, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        <div className="loader"></div>
        <div className="header-summary">
          {calPopUp ? (
            <Calendar
              className="react-calendar"
              selectRange={true}
              onChange={onChange}
              value={value}
            />
          ) : null}
          <div className="Calendar">
            {
              <p
                className="selected-date"
                style={
                  value === "undefined"
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                {" "}
                {date1 === date2 ? date1 : selectedDate}
              </p>
            }
            {
              <Dropdown
                options={options}
                choice={curItem}
                setChoice={setCurItem}
                comingSoonPosition="left"
              />
            }

            {curItem === options[3] ? (
              <Calendar
                className="react-calendar"
                selectRange={true}
                onChange={onChange}
                value={value}
              />
            ) : null}
          </div>
          <h2>My Feelings Flow Tracker</h2>
          <div className="colorLine" style={{ width: "100%", height: "6px" }} />
          <div className="menu">
            <div className={`logo ${!overlay ? "toggle" : null}`}>
              <MenuIcon
                className="menuIcon"
                onClick={() => {
                  setMenuPopUp(!menuPopUp);
                }}
              />
              {/* TODO: Add dropdown menu here */}
              {/* {menuPopUp && 
                        <Dropdown
                        />} */}
            </div>
            <p
              onClick={() => {
                setExpand("one");
                setOverlay(!overlay);
              }}
              className={` menu-item ${
                (expand === "one") & overlay ? "toggle" : null
              }`}
            >
              Thoughts
            </p>

            <p
              onClick={() => {
                setExpand("two");
                setOverlay(!overlay);
              }}
              className={` menu-item ${
                (expand === "two") & overlay ? "toggle" : null
              }`}
            >
              Feelings
            </p>

            <p
              onClick={() => {
                setExpand("three");
                setOverlay(!overlay);
              }}
              className={` menu-item ${
                (expand === "three") & overlay ? "toggle" : null
              }`}
            >
              Body Sensations
            </p>

            <p
              onClick={() => {
                setExpand("four");
                setOverlay(!overlay);
              }}
              className={` menu-item ${
                (expand === "four") & overlay ? "toggle" : null
              }`}
            >
              Actions
            </p>
          </div>
        </div>
        <div
          className="process-container"
          style={{
            backgroundImage: `url(${summary})`,
            backgroundPositionY: "-3.5em",
            backgroundSize: "cover",
            overflow: "hidden",
          }}
        >
          <div className="header-container">
            <p>Process: Summary</p>
          </div>
          <div className="body-container-summary">
            {/* THOUGHTS */}
            <div
              className={`one process ${
                overlay & (expand === "one")
                  ? "body-summary-expanded"
                  : "body-summary"
              }`}
              onClick={() => {
                setExpand("one");
                setOverlay(!overlay);
              }}
            >
              {overlay & (expand === "one") ? (
                // TODO: NEED TO GET ACTUAL DATA
                // <p>{truncate(emotionalData.q5, 70)}</p> :
                // <p>{truncate(emotionalData.q5, 50)}</p>

                // Placeholder:
                <p>
                  {" "}
                  {truncate(
                    "I am thinking to start an exercise challenge, which makes me feel energetic...",
                    70
                  )}
                </p>
              ) : (
                <p>
                  {" "}
                  {truncate(
                    "I am thinking to start an exercise challenge, which makes me feel energetic...",
                    50
                  )}
                </p>
              )}

              <img className="process-arrow" src={arrow} alt="pointer" />
              <img
                // className=
                src={overlay & (expand === "one") ? chevronUp : chevrondown}
                onClick={() => setExpand(!expand)}
                style={{ width: "15px", height: "15px", marginLeft: "5px" }}
                alt="chevron"
              />
            </div>
            {/* Feelings - primary and associated combined */}
            <div
              className={`two process ${
                overlay & (expand === "two")
                  ? "body-summary-expanded"
                  : "body-summary"
              }`}
              onClick={() => {
                setExpand("two");
                setOverlay(!overlay);
              }}
            >
              {/* Primary  + Associated*/}
              {!overlay && <p style={{ fontWeight: "bold" }}>{ emotionalData.q1}</p>}

              {!overlay && emotionalData.q2?.slice(0,2).map((i) => {
                return <p keys={i} className="primary">{i}</p>;
              })}

              <img className="process-arrow" src={arrow} alt="pointer" />
              <img
                src={overlay & (expand === "two") ? chevronUp : chevrondown}
                onClick={() => setExpand(!expand)}
                style={{ width: "15px", height: "15px", marginLeft: "5px" }}
                alt="chevron"
              />
               {overlay && expand === "two" && (
                <div>
                  {emotionalData.q2?.slice(0,5).map((i, index) => (
                    <p key={index}>{i}</p>
                  ))}
                </div>
              )}

            </div>
            {/*  Associated */}
            {/* <div 

                    className={`three ${overlay & expand ==='three' ? 'body-summary-expanded':'body-summary'}`}
                    onClick={()=>{setExpand('three'); setOverlay(!overlay)}}
                    >
                        <p> {emotionalData.q1}</p>

                        <img 
                        className='chevrons'
                        src={overlay & expand ==='three' ? chevronUp : chevrondown} 
                        onClick={()=>setExpand(!expand)}
                        style={{ width: "15px", height: "15px", marginLeft: '5px',
                    }} alt='chevron' />

                    </div> */}

            {/* Body Sensations */}
            <div
              className={`four process ${
                overlay & (expand === "three")
                  ? "body-summary-expanded"
                  : "body-summary"
              }`}
              onClick={() => {
                setExpand("three");
                setOverlay(!overlay);
              }}
            >
              {!overlay && emotionalData.q4?.slice(0, 3).map((i) => {
                return <p key={i}>{i}</p>;
              })}
              <img className="process-arrow" src={arrow} alt="pointer" />
              <img
                src={overlay & (expand === "three") ? chevronUp : chevrondown}
                onClick={() => setExpand(!expand)}
                style={{ width: "15px", height: "15px", marginLeft: "5px" }}
                alt="chevron"
              />
              {overlay && expand === "three" && (
                <div>
                  {emotionalData.q4?.slice(0,5).map((i, index) => (
                    <p key={index}>{i}</p>
                  ))}
                </div>
              )}
            </div>

            {/* Actions */}
            <div
              className={`five process ${
                overlay & (expand === "four")
                  ? "body-summary-expanded"
                  : "body-summary"
              }`}
              onClick={() => {
                setExpand("four");
                setOverlay(!overlay);
              }}
            >
              {/* Actions */}
              {overlay & (expand === "five") ? (
                
                // TODO: NEED TO GET AN ACTUAL DATA FROM DB
                // <p>{truncate(emotionalData.q3, 200)}</p> :
                // <p>{truncate(emotionalData.q3, 50)}</p>

                // Placeholder:
                <p>
                  {" "}
                  {truncate(
                    "I worked out for 1 hour and I made myself a lavender tea",
                    70
                  )}
                </p>
              ) : (
                <p>
                  {" "}
                  {truncate(
                    "I worked out for 1 hour and I made myself a lavender tea",
                    50
                  )}
                </p>
              )}
              <img className="process-arrow" src={arrow} alt="pointer" />
              <img
                src={overlay & (expand === "four") ? chevronUp : chevrondown}
                onClick={() => setExpand(!expand)}
                style={{ width: "15px", height: "15px", marginLeft: "5px" }}
                alt="chevron"
              />
            </div>
            <div
              className={overlay ? "dark-overlay" : ""}
              // onClick={setOverlay(!overlay)}
            ></div>
            <div></div>
            <p className="resources-link">Resources</p>
            <div className="Mytooltiptext">Coming Soon</div>
          </div>
        </div>
      </motion.div>
    </>
  );
};
export default Summary;
