import feelings from "../../../../images/ftt-process/feelings.jpg";
import { motion } from "framer-motion";

const Feelings = ({ emotionalData }) => {
  return (
    <motion.div
      className="myftt"
      initial={{ x: 250, opacity: 0 }}
      animate={{ x: 0, opacity: 1, type: "tween" }}
      // transition={{delay:0.2}}
    >
      <div className="header">
        <h2>My Feelings Flow Tracker</h2>
        <div className="colorLine" style={{ width: "100%", height: "6px" }} />
      </div>
      <div
        className="process-container"
        style={{ backgroundImage: `url(${feelings})`, backgroundSize: "cover" }}
      >
        <div className="header-container">
          <p>Process: Feelings</p>
        </div>
        <div className="body-container">
          <div className="body-description">
            <p>... gave rise to these feelings in me:</p>
          </div>
          <div className="body-summary">
            {
              <p style={{ fontWeight: "bold" }} className="primary-feeling">
                {emotionalData.q1}
              </p>
            }
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "0.1fr 0.1fr",
                overflow: "scroll",
              }}
              className="scroll-wrapper"
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: "18px",
                }}
              >
                {emotionalData.q2?.map((q) => (
                  <p>{q}</p>
                ))}
                {/* {emotionalData.q2?.map((q) => (
                  <p>{q}</p>
                ))}
                {emotionalData.q2?.map((q) => (
                  <p>{q}</p>
                ))}
                {emotionalData.q2?.map((q) => (
                  <p>{q}</p>
                ))}
                {emotionalData.q2?.map((q) => (
                  <p>{q}</p>
                ))} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
export default Feelings;
