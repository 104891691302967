import "./index.css";
import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { QuestionContext } from "../../context/question.context";
import { selectCommunityData } from "../../store/communityHealthSlice";
import MajorFeelingForm from "./FeelingsFlowTrackerForms/MajorFeelingForm";
import AssociatedFeelingForm from "./FeelingsFlowTrackerForms/AssociatedFeelingForm";
import ThoughtForm from "./FeelingsFlowTrackerForms/ThoughtForm";
import BodySensationForm from "./FeelingsFlowTrackerForms/BodySensationForm";
import ActionForm from "./FeelingsFlowTrackerForms/ActionForm";
import Summary from "./FeelingsFlowTrackerForms/SummaryForm";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { BackgroundContext } from "../../context/background.context";

const FeelingsFlowQuestion = (props) => {
  // Current Question tracks when to render new question upon user clicking next or back
  const { resultsList, addResultToList, currentQuestion, changeQuestion, isQuestionOne, resetResultsList } =
    useContext(QuestionContext);
  const { isModalOpen, removeOpacity, applyOpacity } = useContext(BackgroundContext);

  //TODO - UNCOMMENT ONCE WASHINGTON DC ZIPCODE IS REMOVED
  // const [userLocation, setUserLocation] = useState({});

  // const [opacityDark, setOpacityDark] = useState(false);

  // Contains our email/sub for the civAPI
  const {
    tokenPayload,
    setPage,
    reachedLimit,
    setReachedLimit,
    glossaryOpen,
    setGlossaryOpen,
    opacityDark,
    setOpacityDark,
    setIsAlertOpen,
  } = props;

  //TODO - UNCOMMENT ONCE WASHINGTON DC ZIPCODE IS REMOVED
  //using communityHealthSlice to get user's zipcode, latitude & longitude
  // const data = useSelector(selectCommunityData);
  // const { ZIP, latitude, longitude } = data;
  // console.log(`ZIP: ${ZIP} | latitude: ${latitude} | longitude: ${longitude}`);
  //const [reachedLimit, setReachedLimit] = useState(false);

  //TODO - UNCOMMENT ONCE WASHINGTON DC ZIPCODE IS REMOVED
  // useEffect(() => {
  //   console.log(`User Location: Zipcode: ${ZIP}, Latitude: ${latitude}, Longitude: ${longitude}`);
  //   setUserLocation({ ZIP, latitude, longitude });
  // }, [data]);

  // // Send User Data to our DynamoDB using civAPI
  const logUser = async (tokenPayload) => {
    // GUARD TO NOT ALLOW FORM SUBMISSION UNLESS ALL 5 QUESTIONS ARE ANSWERED
    if (resultsList[1].answer.length === 0) {
      return;
    }

    console.log("USER SUBMITTED FORM - see dynamoDB");
    // console.log("userLocation: ", userLocation);

    //TODO - UNCOMMENT ONCE WASHINGTON DC ZIPCODE IS REMOVED
    // const userZipcode = userLocation.ZIP;
    // const userLatitude = JSON.stringify(userLocation.latitude);
    // const userLongitude = JSON.stringify(userLocation.longitude);
    // console.log(`userZipcode: ${userZipcode} | userLatitude: ${userLatitude} | userLongitude: ${userLongitude}`);

    // TEMPORARY: HARDCODED TO WASHINGTON DC
    const zipcode = 20500;
    const latitude = 38.897676;
    const longitude = -77.036529;
    const userZipcode = JSON.stringify(zipcode);
    const userLatitude = JSON.stringify(latitude);
    const userLongitude = JSON.stringify(longitude);
    console.log(`userZipcode: ${userZipcode} | userLatitude: ${userLatitude} | userLongitude: ${userLongitude}`);

    // Washington DC LAT/LONG = Lat: 38.897676 Long: -77.036529
    setTimeout(async () => {
      var isoDateString = new Date().toISOString();
      try {
        // TODO - add condition to check if all 5 questions have been answered to pass
        if (tokenPayload) {
          const payload = {
            body: {
              id: Date.now() + "-" + tokenPayload.sub,
              userId: tokenPayload.sub,
              activity: "emotionalHealthSurvey",
              email: tokenPayload.email,
              survey: {
                q1: resultsList[0].answer,
                q2: resultsList[1].answer,
                q3: resultsList[2].answer,
                q4: resultsList[3].answer,
                q5: resultsList[4].answer,
                location: {
                  zipcode: userZipcode,
                  latitude: userLatitude,
                  longitude: userLongitude,
                },
              },
              dateAdded: isoDateString,
            },
          };

          API.post("civAPI", "/emotional/store", payload).then((response) => {
            if (!response.error) {
              console.log(response);
            } else {
              console.log(response);
            }
          });

          console.log("Logged User:", JSON.stringify(payload));
        }
      } catch (error) {
        // TODO - add error to mention to user to answer all 5 questions
        console.log("Error currentSession: ", error);
      }
    });
    setPage("Process");
    // RESETS RESULT LIST TO EMPTY ARRAY UPON SUCCESSFUL SUBMISSION
    resetResultsList();
    // RESET CURRENT QUESTION BACK TO 1 TO RESET FORM IF USER WANTS TO SUBMIT ANOTHER FORM
    changeQuestion(1);
  };

  return (
    <div
      className="ff-container"
      style={{
        backgroundColor:
          glossaryOpen || opacityDark ? "#B7B7B7" : currentQuestion === 6 && !glossaryOpen && !opacityDark ? "#ffffff" : "#F7F5F0",
        opacity: reachedLimit ? 0.6 : 1,
        height: isQuestionOne && isModalOpen ? "2165px" : null,
        zIndex: 1,
      }}
    >
      {/* Render form content depending on which question user is on */}
      {currentQuestion === 1 ? (
        <>
          <MajorFeelingForm displayProgress={true} />
        </>
      ) : null}
      {currentQuestion === 2 ? (
        <>
          <AssociatedFeelingForm
            reachedLimit={reachedLimit}
            setReachedLimit={setReachedLimit}
            glossaryOpen={glossaryOpen}
            setGlossaryOpen={setGlossaryOpen}
            displayChoice={true}
            displayProgress={true}
            setIsAlertOpen={setIsAlertOpen}
          />
        </>
      ) : null}
      {currentQuestion === 3 ? (
        <>
          <ThoughtForm displayProgress={true} />
        </>
      ) : null}
      {currentQuestion === 4 ? (
        <>
          <BodySensationForm
            reachedLimit={reachedLimit}
            setReachedLimit={setReachedLimit}
            glossaryOpen={glossaryOpen}
            setGlossaryOpen={setGlossaryOpen}
            displayProgress={true}
            setIsAlertOpen={setIsAlertOpen}
          />
        </>
      ) : null}
      {currentQuestion === 5 ? (
        <>
          <ActionForm displayProgress={true} />
        </>
      ) : null}
      {currentQuestion === 6 ? (
        <div>
          <Summary
            removeOpacity={removeOpacity}
            applyOpacity={applyOpacity}
            reachedLimit={reachedLimit}
            setReachedLimit={setReachedLimit}
            opacityDark={opacityDark}
            setOpacityDark={setOpacityDark}
            setGlossaryOpen={setGlossaryOpen}
            setIsAlertOpen={setIsAlertOpen}
          />

          <div className="ff-submit-btn-container">
            <button className="ff-submit-btn" onClick={() => logUser(tokenPayload)}>
              Submit Form
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FeelingsFlowQuestion;
