import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'

import LearnDetail from "../../components/LearnDetail";
import EmotionalHealth from "../../components/PublicHealth/EmotionalHealth";
import '../../components/Shared/index.css';

function LearnMore() {
    //land on this page from public health widgets

    //use this page as a template for the different widgets (community health emotional...)

    //at this page determine how to identify if it's emotional or community ...

    //initiate verticals page here:
    const [showVerticals, setShowVerticals] = useState(false)

const location = useLocation()
const 
    {headerTitle, 
    minuteCardHeader,
    minuteCardText,
    disease,
    page,
    // setShowVerticals,
}
      = location.state

    useEffect(() => {
        // props.reRender();
    }, []);
    // console.log('loc',location)
    return (<div className="LearnMore">
    {/*learn detail = community health page  */}
    <LearnDetail
    headerTitle={headerTitle}
    disease={disease}
    // setDisease={setDisease}
    // page={page}
    // setPage={setPage}
    showVerticals={showVerticals}
    // setShowVerticals={setShowVerticals}
    minuteCardHeader={minuteCardHeader}
    minuteCardText={minuteCardText}

    />
    {/* <EmotionalHealth
    minuteCardHeader={minuteCardHeader}
    setMinuteCardHeader={setMinuteCardHeader}
    minuteCardText={minuteCardText}
    setMinuteCardText={setMinuteCardText}
    
    disease={disease}
    setDisease={setDisease}
    page={page}
    setPage={setPage}
    showVerticals={showVerticals}
    setShowVerticals={setShowVerticals}/> */}
    
    </div>);
}

export default LearnMore;