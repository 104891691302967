import "../../index.css";
import MajorFeelingForm from "../MajorFeelingForm";
import AssociatedFeelingForm from "../AssociatedFeelingForm";
import ThoughtForm from "../ThoughtForm";
import BodySensationForm from "../BodySensationForm";
import ActionForm from "../ActionForm";
import SaveButton from "./buttons/SaveButton";

const FORM_TYPE_OPTIONS = {
  MajorFeelingForm: "MajorFeelingForm",
  AssociatedFeelingForm: "AssociatedFeelingForm",
  ThoughtForm: "ThoughtForm",
  BodySensationForm: "BodySensationForm",
  ActionForm: "ActionForm",
};

const getForm = (formType, FORM_TYPE_OPTIONS) =>
  ({
    [FORM_TYPE_OPTIONS.MajorFeelingForm]: MajorFeelingForm,
    [FORM_TYPE_OPTIONS.AssociatedFeelingForm]: AssociatedFeelingForm,
    [FORM_TYPE_OPTIONS.ThoughtForm]: ThoughtForm,
    [FORM_TYPE_OPTIONS.BodySensationForm]: BodySensationForm,
    [FORM_TYPE_OPTIONS.ActionForm]: ActionForm,
  }[formType]);

// Passing in type of form and callback function
export const EditForm = (props) => {
  const formType = props.formType;
  // const formToRender = getForm(formType, FORM_TYPE_OPTIONS)
  const {
    applyOpacity,
    setReachedLimit,
    reachedLimit,
    questionNum,
    setIsQuestionOne,
    displayProgress,
    setGlossaryOpen,
    setIsAlertOpen,
  } = props;
  console.log("EDIT FORM: ", displayProgress);

  // Adds background opacity upon opening modal window
  applyOpacity();

  //If user is editting question 1, then we will use this to apply additional height to fit our primary feeling form
  questionNum === 1 ? setIsQuestionOne(true) : setIsQuestionOne(false);

  /* Finds the matching component to render 
  i.e.) formType = "ActionFrom" matches [FORM_TYPE_OPTIONS.ActionForm]: ActionForm -> RenderEditForm === <ActionForm /> */
  const RenderEditForm = getForm(formType, FORM_TYPE_OPTIONS);

  return (
    <>
      <div className="summary-edit-form">
        <div className="ff-img-container"></div>
        <div className="summary-test">
          {/* Pass formType and callback function to close popup once */}
          <div className="summary-edit-btn-placement">
            <SaveButton formType={props.formType} handleEdit={props.handleEdit} />
          </div>
        </div>
        {/* Must pass reached limit otherwise we will get error when trying to remove an item */}
        <RenderEditForm
          setReachedLimit={setReachedLimit}
          reachedLimit={reachedLimit}
          setGlossaryOpen={setGlossaryOpen}
          setIsAlertOpen={setIsAlertOpen}
        />
      </div>
    </>
  );
};

export default EditForm;
