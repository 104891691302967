export const getGradientColorFromValue = (val) => {
  val = Math.round(val);
  const baseColor = (100 - val) * 1.7 - 50
  const l = 55 - val / 5
  return `linear-gradient(45deg, hsl(${baseColor}, 100%, ${l}%), hsl(${baseColor - 40}, 100%, ${l}%) )`;
};

export const getColorFromValue = (val) => {
    val = Math.round(val);
    return  `hsl(${(100 - val) * 1.7 - 50}, 100%, 50%)`
}