import { Info } from "../Shared/Info"
import { Dropdown } from "./DetailContent/Dropdown"
import Logo from "../../images/civilience_triangle.png"

export const DetailTitle = ({ headerTitle, setPage, setShowVerticals}) => {
  return (
    <section id="title-and-info">
      <img src={Logo} alt="Civilience Logo" width="35" height="35" style={{verticalAlign: 'text-bottom'}}/>
      <Dropdown 
        title="HEALTH"
        handleTitleClick={() => setShowVerticals(prev => !prev)} 
        options={['Community Health', 'Emotional Health']} 
        comingSoonOptions={['Wellness', 'Chronic Health']}
        choice={headerTitle} 
        setChoice={setPage}
      />
      <Info />
    </section>
  )
}