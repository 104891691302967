import "../index.css";
import { useContext, useState, useEffect } from "react";
import { QuestionContext } from "../../../context/question.context";
import Associatedicons from "./progressbar/Associatedicons";
import NavigationButtons from "./components/buttons/NavigationButtons";
import CVLIcon from "./../../../images/cvl-icon.svg";
import InfoIcon from "./../../../images/info-icon.svg";
import GLOSSARY_DATA from "./glossary-data";
import AssociatedGlossary from "./components/Glossary/AssociatedGlossary";

// finds list that matches User's mood - ex. {title: Accepting/Open, List: [...]}
const getList = (majorFeeling, associatedList) => {
  const mood = associatedList.find(
    (moodList) => moodList.title === majorFeeling
  );
  return mood;
  // return mood.list;
};

// If this mood is in the list then render mood background blue
const isMoodInList = (userMoodsList, mood) => {
  return userMoodsList.includes(mood) ? true : false;
};

// displayChoice is a boolean to display or hide icon & user's Choice
const AssociatedFeelingForm = ({
  displayChoice,
  reachedLimit,
  setReachedLimit,
  displayProgress,
  glossaryOpen,
  setGlossaryOpen,
  setIsAlertOpen,
}) => {
  const { addResultToList, changeQuestion, currentQuestion, resultsList } =
    useContext(QuestionContext);
  const [userMoodsList, setUserMoodsList] = useState([]);
  const [displayList, setDisplayList] = useState([]);

  //OPEN/CLOSE DEFINITIONS POPUP
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  //Moved associatedMoodList to glossary-data.js to reuse
  const associatedMoodList = GLOSSARY_DATA[1].items;

  //Selecting user's mood choice from question 1
  const findMood = resultsList.find((result) => result.question === 1);

  // Gets user mood description - ex. Accepting/Open
  const majorFeeling = findMood.answer;

  const getListAndIcon = getList(majorFeeling, associatedMoodList);
  // Passing user mood description & list containing all 18 moods
  const iconToDisplay = getListAndIcon.icon;
  const listToDisplay = getListAndIcon.list;

  // Set list to display on initial render depending on major feeling
  useEffect(() => {
    setDisplayList(listToDisplay);
  }, []);

  // Initialize currently chosen options based on resultsList
  useEffect(() => {
    if (resultsList.length > 1) {
      let currentAnswer = resultsList[1].answer;
      setUserMoodsList(currentAnswer);
    }
  }, []);

  //OPEN/CLOSE DEFINITION POPUP - Passing this function as prop to PrimaryGlossary and call it within child to change state here
  const handlePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setGlossaryOpen(true);
  };

  const handleClick = (associatedMood) => {
    //If mood is in list then we will remove it, else add it to the end
    const moodToRemove = userMoodsList.find((mood) => {
      return mood === associatedMood;
    });

    //User's Associated Feeling

    // CHECK if Before = After
    // if (beforeMajorFeeling !== majorFeeling) {
    //   userMoodsList.length = 0;
    // }

    // beforeMajorFeeling = majorFeeling

    const answer = associatedMood;

    //REMOVE ITEM - this will change choice back to grey on second click
    if (moodToRemove) {
      // console.log(`Removing ${associatedMood} from list.`);
      const updatedList = userMoodsList.filter((item) => item !== moodToRemove);

      setUserMoodsList(updatedList);
      updatedList.length > 0
        ? addResultToList({ answer: updatedList, question: 2 })
        : resultsList.splice(currentQuestion - 1, 1);

      console.log("Updated List: ", updatedList);

      if (userMoodsList.length < 11) {
        setReachedLimit(false);
        setIsAlertOpen(false);
      }
    } else {
      //NOT IN LIST - ADD MOOD
      // console.log(`${associatedMood} is not in list.`);

      if (userMoodsList.length >= 12) {
        setReachedLimit(true);
        setIsAlertOpen(true);
        return;
      }

      setUserMoodsList([...userMoodsList, answer]);
      addResultToList({ answer: [...userMoodsList, answer], question: 2 });
    }
  };

  // User submits with next button
  const handleSubmit = (e) => {
    e.preventDefault();

    //Passing object for this question's results containing: choice list and question number
    const questionResults = { answer: userMoodsList, question: 2 };
    if (userMoodsList.length !== 0) {
      //addResultToList(questionResults);
      // Increment Question Counter to render next question
      changeQuestion(currentQuestion + 1);
    }
  };

  return (
    <div>
      <div
        className="ff-header-container"
        style={{ opacity: glossaryOpen ? 0.6 : 1, marginLeft: "20px" }}
      >
        <p className="ff-header" style={{ fontSize: "25px" }}>
          My Feelings Flow Tracker
        </p>
        <div className="ff-img-container" style={{ marginRight: "20px" }}>
          <svg width="21" height="20">
            <image href={CVLIcon} style={{ opacity: glossaryOpen ? 0.6 : 1 }} />
          </svg>
        </div>
      </div>

      {displayProgress ? <Associatedicons glossaryOpen={glossaryOpen} /> : null}

      <div className="ff-split"></div>

      <div>
        <div className="ff-subheader-container">
          <h2 className="ff-subheader">Associated Feeling(s)</h2>
          <div className="ff-img-container">
            <svg
              width="24"
              height="24"
              className="info-icon"
              onClick={() => handlePopup()}
            >
              <image href={InfoIcon} width="24" height="24" />
            </svg>
          </div>
        </div>

        <p className="ff-question">
          What additional, associated feeling(s) have you had over the past 24
          hours?
          <span style={{ color: "#999999" }}> [select one or multiple]</span>
        </p>
      </div>

      {displayChoice ? (
        <div className="ff-user-container">
          <>
            <div
              className="ff-icon-container"
              style={{ backgroundColor: `${iconToDisplay.backgroundColor}` }}
            >
              <img
                src={iconToDisplay.primaryMood}
                className="ff-list-icon"
                alt="Feeling Icon"
              ></img>
            </div>
            <div className="ff-user-choice">{majorFeeling}</div>
          </>
        </div>
      ) : null}
      <form onSubmit={handleSubmit}>
        <div className="ff-list" style={{ opacity: glossaryOpen ? 0.6 : 1 }}>
          {displayList.map((mood) => {
            return (
              <div className="ff-item-container" key={mood}>
                <div
                  type="button"
                  name="username"
                  value={mood}
                  onClick={() => handleClick(mood)}
                  className={`${
                    isMoodInList(userMoodsList, mood)
                      ? "ff-list-item-clicked"
                      : "ff-list-item"
                  }`}
                >
                  {mood}
                </div>
              </div>
            );
          })}
        </div>
      </form>
      <div>
        {/* Your other components or code */}
        <NavigationButtons
          reachedLimit={reachedLimit}
          setReachedLimit={setReachedLimit}
          glossaryOpen={glossaryOpen}
          setIsAlertOpen={setIsAlertOpen}
        />
        {/* <Associatedicons /> */}

        {/* For testing purposes */}
        {isPopupOpen ? (
          <AssociatedGlossary
            findMood={findMood}
            handlePopup={handlePopup}
            setGlossaryOpen={setGlossaryOpen}
          />
        ) : null}

        {/* {isAlertOpen && (
        <div className="alert-div alert-message">
          <span>You may only select 12</span>
          <button className="close-button" style= {{border: "none", marginRight: 5, marginTop: -8}} onClick={()=>{setIsAlertOpen(false); setReachedLimit(false)}}>
            X
          </button>
        </div>
      )} */}
      </div>
    </div>
  );
};

export default AssociatedFeelingForm;
