import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import "./index.css";

import InfoIcon from "../../images/info.png";

import { WidgetContainer } from "./WidgetContainer";
import DetailFooter from "../Shared/DetailFooter";
import { Ad } from "../Shared/Ad";
import { Info } from "../Shared/Info";
import { selectCommunityData, selectStatus } from "../../store/communityHealthSlice";
import { getGradientColorFromValue } from "../LearnDetail/DetailContent/getColorFromValue.js";
import { Slogan } from "../Shared/Slogan";
import FeelingsFlowQuestion from "./FeelingsFlowQuestion";
import { API } from "aws-amplify";
// import BodySensationForm from "./BodySensationForm";
// import { useContext } from "react";
// import { QuestionContext } from "../../context/question.context";

export function PublicHealth(props) {
  // Will pass payload to emotionalHealth widget

 
  const { tokenPayload } = props;
  // console.log("User signed in - tokenPayload: ", tokenPayload);

  const options = {
    community: ["Location"],
    emotional: ["Today","This Week", "This Month", "Select Date/Range"],
  }
  

  const comingSoonOptions = {
    community: ["Disease", "Transmission Type", "Demographics", "Advanced"],
    emotional: ["Thoughts", "Feelings","Body Sensations", "Actions"],
  };
  const data = useSelector(selectCommunityData);

  const linkToStateMap = () => {
    window.open(data.url_state);
  };

  const linkToNationalMap = () => {
    window.open(data.url_USA);
  };

  const countyData = data.K_historical;
  const countyLabel = data ? data.county_name.split(",")[0] : "County";

  const stateData = data.K_state;
  const stateLabel = data ? data.state : "State";

  const countryData = data.k_US;
  const countryLabel = "USA";

  const status = useSelector(selectStatus);
  const emotional = {
      options:options.emotional,
      comingSoonOptions: comingSoonOptions.emotional,
      headerTitle: "Emotional Health",
      minuteCardOneHeader: "Feelings Flow Tracker",
      minuteCardOneText: "Identify, understand, and process your emotions",
      minuteCardTwoHeader: "Boundary Builder",
      minuteCardTwoText: "Understand your personal boundaries",
      sayComponentText:
        "In addition to Reading, Writing and Arithmetic, should schools teach Relationships (how we relate to ourselves, others and situations)?",
      saySectionLabel: "VIEW ACTIVITY AS",
      tokenPayload,
    }

  return (
    <div className="publicHealth">
      <div className="blue" id="publichealth-top-blue"></div>
      <div id="top-content">
        <Ad />
        <Slogan />
        <div className="title">
          <h1>HEALTH</h1>
          <Info />
        </div>
      </div>
      <div className="body">
        <WidgetContainer name="Community Health">
          <div className="mainContent">
            <div className="covid-info">
              <div className="panel-left">
                <p className="simpleinfo"> Composite of Covid-19 case prevalence, severity and health resources utilization</p>
                <div className="info-days-hours">
                  <div className="text-center border-right">
                    <p className={`font-lg ${status}`}>{data ? Math.round(data.K_historical) : 0}</p>
                    <p className="font-sm">7 DAYS</p>
                  </div>

                  <div className="text-center border-right">
                    <p className={`font-lg ${status}`}>{data ? Math.round(data.K_state) : 0}</p>
                    <p className="font-sm">24 HRS</p>
                  </div>
                </div>
              </div>
              <div className="panel-right">
                <p
                  className={`circle-bg font-xl ${status}`}
                  style={{ background: status === "fulfilled" ? getGradientColorFromValue(data.K) : "hsl(0 0% 90%)" }}
                >
                  {data ? Math.round(data.K) : 0}
                </p>
                <p className=""></p>
              </div>
            </div>

            {/* Color Slider */}
            <input type="range" className="color-slider" min="0" max="100" value={data ? data.K : 0} readOnly />
            <div className="text-center">
              Local Area Infection Risk
              <div className="Mytooltip">
                <img src={InfoIcon} className="InfoIcon"></img>
                <span className="Mytooltiptext wide">
                  <p className="mb-10">
                    <strong>Risk score:</strong> 0 (low) -{">"} 100+ (high)
                  </p>
                  <p>
                    <strong>Prevalence:</strong> number of new cases.
                  </p>
                  <p>
                    <strong>Severity:</strong> number of new morbidity cases, hospitalizations, deaths.
                  </p>
                  <p className="mb-10">
                    <strong>Health resources utilization:</strong> percentage use of infrastructure, equipment, supplies or medicines.
                  </p>
                  <p>
                    <strong>Data source:</strong> CDC
                  </p>
                </span>
              </div>
            </div>
          </div>
          {data && (
            <Link
              state={{
                headerTitle: "Community Health",
                options: options.community,
                comingSoonOptions: comingSoonOptions.community,
                countyData,
                countyLabel,
                stateData,
                stateLabel,
                countryData,
                countryLabel,
                // disease: disease,
                // setDisease:setDisease,
                // page: page,
                // setPage:setPage,
                // showVerticals: showVerticals,
                // setShowVerticals: setShowVerticals,
                minuteCardOneHeader: "My Guidelines",
                minuteCardOneText: "Know what guidelines apply to you",
                minuteCardTwoText: "Know guidelines and your personal risk of catching & spreading",
                minuteCardTwoHeader: "My Risk",
                sayComponentText: " Do you think the Covid-19 pandemic is over? Why or why not?",
                saySectionLabel: "VIEW RISK BY",
                tokenPayload
              }}
              to={"/learnmore"}
              className="learnmore"
            >
              Learn More
            </Link>
          )}
        </WidgetContainer>
        <WidgetContainer name="Emotional Health" comingSoon={false}>
          {/* <FeelingsFlowQuestion /> */}
          <Link
            state={{
              options: options.emotional,
              comingSoonOptions: comingSoonOptions.emotional,
              headerTitle: "Emotional Health",
              minuteCardOneHeader: "Feelings Flow Tracker",
              minuteCardOneText: "Identify, understand, and process your emotions",
              minuteCardTwoHeader: "Boundary Builder",
              minuteCardTwoText: "Understand your personal boundaries",
              sayComponentText:
                "In addition to Reading, Writing and Arithmetic, should schools teach Relationships (how we relate to ourselves, others and situations)?",
              saySectionLabel: "VIEW ACTIVITY AS",
              tokenPayload,
              
            }}
            to={"/emotionalhealth"}
            className="learnmore"
          >
            Learn More
            {/* Learn Detail Component and Props */}
            
          </Link>
        </WidgetContainer>
        <WidgetContainer name="Wellness" comingSoon={true} />
        <WidgetContainer name="Chronic Health" comingSoon={true} />
        {/* <Outlet context={[emotional]}/> */}
      </div>
      <DetailFooter />
    </div>
  );
}
