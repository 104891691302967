import React from "react";
import IframeResizer from "iframe-resizer-react";
import { Dropdown } from "../DetailContent/Dropdown";
import SwitchSurvey from "../DetailContent/SwitchSurvey";

export const DiseaseSurvey = ({ disease, setDisease, showVerticals, page }) => {
    const links = {
        "Covid 19":
            "https://main.d26fb7k5zbcwdt.amplifyapp.com/html/survey/covid1min.html",
        //"http://127.0.0.1:5500/html/survey/covid1min.html",
        Monkeypox:
            "https://main.d26fb7k5zbcwdt.amplifyapp.com/html/survey/monkeypox.html",
        //"http://127.0.0.1:5500/html/survey/monkeypox.html",
        "Hepatitics C": "",
        HIV: "",
        Influenza: "",
        Norovirus: "",
    };

    const iFrame = (
        <IframeResizer
            log
            inPageLinks
            src={links[disease]}
            style={{ width: "1px", minWidth: "100%" }}
            scrolling="no"
        />
    );
    return (
        <div style={{ display: showVerticals ? 'none' : 'block' }}>
            <span className="colorLine wid-100 height-4" />
            <div className="survey-container">
                <div className="top-row">
                    <label>Screen For</label>
                    <Dropdown
                        options={['Covid 19', 'Monkeypox']}
                        comingSoonOptions={['Influenza', 'Norovirus', 'HIV', 'Hepatitics C']}
                        choice={disease}
                        setChoice={setDisease}
                        comingSoonPosition="left"
                    />
                    <SwitchSurvey />
                </div>
                {/* if production, use this src url instead: https://main.d26fb7k5zbcwdt.amplifyapp.com/html/survey/one-minute.html */}
                {links[disease] === "" ? (
                    <h2>{disease} survey is not available yet.</h2>
                ) : (
                    iFrame
                )}
                <br />
            </div>
            <span className="colorLine wid-100 height-4" />
        </div>
    );
};

