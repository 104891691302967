import { Block } from "@material-ui/icons";
import { useState } from "react";
import "./process.styles.scss";
import Summary from "./Process/Summary";
import Thoughts from "./Process/Thoughts";
import BodySensations from "./Process/BodySensations";
import Actions from "./Process/Actions";
import Feelings from "./Process/Feelings";
import Transition from "./Process/Transition";

function ProcessScreen({ emotionalData, setPage, value, onChange, options }) {
  console.log(emotionalData);

  const [page, setFormPage] = useState(1);
  const handlePrev = () => setFormPage(page - 1);
  const handleNext = () => setFormPage(page + 1);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional, smooth scrolling animation
    });
  };

  return (
    <div>
      <div className="calendar-container">{/* <Calendar onChange={setDate} value={date}/> */}</div>
      <div className="process-container">
        {page === 1 ? (
          <Transition value={value} onChange={onChange} emotionalData={emotionalData} />
        ) : page === 2 ? (
          <Thoughts value={value} onChange={onChange} emotionalData={emotionalData} />
        ) : page === 3 ? (
          <Feelings value={value} onChange={onChange} emotionalData={emotionalData} />
        ) : page === 4 ? (
          <BodySensations value={value} onChange={onChange} emotionalData={emotionalData} />
        ) : page === 5 ? (
          <Actions value={value} onChange={onChange} emotionalData={emotionalData} />
        ) : page === 6 ? (
          <Summary options={options} value={value} onChange={onChange} emotionalData={emotionalData} />
        ) : null}

        <div className="buttons">
          <button
            style={{ display: page === 1 ? "none" : "block" }}
            onClick={() => handlePrev()}
            // onClick={()=>setFormPage('do-say-see')}
          >
            Prev
          </button>
          <button
            style={{ display: page === 6 ? "none" : "block" }}
            onClick={() => handleNext()}
            // onClick={()=>setFormPage('do-say-see')}
          >
            Next
          </button>
          <button
            style={{ display: page === 6 ? "block" : "none" }}
            onClick={() => {
              setTimeout(setPage("do-say-see"), 1000);
              scrollToTop();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProcessScreen;
