import MainIcon from '../../images/civilience_triangle.png'

export const Slogan = () => {
    return (
        <nav className="slogan">
            <img src={MainIcon} alt="Civilience logo" className="civilience-logo" />
            <div className="civilience">
                <h2>CIVILIENCE</h2>
                <p>TECHNOLOGY • VITALITY • HUMANITY </p>
            </div>
        </nav>
    )
}