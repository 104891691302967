import React from "react";
import CVLIcon from "../../../../images/cvl-icon.svg";

const Myactionsicons = () => {
  return (
    <div style={{ margin: "23px 25px 70px 25px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          zIndex: "10",
        }}
      >
        <div
          style={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "#E12B5E",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p id="Primary1" style={{ marginTop: "60px", textAlign: "center", fontSize: "10px" }}>
            Primary Feelings
          </p>
        </div>

        <div
          style={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "#E12B5E",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p id="Associated" style={{ marginTop: "60px", marginLeft: "20px", fontSize: "10px" }}>
            Associated Feelings
          </p>
        </div>

        <div
          style={{
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            backgroundImage: `url('https://s2.loli.net/2023/02/14/p3UxLaSb9OBDyPz.png')`,
            backgroundSize: "cover",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            id="Sensations1"
            style={{
              marginTop: "45px",
              marginLeft: "20px",
              fontSize: "10px",
              fontWeight: "700",
              fontFamily: "verdana",
            }}
          >
            Thoughts
          </p>
        </div>

        <div
          style={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "#C2C2C2",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            id="Thoughts"
            style={{
              marginTop: "60px",
              marginLeft: "20px",
              textAlign: "center",
              fontSize: "10px",
              color: "#a2a0a0",
            }}
          >
            Body Sensations
          </p>
        </div>

        <div
          style={{
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            backgroundImage: `url(${CVLIcon})`,
            backgroundSize: "cover",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            id="Actions1"
            style={{
              marginTop: "60px",
              marginLeft: "20px",
              textAlign: "center",
              fontSize: "10px",
              color: "#a2a0a0",
            }}
          >
            Actions
          </p>
        </div>
      </div>
      <div
        className="progresslineLong_body"
        style={{
          width: "170px",
          color: "pink",
          background: "linear-gradient(90deg, #e12b5e 30%, #ea4743 80%)",
        }}
      ></div>
      <div className="progresslineLong_body_grey" style={{ width: "160px", marginLeft: "210px" }}></div>
    </div>
  );
};

export default Myactionsicons;
