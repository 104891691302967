import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DetailSubHeader } from "./DetailSubHeader";
import { DetailSubsection } from "./DetailSubsection";
import { Dropdown } from "./Dropdown";
import { MinuteCard } from "./MinuteCard";

function DoComponent({ disease, headerTitle, setDisease, minuteCardOneHeader,
  minuteCardOneText, minuteCardTwoHeader,
  minuteCardTwoText, setPage, children }) {

  const [openTwoMin, setOpenTwoMin] = useState(false);

  useEffect(() => {
    setOpenTwoMin(false);
  }, [disease]);

  return (
    <DetailSubsection name="DO">
      <DetailSubHeader title="DO">
        <div className="label-and-dropdown">
          <label>SCREEN FOR</label>
          <Dropdown 
            options={['Covid 19', 'Monkeypox']} 
            comingSoonOptions={['Influenza', 'Norovirus', 'HIV', 'Hepatitics C']}
            choice={disease}
            setChoice={setDisease}
            comingSoonPosition="left"
          />
        </div>
      </DetailSubHeader>
      <div className="content">
        <div className="minute-cards">
          <MinuteCard
            onClick={() => setPage('survey')}
            children={children}
            header={minuteCardOneHeader}
            text={minuteCardOneText}
            time="1"
          >
            {/* <Link to={'./survey'} 
            state={headerTitle}
            >Take Survey</Link> */}

          </MinuteCard>
          <MinuteCard
            onClick={() => setOpenTwoMin(!openTwoMin)}
            header={minuteCardTwoHeader}
            text={minuteCardTwoText}
            // text={["Know guidelines and", "your personal risk of", "catching & spreading"]}
            time="2"
          >
            {openTwoMin && (
              <div className="coming-soon-overlay">
                <div className="Mytooltiptext">Coming Soon</div>
              </div>
            )}
          </MinuteCard>
        </div>
      </div>
    </DetailSubsection>
  );
}

export default DoComponent;
