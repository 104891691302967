import "../../../index.css";

export const SaveButton = (props) => {
  // console.log("saveButton - props: ", props);

  const handleSave = () => {
    // Callback to parent to close form popup
    props.handleEdit(props.formType);
  };

  return (
    <div>
      <button className="summary-save-btn" onClick={handleSave}>
        Save
      </button>
    </div>
  );
};

export default SaveButton;
