import { useState } from 'react'

const SwitchSurvey = () => {
    const [twoMin, setTwoMin] = useState(false)
    return(
        <p 
            className='Mytooltip one-two-min fw-bold'
            onClick={ () => setTwoMin(!twoMin)}
        >
            { twoMin ? `⏱️ 2 min` : `⏱️ 1 min` }
            { twoMin && <p className='Mytooltiptext'> Coming Soon</p>}
            <p className="up-down-arrows">
                <div>▲</div>
                <div>▼</div>
            </p>
        </p>
    )
}

export default SwitchSurvey;