import AlivenessJoyIcon from "./../../../images/aliveness-joy-icon.svg";
import GratefulIcon from "./../../../images/grateful-icon.svg";
import CourageousPowerfulIcon from "./../../../images/courageous-powerful-icon.svg";
import ConnectedLovingIcon from "./../../../images/connected-loving-icon.svg";
import AcceptingOpenIcon from "./../../../images/accepting-open-icon.svg";
import HopefulIcon from "./../../../images/hopeful-icon.svg";
import CuriousIcon from "./../../../images/curious-icon.svg";
import TenderIcon from "./../../../images/tender-icon.svg";
import DisconnectedNumbIcon from "./../../../images/disconnected-numb-icon.svg";
import StressedTenseIcon from "./../../../images/stressed-tense-icon.svg";
import UnsettledDoubtIcon from "./../../../images/unsettled-doubt-icon.svg";
import FragileIcon from "./../../../images/fragile-icon.svg";
import DespairSadIcon from "./../../../images/despair-sad-icon.svg";
import EmbarassedShameIcon from "./../../../images/embarassed-shame-icon.svg";
import AngryAnnoyedIcon from "./../../../images/angry-annoyed-icon.svg";
import GuiltIcon from "./../../../images/guilt-icon.svg";
import FearIcon from "./../../../images/fear-icon.svg";
import PowerlessIcon from "./../../../images/powerless-icon.svg";

const GLOSSARY_DATA = [
  {
    title: "PrimaryGlossary",
    items: [
      {
        id: 1,
        description: "Aliveness",
        headerDescription: "Aliveness / Joy",
        definition: "Full of energy or animation; active, lively, brisk.",
        synonym: "Live",
        sentence: "I feel heightened, alive, muscle-bound.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/5093?rskey=ETv1Hh&result=1&p=emailAqZQtwFBbpRYA&d=5093",
        activeColor: "#F0E3F0",
        icon: AlivenessJoyIcon,
        fontColor: "#000",

        altdescription: "Joy",
        altdefinition:
          "A vivid emotion of pleasure arising from a sense of well-being or satisfaction; the feeling or state of being highly pleased or delighted",
        altsynonym: "Glad, delight",
        altsentence: "Seeing her again brought tears of joy to my eyes.",
        altlink: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/101795?rskey=1r5EHT&result=1&p=emailAqp6oh0iVNPO.&d=101795",
      },
      {
        id: 2,
        description: "Grateful",
        headerDescription: "Grateful",
        definition: "Feeling gratitude",
        synonym: "Thankful",
        sentence: "I'm grateful to you for your help.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/80927?redirectedFrom=Grateful&p=emailAqf9tXZ1a9t.Y&d=80927",
        activeColor: "#F6C5F6",
        icon: GratefulIcon,
        fontColor: "#000",
      },
      {
        id: 3,
        description: "Courageous",
        headerDescription: "Courageous / Powerful",
        definition: "Having courage, full of courage",
        synonym: "Brave, fearless, valiant",
        sentence: "She was a courageous woman who wasn't afraid to support unpopular causes.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/43153?redirectedFrom=Courageous&p=emailAqT3IDG.gRclA&d=43153",
        activeColor: "#EABEE6",
        icon: CourageousPowerfulIcon,
        fontColor: "#000",

        altdescription: "Powerful",
        altdefinition: "Holding control or influence over people and events, socially or politically influential",
        altsynonym: "Strong",
        altsentence: "I feel powerful with your support.",
        altlink: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/149175?redirectedFrom=powerful&p=emailAqauYzJaulou2&d=149175",
      },

      {
        id: 4,
        description: "Connected",
        headerDescription: "Connected / Loving",

        definition: "Related by ties of family, intimacy, common aims",
        synonym: "Affiliated",
        sentence: "She is a closely connected friend to him.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/39329?rskey=0J6eR9&result=2&isAdvanced=false&p=emailAqOgnm0gFFGbA&d=39329",
        activeColor: "#B22C96",
        icon: ConnectedLovingIcon,
        fontColor: "#000",

        altdescription: "Loving",
        altdefinition: "Feeling or showing love",
        altsynonym: "Affectionate",
        altsentence: "They were a loving family, supporting each other when times were bad",
        altlink:
          "https://www-oed-com.proxy.library.upenn.edu/view/Entry/110633?rskey=RGOS4J&result=5&isAdvanced=false&p=emailAqzVB/24ZEB26&d=110633",
      },
      {
        id: 5,
        description: "Accepting",
        headerDescription: "Accepting / Open",
        definition: "Able or willing to accept something or someone",
        synonym: "Tolerating without protest",
        sentence: "I had become more accepting of death as an inevitable and natural part of life",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/1022?rskey=0uu7EW&result=3&isAdvanced=false&p=emailAq/iZlNQK5Y.I&d=1022",
        activeColor: "#362584",
        icon: AcceptingOpenIcon,
        fontColor: "#FFF",

        altdescription: "Open",
        altdefinition: "Accessible to",
        altsynonym: "Receptive",
        altsentence: "He didn't abandon",
        altlink:
          "https://www-oed-com.proxy.library.upenn.edu/view/Entry/131699?rskey=boTRnz&result=2&isAdvanced=false&p=emailAqzJk6zkaaGMs&d=131699",
      },

      {
        id: 6,
        description: "Hopeful",
        headerDescription: "Hopeful",

        definition: "Full of hope; feeling or entertaining hope; expectant of that which is desired.",
        synonym: "Anticipant",
        sentence: "We are hopeful of our final victory",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/88375?redirectedFrom=Hopeful&p=emailAqJuvNu24Fdss&d=88375",
        activeColor: "#77B1E4",
        icon: HopefulIcon,
        fontColor: "#000",
      },
      {
        id: 7,
        description: "Curious",
        headerDescription: "Curious",
        definition: "Desirous of seeing or knowing; eager to learn",
        synonym: "Inquisitive",
        sentence: "He was very curious about his surroundings, and much inclined to hear of novelties, and rare things.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/46040?rskey=hzVSIP&result=1&isAdvanced=false&p=emailAqKeCNxqMj7Tc&d=46040",
        activeColor: "#3C90F0",
        icon: CuriousIcon,
        fontColor: "#000",
      },
      {
        id: 8,
        description: "Tender",
        headerDescription: "Tender",
        definition: "Characterized by, exhibiting, or expressing delicacy of feeling or susceptibility to the gentle emotions",
        synonym: "Kind, loving, gentle, mild, affectionate",
        sentence: "I delight in the tender passions.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/199047?rskey=9PEfPL&result=4&p=emailAq6kPe8INPG3s&d=199047",
        activeColor: "#AEEE7E",
        icon: TenderIcon,
        fontColor: "#000",
      },
      {
        id: 9,
        description: "Disconnected",
        headerDescription: "Disconnected / Numb",
        definition: "Having no connection or association",
        synonym: "Unconnected, separate; detached",
        sentence:
          "I think you can be lonely anywhere, if you feel that you are disconnected from the world around you, if you feel that no one understands you.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/53881?rskey=BwUEm9&result=2&p=emailAqhOSi/e0epDw&d=53881",
        activeColor: "#F3ED58",
        icon: DisconnectedNumbIcon,
        fontColor: "#000",

        altdescription: "Numb",
        altdefinition: "Deprived of physical sensation or of the power of movement",
        altsynonym: "Insensible",
        altsentence: "He had become almost numb from the weariness of his position.",
        altlink:
          "https://www-oed-com.proxy.library.upenn.edu/view/Entry/129077?rskey=3uZ0VI&result=2&isAdvanced=false&p=emailAqlgMtvOiO6Z.&d=129077",
      },
      {
        id: 10,
        description: "Stressed",
        headerDescription: "Stressed / Tense",
        definition: "Experiencing or subjected to physiological, mental, or emotional stress",
        synonym: "Distressed, afflicted, suffering",
        sentence: "He was feeling pretty stressed about the deadline.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/191515?rskey=7jMWGz&result=2&isAdvanced=false&p=emailAqQOJj7OGUpK6&d=191515",
        activeColor: "#E0DC48",
        icon: StressedTenseIcon,
        fontColor: "#FFF",

        altdescription: "Tense",
        altdefinition: "In a state of nervous or mental strain or tension",
        altsynonym: "Strained",
        altsentence: "Her sensibilities kept tense through the long winter.",
        altlink: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/199170?rskey=33SDCI&result=2&p=emailAqkJu9.pg5djg&d=199170",
      },

      {
        id: 11,
        description: "Unsettled",
        headerDescription: "Unsettled / Doubt",
        definition: "Not peaceful, tranquil, or orderly",
        synonym: "Disturbed",
        sentence: "Worried about the journey, I was unsettled for the first few days.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/217788?rskey=seJ7Qg&result=2&isAdvanced=false&p=emailAqHUEeOZz2OxM&d=217788",
        activeColor: "#E57F30",
        icon: UnsettledDoubtIcon,
        fontColor: "#FFF",

        altdescription: "Doubt",
        altdefinition: "The condition of being (objectively) uncertain",
        altsynonym: "Unsure, questionable",
        altsentence: "I was doubtful of my judgment.",
        altlink: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/57076?rskey=JbXU8U&result=1&p=emailAqWg3OmkLrc/.&d=57076",
      },

      {
        id: 12,
        description: "Fragile",
        headerDescription: "Fragile",
        definition: "Of weak or tender frame or constitution",
        synonym: "Delicate",
        sentence: "I think I'm fragile. I'm extremely sensitive.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/74112?redirectedFrom=Fragile&p=emailAqAvmEsLoIyd.&d=74112",
        activeColor: "#C15023",
        icon: FragileIcon,
        fontColor: "#FFF",
      },
      {
        id: 13,
        description: "Despair",
        headerDescription: "Despair / Sad",
        definition: "The state of mind in which there is entire want of hope",
        synonym: "Hopelessness",
        sentence: "His impotence to help made him despair.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/50935?rskey=5vs85R&result=1&isAdvanced=false&p=emailAqU/SGrRwAS5.&d=50935",
        activeColor: "#C25022",
        icon: DespairSadIcon,
        fontColor: "#FFF",

        altdescription: "Sad",
        altdefinition: "Feeling sorrow",
        altsynonym: "Sorrowful, mournful, heavy-hearted.",
        altsentence: "Separation from her friends made her very sad.",
        altlink:
          "https://www-oed-com.proxy.library.upenn.edu/view/Entry/169609?rskey=e4KrRr&result=2&isAdvanced=false&p=emailAqX0ICaPdx.N.&d=169609",
      },

      {
        id: 14,
        description: "Embarrassed",
        headerDescription: "Embarrassed / Shame",
        definition: "Feeling, expressing, or characterized by embarrassment",
        synonym: "Awkward, self-conscious",
        sentence: "He felt acutely embarrassed at being the center of attention.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/60794?rskey=DukoGc&result=2&isAdvanced=false&p=emailAq35aJVnsyR3I&d=60794",
        activeColor: "#D94941",
        icon: EmbarassedShameIcon,
        fontColor: "#FFF",

        altdescription: "Shame",
        altdefinition:
          "The painful emotion arising from the consciousness of something dishonoring, ridiculous, or indecorous in one's own conduct or circumstances",
        altsynonym: "Guilty",
        altsentence: "He was shamed by how much more work the others had done.",
        altlink:
          "https://www-oed-com.proxy.library.upenn.edu/view/Entry/177406?rskey=Yh8r7L&result=1&isAdvanced=false&p=emailAqjpk5yZQdbok&d=177406",
      },

      {
        id: 15,
        description: "Angry",
        headerDescription: "Angry / Annoyed",
        definition: "Feeling or showing anger",
        synonym: "Furious",
        sentence: "I was angry that he had forgotten my birthday.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/7623?rskey=E9ZPWm&result=2&isAdvanced=false&p=emailAqbhqty/Ud8WM&d=7623",
        activeColor: "#D33022",
        icon: AngryAnnoyedIcon,
        fontColor: "#FFF",

        altdescription: "Annoyed",
        altdefinition: "That feels, expresses, or is characterized by irritation or annoyance",
        altsynonym: "Displeased, troubled",
        altsentence: "I was annoyed by his bad manners.",
        altlink:
          "https://www-oed-com.proxy.library.upenn.edu/view/Entry/7941?rskey=JtWdVb&result=2&isAdvanced=false&p=emailAqTRkNZAj/42s&d=7941",
      },

      {
        id: 16,
        description: "Guilt",
        headerDescription: "Guilt",
        definition: "An unpleasant feeling of having committed wrong or failed in an obligation",
        synonym: "Remorse",
        sentence: "Talking to her helped to assuage my guilt.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/82364?rskey=lU14PJ&result=1&isAdvanced=false&p=emailAqGxQxM7d6sk2&d=82364",
        activeColor: "#897476",
        icon: GuiltIcon,
        fontColor: "#FFF",
      },
      {
        id: 17,
        description: "Fear",
        headerDescription: "Fear",
        definition: "A state of alarm or dread",
        synonym: "Anxiety",
        sentence: "He tried to vanquish his fears through talking to therapists.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/68773?rskey=ZsSo2h&result=1&isAdvanced=false&p=emailAqpcPVYJWjPSw&d=68773",
        activeColor: "#44403D",
        icon: FearIcon,
        fontColor: "#FFF",
      },
      {
        id: 18,
        description: "Powerless",
        headerDescription: "Powerless",
        definition: "Without power, strength, or ability",
        synonym: "Impotent",
        sentence: "We are powerless in the face of such forces.",
        link: "https://www-oed-com.proxy.library.upenn.edu/view/Entry/149179?redirectedFrom=Powerless&p=emailAq.JKwcmyVgF2&d=149179",
        activeColor: "#000002",
        icon: PowerlessIcon,
        fontColor: "#FFF",
      },
    ],
  },
  {
    title: "AssociatedGlossary",
    items: [
      {
        title: "Accepting/Open",
        icon: { primaryMood: AcceptingOpenIcon, backgroundColor: "#362584" },
        list: ["Calm", "Centered", "Content", "Fulfilled", "Patient", "Peaceful", "Present", "Relaxed", "Serene", "Trusting"],
        text: {
          Calm: {
            definition: "free from agitation, excitement, or disturbance",
            sentence: "She took a deep breath and tried to remain calm during the stressful situation.",
            synonym2: "halcyon, hushed",
            synonym5: "halcyon, hushed, lown, peaceful, placid",
          },
          Centered: {
            definition: "emotionally stable and secure",
            sentence: "He felt centered and focused on his goals.",
            synonym2: "disciplined, equable",
            synonym5: "disciplined, equable, self-contained, self-controlled, imperturbable",
          },
          Content: {
            definition: "satisfied",
            sentence: " She felt content and happy with her life.",
            synonym2: "matter, motif",
            synonym5: "matter, motif, motive, question, subject",
          },
          Fulfilled: {
            definition: "feeling happiness and satisfaction",
            sentence: " He felt fulfilled and satisfied with his accomplishments.",
            synonym2: "answered, completed",
            synonym5: "answered, completed, complied (with), filled, kept",
          },
          Patient: {
            definition: "bearing pains or trials calmly or without complaint",
            sentence: "She waited patiently for her turn.",
            synonym2: "case",
            synonym5: "case",
          },
          Peaceful: {
            definition: "peaceable, untroubled by conflict, agitation, or commotion",
            sentence: "The sound of the waves crashing against the shore was so peaceful.",
            synonym2: "dovish, pacific",
            synonym5: "dovish, pacific, pacifist, peaceable",
          },
          Present: {
            definition: "fully focused on or involved in what one is doing or experiencing",
            sentence: "You have to put everything out of your mind and be really present",
            synonym2: "carry, give",
            synonym5: "carry, give, mount, offer, stage",
          },
          Relaxed: {
            definition: "freed from or lacking in precision or stringency, set or being at rest or at ease, easy of manner",
            sentence: "He felt relaxed and comfortable in his favorite chair.",
            synonym2: "canny, comfortable",
            synonym5: "canny, comfortable, comfy, cozy, snug",
          },
          Serene: {
            definition: "marked by or suggestive of utter calm and unruffled repose or quietude",
            sentence: "The view of the sunset over the mountains was serene.",
            synonym2: "arcadian, calm",
            synonym5: "arcadian, calm, hushed, peaceful, placid",
          },
          Trusting: {
            definition: "believe, to place confidence in",
            sentence: " She trusted that everything would work out in the end.",
            synonym2: "confiding, trustful",
            synonym5: "confiding, trustful",
          },
        },
      },
      {
        title: "Aliveness/Joy",
        icon: { primaryMood: AlivenessJoyIcon, backgroundColor: "#F0E3F0" },
        list: [
          "Amazed",
          "Awe",
          "Bliss",
          "Delighted",
          "Eager",
          "Ecstatic",
          "Enchanted",
          "Energized",
          "Engaged",
          "Enthusiastic",
          "Excited",
          "Free",
          "Happy",
          "Inspired",
          "Invigorated",
          "Lively",
          "Passionate",
          "Playful",
          "Radiant",
          "Refreshed",
          "Rejuvenated",
          "Renewed",
          "Satisfied",
          "Thrilled",
          "Vibrant",
        ],
        text: {
          Amazed: {
            definition: "feeling or showing great surprise or wonder",
            sentence: "He was amazed by the beauty of the natural landscape.",
            synonym2: "astonished, astounded",
            synonym5: "astonished, astounded, awestruck, bowled over, dumbfounded",
          },
          Awe: {
            definition:
              "an emotion variously combining dread, veneration, and wonder that is inspired by authority or by the sacred or sublime",
            sentence: "She was in awe of the magnificent architecture of the building.",
            synonym2: "admiration, amazement",
            synonym5: "admiration, amazement, astonishment, wonder, wonderment",
          },
          Bliss: {
            definition: "complete happiness, paradise, heaven",
            sentence: "She experienced pure bliss while lying on the beach and listening to music.",
            synonym2: "above, elysian fields",
            synonym5: "above, elysian fields, Elysium, empyrean, heaven",
          },
          Delighted: {
            definition: "delightful, highly pleased",
            sentence: "He was delighted by the surprise gift from his friend.",
            synonym2: "blissful, chuffed",
            synonym5: "blissful, chuffed, glad, gratified, happy",
          },
          Eager: {
            definition: "marked by enthusiastic or impatient desire or interest, sharp, sour",
            sentence: "She was eager to start her new job.",
            synonym2: "agog, antsy",
            synonym5: "agog, antsy, anxious, ardent, athirst",
          },
          Ecstatic: {
            definition: "of, relating to, or marked by ecstasy",
            sentence: "He was ecstatic when he found out he got accepted into his dream school.",
            synonym2: "elated, elevated",
            synonym5: "elated, elevated, enrapt, enraptured, entranced",
          },
          Enchanted: {
            definition: "made to feel delightfully pleased or charmed",
            sentence: "She felt enchanted by the magical atmosphere of the fairytale village.",
            synonym2: "bewitched, charmed",
            synonym5: "bewitched, charmed, entranced, magic, magical",
          },
          Energized: {
            definition: "to make energetic, vigorous, or active, to impart energy",
            sentence: "He felt energized and ready to tackle the day after a good night's sleep.",
            synonym2: "freshened, invigorated",
            synonym5: "freshened, invigorated, new, newborn, reanimated",
          },
          Engaged: {
            definition: "betrothed, greatly interested",
            sentence: "She was fully engaged and focused during the meeting.",
            synonym2: "affianced, bespoke",
            synonym5: "affianced, bespoke, betrothed, promised",
          },
          Enthusiastic: {
            definition: "filled with or marked by enthusiasm",
            sentence: " He was enthusiastic about his new hobby.",
            synonym2: "agog, antsy",
            synonym5: "agog, antsy, anxious, ardent, athirst",
          },
          Excited: {
            definition: "having, showing, or characterized by a heightened state of energy, enthusiasm, eagerness, etc.",
            sentence: "She was excited to go on vacation with her family.",
            synonym2: "agitated, feverish",
            synonym5: "agitated, feverish, frenzied, heated, hectic",
          },
          Free: {
            definition: "not obstructed, restricted, or impeded",
            sentence: "He felt free and liberated after quitting his job.",
            synonym2: "autonomous, freestanding",
            synonym5: "autonomous, freestanding, independent, self-governed, self-governing",
          },
          Happy: {
            definition: "favored by luck or fortune",
            sentence: "She was happy to spend time with her friends.",
            synonym2: "fluky, fortuitous",
            synonym5: "fluky, fortuitous, fortunate, heaven-sent, lucky",
          },
          Inspired: {
            definition: "outstanding or brilliant in a way or to a degree suggestive of divine inspiration",
            sentence: "He was inspired by the work of his favorite artist.",
            synonym2: "gifted, talented",
            synonym5: "gifted, talented, clever, creative, imaginative",
          },
          Invigorated: {
            definition: "to give life and energy to",
            sentence: "She felt invigorated after taking a cold shower.",
            synonym2: "energized, freshened",
            synonym5: "energized, freshened, new, newborn, reanimated",
          },
          Lively: {
            definition: "briskly alert and energetic",
            sentence: "The party was lively and full of energy.",
            synonym2: "airily, animatedly",
            synonym5: "airily, animatedly, animately, bouncily, buoyantly",
          },
          Passionate: {
            definition: "Enthusiastic, ardent",
            sentence: "She was passionate about her work and put in extra effort.",
            synonym2: "concupiscent, goatish",
            synonym5: "concupiscent, goatish, horny, hot, hypersexual",
          },
          Playful: {
            definition: "full of play",
            sentence: "He was in a playful mood and joked around with his friends.",
            synonym2: "antic, coltish",
            synonym5: "antic, coltish, elfish, fay, frisky",
          },
          Radiant: {
            definition: "glowing, marked by or expressive of love, confidence, or happiness",
            sentence: "She looked radiant and glowing on her wedding day.",
            synonym2: "aglow, beaming",
            synonym5: "aglow, beaming, bright, glowing, sunny",
          },
          Refreshed: {
            definition: "to restore strength and animation to",
            sentence: "He felt refreshed and rejuvenated after a weekend getaway.",
            synonym2: "energized, freshened",
            synonym5: "energized, freshened, invigorated, new, newborn",
          },
          Rejuvenated: {
            definition: "to make young or youthful again",
            sentence: "She felt rejuvenated after a relaxing spa day.",
            synonym2: "refreshed, revitalized",
            synonym5: "refreshed, revitalized, firm, fortified, mettlesome",
          },
          Renewed: {
            definition: "restore to freshness, vigor, or perfection, to make new spiritually",
            sentence: "He felt renewed and motivated after a difficult period.",
            synonym2: "energized, freshened",
            synonym5: "energized, freshened, invigorated, new, newborn",
          },
          Satisfied: {
            definition: "pleased or content with what has been experienced or received",
            sentence: "She felt satisfied with the delicious meal she cooked.",
            synonym2: "blissful, chuffed",
            synonym5: "blissful, chuffed, delighted, glad, gratified",
          },
          Thrilled: {
            definition: "extremely pleased and excited",
            sentence: "He was thrilled to finally meet his favorite celebrity.",
            synonym2: "blessed, blissful",
            synonym5: "blessed, blissful, delighted, ecstatic, elated",
          },
          Vibrant: {
            definition: "pulsating with life, vigor, or activity",
            sentence: "The colors of the flowers were vibrant and bright.",
            synonym2: "aboil, abubble",
            synonym5: "aboil, abubble, abuzz, alive, animated",
          },
        },
      },
      {
        title: "Angry/Annoyed",
        icon: { primaryMood: AngryAnnoyedIcon, backgroundColor: "#D33022" },
        list: [
          "Agitated",
          "Aggravated",
          "Bitter",
          "Contempt",
          "Cynical",
          "Disdain",
          "Disgruntled",
          "Disturbed",
          "Edgy",
          "Exasperated",
          "Frustrated",
          "Furious",
          "Grouchy",
          "Hostile",
          "Impatient",
          "Irritated",
          "Irate",
          "Moody",
          "On edge",
          "Outraged",
          "Pissed",
          "Resentful",
          "Upset",
          "Vindictive",
        ],
        text: {
          Agitated: {
            definition: "troubled in mind, disturbed and upset",
            sentence: "She felt agitated and restless after drinking too much coffee.",
            synonym2: "excited, feverish",
            synonym5: "excited, feverish, frenzied, heated, hectic",
          },
          Aggravated: {
            definition: "angry or displeased especially because of small problems or annoyances",
            sentence: "He was aggravated by the slow internet connection.",
            synonym2: "annoyed, bothered",
            synonym5: "annoyed, bothered, exasperated, galled, irked",
          },
          Bitter: {
            definition: "distasteful or distressing to the mind",
            sentence: "She felt bitter about the breakup with her ex-partner.",
            synonym2: "acrid, acrimonious",
            synonym5: "acrid, acrimonious, embittered, hard, rancorous",
          },
          Contempt: {
            definition: "the state of mind of one who despises",
            sentence: "He looked at his opponent with contempt and disdain.",
            synonym2: "contemptuousness, despisement",
            synonym5: "contemptuousness, despisement, despite, despitefulness, disdain",
          },
          Cynical: {
            definition: "having or showing the attitude or temper of a cynic",
            sentence: "She had a cynical view of the world and its problems.",
            synonym2: "misanthropic, pessimistic",
            synonym5: "misanthropic, pessimistic",
          },
          Disdain: {
            definition: "a feeling of contempt for someone or something regarded as unworthy or inferior",
            sentence: "He felt disdain for the rude customer.",
            synonym2: "contempt, contemptuousness",
            synonym5: "contempt, contemptuousness, despisement, despite, despitefulness",
          },
          Disgruntled: {
            definition: "unhappy and annoyed",
            sentence: "She was disgruntled with her job and wanted to quit.",
            synonym2: "aggrieved, discontent",
            synonym5: "aggrieved, discontent, discontented, displeased, dissatisfied",
          },
          Disturbed: {
            definition: "experiencing or exhibiting emotional disturbance or agitation",
            sentence: "He was disturbed by the violent movie he had just watched.",
            synonym2: "agitated, distressed",
            synonym5: "agitated, distressed, perturbed, troubled, unsettled",
          },
          Edgy: {
            definition: "tense, irritable, characterized by tension",
            sentence: "She felt edgy and irritable after not getting enough sleep.",
            synonym2: "aflutter, antsy",
            synonym5: "aflutter, antsy, anxious, atwitter, dithery",
          },
          Exasperated: {
            definition: "having or showing strong feelings of irritation or annoyance",
            sentence: "He was exasperated by the never-ending tasks on his to-do list.",
            synonym2: "aggravated, annoyed",
            synonym5: "aggravated, annoyed, bothered, galled, irked",
          },
          Frustrated: {
            definition: "feeling, showing, or characterized by frustration",
            sentence: "She felt frustrated by the lack of progress in her project.",
            synonym2: "disappointed, disenchanted",
            synonym5: "disappointed, disenchanted, disillusioned, unfulfilled, aggrieved",
          },
          Furious: {
            definition: "exhibiting or goaded by anger",
            sentence: "He was furious when he found out his car had been stolen.",
            synonym2: "delirious, ferocious",
            synonym5: "delirious, ferocious, feverish, fierce, frantic",
          },
          Grouchy: {
            definition: "given to grumbling",
            sentence: "She woke up on the wrong side of the bed and felt very grouchy all day.",
            synonym2: "choleric, crabby",
            synonym5: "choleric, crabby, cranky, cross, crotchety",
          },
          Hostile: {
            definition: "having or showing unfriendly feelings, openly opposed or resisting",
            sentence: "His hostile behavior towards others caused him to have few friends.",
            synonym2: "adversarial, adversary",
            synonym5: "adversarial, adversary, antagonistic, antipathetic, inhospitable",
          },
          Impatient: {
            definition: "not patient, restless or short of temper especially under irritation, delay, or opposition",
            sentence: "She tapped her foot impatiently while waiting for her order at the restaurant.",
            synonym2: "agog, antsy",
            synonym5: "agog, antsy, anxious, ardent, athirst",
          },
          Irritated: {
            definition: "subjected to irritation; especially roughened, reddened, or inflamed by an irritant",
            sentence: "The constant noise from the construction site was making her very irritated.",
            synonym2: "aggravated, annoyed",
            synonym5: "aggravated, annoyed, bothered, exasperated, galled",
          },
          Irate: {
            definition: "roused to ire, arising from anger",
            sentence: "The customer became irate when his order was delayed.",
            synonym2: "angered, angry",
            synonym5: "angered, angry, apoplectic, ballistic, cheesed off",
          },
          Moody: {
            definition: "subject to depression",
            sentence: "She was very moody and difficult to predict her emotions.",
            synonym2: "temperamental",
            synonym5: "temperamental",
          },
          "On edge": {
            definition: "feeling or showing uncomfortable feelings of uncertainty",
            sentence: "He was on edge waiting for the results of his job interview.",
            synonym2: "anxious, tense",
            synonym5: "anxious, tense, nervous, irritable, wire",
          },
          Outraged: {
            definition: "to arouse anger or resentment in usually by some grave offense",
            sentence: "The community was outraged when they learned about the company's pollution of the local river.",
            synonym2: "angered, angry",
            synonym5: "angered, angry, apoplectic, ballistic, cheesed off",
          },
          Pissed: {
            definition: "irked, irate",
            sentence: "She was pissed when her phone got stolen.",
            synonym2: "incensed, indignant",
            synonym5: "bothered, incensed, indignant, offended, provoked",
          },
          Resentful: {
            definition: "inclined to resent, caused or marked by resentment",
            sentence: "He was resentful towards his brother for always getting more attention.",
            synonym2: "acrid, acrimonious",
            synonym5: "acrid, acrimonious, bitter, embittered, hard",
          },
          Upset: {
            definition: "emotionally disturbed or agitated",
            sentence: "She was upset after hearing the news of her friend's illness.",
            synonym2: "aflutter, antsy",
            synonym5: "aflutter, antsy, anxious, atwitter, dithery",
          },
          Vindictive: {
            definition: "disposed to seek revenge",
            sentence: "She was vindictive and sought revenge against those who wronged her.",
            synonym2: "revengeful, vengeful",
            synonym5: "revengeful, vengeful",
          },
        },
      },
      {
        title: "Courageous/Powerful",
        icon: { primaryMood: CourageousPowerfulIcon, backgroundColor: "#D57DCE" },
        list: [
          "Adventurous",
          "Brave",
          "Capable",
          "Confident",
          "Daring",
          "Determined",
          "Free",
          "Grounded",
          "Proud",
          "Strong",
          "Worthy",
          "Valiant",
        ],
        text: {
          Adventurous: {
            definition: "disposed to seek adventure or to cope with the new and unknown",
            sentence: "He was feeling adventurous and decided to try skydiving.",
            synonym2: "adventuresome, audacious",
            synonym5: "adventuresome, audacious, bold, daring, dashing",
          },
          Brave: {
            definition: "having or showing mental or moral strength to face danger, fear, or difficulty",
            sentence: "She was brave to confront the bully who was picking on her little brother.",
            synonym2: "bold, courageous",
            synonym5: "bold, courageous, dauntless, doughty, fearless",
          },
          Capable: {
            definition:
              "susceptible, comprehensive, having attributes (such as physical or mental power) required for performance or accomplishment",
            sentence: "He felt capable of achieving anything he set his mind to.",
            synonym2: "able, competent",
            synonym5: "able, competent, equal, fit, good",
          },
          Confident: {
            definition: "certain, having or showing assurance and self-reliance, trustful, confiding",
            sentence: "She walked into the interview room with a confident smile.",
            synonym2: "assured, secure",
            synonym5: "assured, secure, self-asserting, self-assured, self-confident",
          },
          Daring: {
            definition: "venturesomely bold in action or thought",
            sentence: "The daredevil stunt required a lot of daring and skill.",
            synonym2: "adventuresome, adventurous",
            synonym5: "adventuresome, adventurous, audacious, bold, dashing",
          },
          Determined: {
            definition: "having reached a decision",
            sentence: "He was determined to finish the marathon, no matter what.",
            synonym2: "bent (on or upon), bound",
            synonym5: "bent (on or upon), bound, decisive, do-or-die, firm",
          },
          Free: {
            definition: "not under the control or in the power of another; able to act or be done as one wishes.",
            sentence: "After finishing her exams, she felt free to do whatever she wanted.",
            synonym2: "autonomous, freestanding",
            synonym5: "autonomous, freestanding, independent, self-governed, self-governing",
          },
          Grounded: {
            definition: "mentally and emotionally stable, admirably sensible, realistic, and unpretentious",
            sentence: "She felt grounded and connected to the earth after her yoga session.",
            synonym2: "aground, beached",
            synonym5: "aground, beached, stranded",
          },
          Proud: {
            definition: "feeling or showing pride",
            sentence: "She was proud of her son for graduating with honors.",
            synonym2: "disdainful, haughty",
            synonym5: "disdainful, haughty, highfalutin, lofty, lordly",
          },
          Strong: {
            definition: "having or marked by great physical power, having moral or intellectual power",
            sentence: "The athlete felt strong and capable after months of intense training.",
            synonym2: "brawny, muscular",
            synonym5: "brawny, muscular, rugged, sinewy, stalwart",
          },
          Worthy: {
            definition: "having worth or value, honorable, meritorious, having sufficient worth or importance",
            sentence: "She felt worthy of love and happiness, despite her flaws.",
            synonym2: "deserving, good",
            synonym5: "deserving, good, meritorious",
          },
          Valiant: {
            definition: "possessing or acting with bravery or boldness",
            sentence: "The soldiers were valiant in defending their country against the invaders.",
            synonym2: "bold, brave",
            synonym5: "bold, brave, courageous, dauntless, doughty",
          },
        },
      },
      {
        title: "Connected/Loving",
        icon: { primaryMood: ConnectedLovingIcon, backgroundColor: "#B22C96" },
        list: ["Accepting", "Affectionate", "Caring", "Compassion", "Empathy", "Fulfilled", "Present", "Safe", "Warm", "Worthy"],
        text: {
          Accepting: {
            definition: "able or willing to accept something or someone",
            sentence: "She was accepting of others, regardless of their background or beliefs.",
            synonym2: "believing, certain",
            synonym5: "believing, certain, confident, convinced, overconfident",
          },
          Affectionate: {
            definition: "feeling or showing affection or warm regard",
            sentence: "The couple showed affectionate gestures towards each other.",
            synonym2: "adoring, devoted",
            synonym5: "adoring, devoted, fond, loving, tender",
          },
          Caring: {
            definition: "feeling or showing concern for or kindness to others",
            sentence: "The nurse showed caring and compassion towards her patients.",
            synonym2: "attentive, beneficent",
            synonym5: "attentive, beneficent, benevolent, benignant, compassionate",
          },
          Compassion: {
            definition: "sympathetic consciousness of others' distress together with a desire to alleviate it",
            sentence: "He had compassion for the homeless man on the street.",
            synonym2: "commiseration, feeling",
            synonym5: "commiseration, feeling, sympathy",
          },
          Empathy: {
            definition:
              "the action of understanding, being aware of, being sensitive to, and vicariously experiencing the feelings, thoughts, and experience of another of either the past or present without having the feelings, thoughts, and experience fully communicated in an objectively explicit manner",
            sentence: "She showed empathy towards her friend who was going through a tough time.",
            synonym2: "pity, sympathy",
            synonym5: "pity, sympathy, understanding, charity, clemency",
          },
          Fulfilled: {
            definition: "feeling happiness and satisfaction",
            sentence: "He felt fulfilled after completing a challenging project.",
            synonym2: "answered, completed",
            synonym5: "answered, completed, complied (with), filled, kept",
          },
          Present: {
            definition: "fully focused on or involved in what one is doing or experiencing.",
            sentence: "you have to put everything out of your mind and be really present",
            synonym2: "carry, give",
            synonym5: "carry, give, mount, offer, stage",
          },
          Safe: {
            definition: "free from harm or risk",
            sentence: "He felt safe and secure in his own home.",
            synonym2: "all right, alright",
            synonym5: "all right, alright, secure",
          },
          Warm: {
            definition: "marked by or readily showing affection, gratitude, cordiality, or sympathy",
            sentence: "The fireplace provided a warm and cozy atmosphere.",
            synonym2: "heated, hottish",
            synonym5: "heated, hottish, lukewarm, tepid, toasty",
          },
          Worthy: {
            definition: "having worth or value",
            sentence: "She believed that everyone is worthy of love and respect.",
            synonym2: "deserving, good",
            synonym5: "deserving, good, meritorious",
          },
        },
      },
      {
        title: "Curious",
        icon: { primaryMood: CuriousIcon, backgroundColor: "#3C90F0" },
        list: ["Engaged", "Exploring", "Fascinated", "Interested", "Intrigued", "Involved", "Stimulated"],
        text: {
          Engaged: {
            definition: "involved in activity",
            sentence: "The students were fully engaged in the classroom discussion.",
            synonym2: "affianced, bespoke",
            synonym5: "affianced, bespoke, betrothed, promised",
          },
          Exploring: {
            definition: "to investigate, study, or analyze",
            sentence: "He enjoyed exploring new places and trying new things.",
            synonym2: "delving (into), digging (into)",
            synonym5: "delving (into), digging (into), examining, inquiring (into), investigating",
          },
          Fascinated: {
            definition: "to command the interest of",
            sentence: "She was fascinated by the history of ancient civilizations.",
            synonym2: "bewitched, captivated",
            synonym5: "bewitched, captivated, charmed, enchanted, entranced",
          },
          Interested: {
            definition: "having the attention engaged, being affected or involved",
            sentence: "He was interested in learning about different cultures.",
            synonym2: "engaged, intrigued",
            synonym5: "engaged, intrigued, involved, absorbed, attentive",
          },
          Intrigued: {
            definition: "having one's interest, desire, or curiosity strongly aroused",
            sentence: "She was intrigued by the mystery novel and couldn't put it down.",
            synonym2: "engaged, interested",
            synonym5: "engaged, interested, involved, absorbed, attentive",
          },
          Involved: {
            definition: "actively participating in something",
            sentence: "The volunteers were deeply involved in the community service project.",
            synonym2: "baroque, byzantine",
            synonym5: "baroque, byzantine, complex, complicate, complicated",
          },
          Stimulated: {
            definition: "to be encouraged to greater activity",
            sentence: "The challenging puzzle stimulated her mind.",
            synonym2: "animated, energized",
            synonym5: "animated, energized, enlivened, excited, galvanized",
          },
        },
      },
      {
        title: "Despair/Sad",
        icon: { primaryMood: DespairSadIcon, backgroundColor: "#C25022" },
        list: [
          "Anguish",
          "Depressed",
          "Despondent",
          "Disappointed",
          "Discouraged",
          "Forlorn",
          "Gloomy",
          "Grief",
          "Heartbroken",
          "Hopeless",
          "Lonely",
          "Longing",
          "Melancholy",
          "Sorrow",
          "Teary",
          "Unhappy",
          "Upset",
          "Weary",
          "Yearning",
        ],
        text: {
          Anguish: {
            definition: "extreme pain, distress, or anxiety",
            sentence: "She felt anguish after going through a painful breakup.",
            synonym2: "affliction, agony",
            synonym5: "affliction, agony, distress, excruciation, hurt",
          },
          Depressed: {
            definition: "low in spirits",
            sentence: "He was clinically depressed and sought help from a therapist.",
            synonym2: "concave, dented",
            synonym5: "concave, dented, dished, hollow, indented",
          },
          Despondent: {
            definition: "feeling or showing extreme discouragement, dejection, or depression",
            sentence: "She was despondent after failing her driving test.",
            synonym2: "despairing, desperate",
            synonym5: "despairing, desperate, forlorn, hopeless",
          },
          Disappointed: {
            definition: "defeated in expectation or hope",
            sentence: "She was disappointed when her favorite team lost the game.",
            synonym2: "disenchanted, disillusioned",
            synonym5: "disenchanted, disillusioned, frustrated, unfulfilled, aggrieved",
          },
          Discouraged: {
            definition: "to deprive of courage or confidence",
            sentence: "The constant setbacks had discouraged him from pursuing his dream.",
            synonym2: "banned, barred",
            synonym5: "banned, barred, contraband, criminalized, disallowed",
          },
          Forlorn: {
            definition: "sad and lonely because of isolation or desertion",
            sentence: "The forlorn look on her face suggested that she had lost all hope.",
            synonym2: "bad, blue",
            synonym5: "bad, blue, brokenhearted, cast down, crestfallen",
          },
          Gloomy: {
            definition: "low in spirits",
            sentence: "The gloomy weather matched his mood after a long and stressful week.",
            synonym2: "black, bleak",
            synonym5: "black, bleak, cheerless, chill, Cimmerian",
          },
          Grief: {
            definition: "deep and poignant distress caused by or as if by bereavement, a cause of such suffering, trouble, annoyance",
            sentence: "He was consumed with grief after the loss of his beloved pet.",
            synonym2: "affliction, anguish",
            synonym5: "affliction, anguish, dolefulness, dolor, heartache",
          },
          Heartbroken: {
            definition: "(of a person) suffering from overwhelming distress",
            sentence: "She was heartbroken when her partner ended their relationship.",
            synonym2: "bad, blue",
            synonym5: "bad, blue, brokenhearted, cast down, crestfallen",
          },
          Hopeless: {
            definition: "having no expectation of good or success",
            sentence: "She felt hopeless about finding a way out of her financial struggles.",
            synonym2: "incorrigible, incurable",
            synonym5: "incorrigible, incurable, irrecoverable, irredeemable, irreformable",
          },
          Lonely: {
            definition: "being without company",
            sentence: "He felt lonely despite being surrounded by people because he couldn't connect with any of them.",
            synonym2: "alone, lone",
            synonym5: "alone, lone, lonesome, single, solitary",
          },
          Longing: {
            definition: "a strong desire especially for something unattainable",
            sentence: "The longing in her eyes showed that she missed her family back home.",
            synonym2: "appetency, appetite",
            synonym5: "appetency, appetite, craving, desire, drive",
          },
          Melancholy: {
            definition: "depression of spirits",
            sentence: "He felt melancholy listening to the sad song playing on the radio.",
            synonym2: "depressing, dismal",
            synonym5: "depressing, dismal, drear, dreary, heartbreaking",
          },
          Sorrow: {
            definition: "deep distress, sadness, or regret especially for the loss of someone or something loved",
            sentence: "She was overcome with sorrow after the passing of her grandfather.",
            synonym2: "agonize, anguish",
            synonym5: "agonize, anguish, bleed, grieve, hurt",
          },
          Teary: {
            definition: "wet or stained with tears",
            sentence: "She couldn't help but feel teary-eyed while watching the emotional movie.",
            synonym2: "depressing, dismal",
            synonym5: "depressing, dismal, drear, dreary, heartbreaking",
          },
          Unhappy: {
            definition: "not fortunate, not satisfied or pleased with (a situation)",
            sentence: "He had been feeling unhappy lately, but talking to his friends always helped him feel a little better.",
            synonym2: "bad, blue",
            synonym5: "bad, blue, brokenhearted, cast down, crestfallen",
          },
          Upset: {
            definition: "emotionally disturbed or agitated",
            sentence: "He was upset after finding out that his friend had betrayed him.",
            synonym2: "aflutter, antsy",
            synonym5: "aflutter, antsy, anxious, atwitter, dithery",
          },
          Weary: {
            definition:
              "exhausted in strength, endurance, vigor, or freshness; having one's patience, tolerance, or pleasure exhausted",
            sentence: "She felt weary after working long hours without a break.",
            synonym2: "all in, aweary",
            synonym5: "all in, aweary, beat, beaten, bleary",
          },
          Yearning: {
            definition: "a tender or urgent longing",
            sentence: "She yearned for the warmth of her bed on a cold winter morning.",
            synonym2: "appetency, appetite",
            synonym5: "appetency, appetite, craving, desire, drive",
          },
        },
      },
      {
        title: "Disconnected/Numb",
        icon: { primaryMood: DisconnectedNumbIcon, backgroundColor: "#F3ED58" },
        list: [
          "Aloof",
          "Bored",
          "Confused",
          "Distant",
          "Empty",
          "Indifferent",
          "Isolated",
          "Lethargic",
          "Listless",
          "Removed",
          "Resistant",
          "Shut Down",
          "Uneasy",
          "Withdrawn",
        ],
        text: {
          Aloof: {
            definition: "removed or distant either physically or emotionally",
            sentence: "She acted aloof to hide the pain she was feeling inside.",
            synonym2: "antisocial, asocial",
            synonym5: "antisocial, asocial, buttoned-up, cold, cold-eyed",
          },
          Bored: {
            definition: "filled with or characterized by boredom",
            sentence: "He was bored out of his mind during the lengthy presentation.",
            synonym2: "fed up, jaded",
            synonym5: "fed up, jaded, sick, sick and tired, tired",
          },
          Confused: {
            definition: "being perplexed or disconcerted",
            sentence: "She was confused about which path to take in life.",
            synonym2: "chaotic, cluttered",
            synonym5: "chaotic, cluttered, disarranged, disarrayed, disheveled",
          },
          Distant: {
            definition: "separated in space",
            sentence: "He felt distant from his partner after a heated argument.",
            synonym2: "away, deep",
            synonym5: "away, deep, far, faraway, far-flung",
          },
          Empty: {
            definition: "containing nothing",
            sentence: "She felt empty inside after the end of a long-term relationship.",
            synonym2: "bare, blank",
            synonym5: "bare, blank, clean, devoid, stark",
          },
          Indifferent: {
            definition: "marked by a lack of interest, enthusiasm, or concern for something",
            sentence: "She was indifferent to the outcome of the game because she didn't care for either team.",
            synonym2: "apathetic, casual",
            synonym5: "apathetic, casual, complacent, disinterested, incurious",
          },
          Isolated: {
            definition: "occurring alone or once",
            sentence: "He felt isolated and alone in a foreign country where he didn't know anyone.",
            synonym2: "cloistered, covert",
            synonym5: "cloistered, covert, hidden, quiet, remote",
          },
          Lethargic: {
            definition: "of, relating to, or characterized by laziness or lack of energy",
            sentence: "She felt lethargic and had no energy to do anything.",
            synonym2: "dull, inactive",
            synonym5: "dull, inactive, inert, quiescent, sleepy",
          },
          Listless: {
            definition: "characterized by lack of interest, energy, or spirit",
            sentence: "He was listless and lacked motivation to complete his tasks.",
            synonym2: "enervated, lackadaisical",
            synonym5: "enervated, lackadaisical, languid, languishing, languorous",
          },
          Removed: {
            definition: "distant in degree of relationship",
            sentence: "She removed herself from the situation to avoid any more conflict.",
            synonym2: "away, deep",
            synonym5: "away, deep, distant, far, far-flung",
          },
          Resistant: {
            definition: "giving, capable of, or exhibiting resistance ",
            sentence: "He was resistant to change and refused to adapt to new circumstances.",
            synonym2: "competing, conflicting",
            synonym5: "competing, conflicting, counteracting, countering, opposing",
          },
          "Shut Down": {
            definition: "cessation or suspension of an operation or activity",
            sentence: "She shut down emotionally to avoid dealing with her problems.",
            synonym2: "standstill, stoppage",
            synonym5: "standstill, stoppage",
          },
          Uneasy: {
            definition: "causing physical or mental discomfort",
            sentence: "He felt uneasy about the upcoming exam and couldn't focus on anything else.",
            synonym2: "aflutter, antsy",
            synonym5: "aflutter, antsy, anxious, atwitter, dithery",
          },
          Withdrawn: {
            definition: "socially detached and unresponsive",
            sentence: "She had withdrawn from social events after an embarrassing incident.",
            synonym2: "backward, bashful",
            synonym5: "backward, bashful, coy, demure, diffident",
          },
        },
      },
      {
        title: "Embarassed/Shame",
        icon: { primaryMood: EmbarassedShameIcon, backgroundColor: "#D94941" },
        list: ["Ashamed", "Humiliated", "Inhibited", "Mortified", "Self-conscious", "Useless", "Weak", "Worthless"],
        text: {
          Ashamed: {
            definition: "sense of guilt, disgrace, inferiority or unworthiness",
            sentence: "He was deeply ashamed of his behavior.",
            synonym2: "chagrined, contrite",
            synonym5: "chagrined, contrite, repentant, rueful, sheepish",
          },
          Humiliated: {
            definition: "to make (someone) ashamed or embarrassed",
            sentence: "She felt humiliated after being publicly criticized by her boss.",
            synonym2: "abashed, discomfited",
            synonym5: "abashed, discomfited, discomforted, discomposed, disconcerted",
          },
          Inhibited: {
            definition:
              "to discourage from free or spontaneous activity especially through the operation of inner psychological or external social constraints",
            sentence: "She was inhibited and couldn't express her true feelings to her partner.",
            synonym2: "aloof, indrawn",
            synonym5: "aloof, indrawn, introverted, restrained, closemouthed",
          },
          Mortified: {
            definition: "feeling or showing strong shame or embarrassment",
            sentence: "She was mortified by the inappropriate comment she had made during the meeting.",
            synonym2: "aggravated, agitated",
            synonym5: "aggravated, agitated, bothered, chagrined, dismayed",
          },
          "Self-conscious": {
            definition: "aware of oneself as an individual, intensely aware of oneself",
            sentence: "He felt self-conscious about his appearance and avoided social gatherings.",
            synonym2: "abashed, discomfited",
            synonym5: "abashed, discomfited, discomforted, discomposed, disconcerted",
          },
          Useless: {
            definition: "having or being of no use",
            sentence: "She felt useless after being laid off from her job.",
            synonym2: "impracticable, impractical",
            synonym5: "impracticable, impractical, inoperable, nonpractical, unserviceable",
          },
          Weak: {
            definition: "lacking strength",
            sentence: "He felt weak and helpless after a debilitating illness.",
            synonym2: "asthenic, debilitated",
            synonym5: "asthenic, debilitated, delicate, down-and-out, effete",
          },
          Worthless: {
            definition: "valueless, useless, contemptible, despicable",
            sentence: "She felt worthless after being rejected by her crush.",
            synonym2: "chaffy, empty",
            synonym5: "chaffy, empty, junky, no-good, null",
          },
        },
      },
      {
        title: "Fear",
        icon: { primaryMood: FearIcon, backgroundColor: "#44403D" },
        list: [
          "Afraid",
          "Anxious",
          "Apprehensive",
          "Frightened",
          "Hesitant",
          "Nervous",
          "Panic",
          "Paralyzed",
          "Scared",
          "Terrified",
          "Worried",
        ],
        text: {
          Afraid: {
            definition: "filled with fear or apprehension, filled with concern or regret over an unwanted situation",
            sentence: "She was afraid of speaking in public and avoided it at all costs.",
            synonym2: "affrighted, aghast",
            synonym5: "affrighted, aghast, alarmed, fearful, frightened",
          },
          Anxious: {
            definition: "worried, characterized by, resulting from, or causing anxiety",
            sentence: "She felt anxious about the upcoming flight and couldn't relax.",
            synonym2: "aflutter, antsy",
            synonym5: "aflutter, antsy, atwitter, dithery, edgy",
          },
          Apprehensive: {
            definition: "viewing the future with anxiety or alarm",
            sentence: "He was apprehensive about the interview and couldn't sleep the night before.",
            synonym2: "alive, aware",
            synonym5: "alive, aware, cognizant, conscious, mindful",
          },
          Frightened: {
            definition: "feeling fear",
            sentence: "She was frightened by the loud thunderstorm outside.",
            synonym2: "affrighted, afraid",
            synonym5: "affrighted, afraid, aghast, alarmed, fearful",
          },
          Hesitant: {
            definition: "showing or feeling reluctance or hesitation",
            sentence: "She hesitated before making a decision that could have major consequences.",
            synonym2: "cagey, disinclined",
            synonym5: "cagey, disinclined, dubious, indisposed, loath",
          },
          Nervous: {
            definition: "timid, apprehensive, easily excited or irritated",
            sentence: "He was nervous about meeting his girlfriend's parents for the first time.",
            synonym2: "aflutter, antsy",
            synonym5: "aflutter, antsy, anxious, atwitter, dithery",
          },
          Panic: {
            definition: "sudden uncontrollable fear or anxiety, often causing wildly unthinking behavior.",
            sentence: "She panicked when she realized she had left her wallet at home.",
            synonym2: "alarm, anxiety",
            synonym5: "alarm, anxiety, dread, fear, fearfulness",
          },
          Paralyzed: {
            definition: "affected with paralysis, rendered incapable of movement or action",
            sentence: "She was paralyzed with fear at the sight of the huge spider.",
            synonym2: "paralytic",
            synonym5: "paralytic",
          },
          Scared: {
            definition: "thrown into or being in a state of fear, fright, or panic",
            sentence: "She was scared of the dark and couldn't sleep without a night light.",
            synonym2: "affrighted, afraid",
            synonym5: "affrighted, afraid, aghast, alarmed, fearful",
          },
          Terrified: {
            definition: "scare, deter, intimidate, to fill with terror",
            sentence: "She was terrified of flying and refused to travel by plane.",
            synonym2: "affrighted, afraid",
            synonym5: "affrighted, afraid, aghast, alarmed, fearful",
          },
          Worried: {
            definition: "feeling or showing concern or anxiety about what is happening or might happen",
            sentence: "He was worried about his finances and couldn't enjoy his vacation.",
            synonym2: "aflutter, antsy",
            synonym5: "aflutter, antsy, anxious, atwitter, dithery",
          },
        },
      },
      {
        title: "Fragile",
        icon: { primaryMood: FragileIcon, backgroundColor: "#C15023" },
        list: ["Helpless", "Sensitive"],
        text: {
          Helpless: {
            definition: "lacking protection or support",
            sentence: "She felt helpless and couldn't solve the problem on her own.",
            synonym2: "defenseless, exposed",
            synonym5: "defenseless, exposed, susceptible, undefended, unguarded",
          },
          Sensitive: {
            definition: "highly responsive or susceptible",
            sentence: "She was sensitive to the needs of others and always tried to be understanding.",
            synonym2: "acute, delicate",
            synonym5: "acute, delicate, fine, keen, perceptive",
          },
        },
      },
      {
        title: "Grateful",
        icon: { primaryMood: GratefulIcon, backgroundColor: "#F6C5F6" },
        list: ["Appreciative", "Blessed", "Delighted", "Fortunate", "Grace", "Humbled", "Lucky", "Moved", "Thankful", "Touched"],
        text: {
          Appreciative: {
            definition: "having or showing appreciation",
            sentence: "I am appreciative of your help and support.",
            synonym2: "admiring, applauding",
            synonym5: "admiring, applauding, approbatory, approving, commendatory",
          },
          Blessed: {
            definition: "held in reverence",
            sentence: "I feel blessed to have such a wonderful family and friends.",
            synonym2: "divine, godlike",
            synonym5: "divine, godlike, godly, heavenly, holy",
          },
          Delighted: {
            definition: "delightful, highly pleased",
            sentence: "She was delighted with the news of her promotion.",
            synonym2: "blissful, chuffed",
            synonym5: "blissful, chuffed, glad, gratified, happy",
          },
          Fortunate: {
            definition: "auspicious, receiving some unexpected good",
            sentence: "I consider myself fortunate to have such amazing people in my life.",
            synonym2: "fluky, fortuitous",
            synonym5: "fluky, fortuitous, happy, heaven-sent, lucky",
          },
          Grace: {
            definition: "simple elegance or refinement of movement.",
            sentence: "She carried herself with grace and poise throughout the difficult situation.",
            synonym2: "benevolence, boon",
            synonym5: "benevolence, boon, courtesy, favor, indulgence",
          },
          Humbled: {
            definition: "not proud or haughty",
            sentence: "I was humbled by the kindness shown to me by strangers.",
            synonym2: "abased, chastened",
            synonym5: "abased, chastened, cheapened, debased, degraded",
          },
          Lucky: {
            definition: "fortuitous, producing or resulting in good by chance",
            sentence: "He felt lucky to have found the love of his life.",
            synonym2: "fortunate, happy",
            synonym5: "fortunate, happy",
          },
          Moved: {
            definition: "to stir the emotions, feelings, or passions of",
            sentence: "The movie moved her to tears with its powerful message.",
            synonym2: "budged, dislocated",
            synonym5: "budged, dislocated, displaced, disturbed, relocated",
          },
          Thankful: {
            definition: "conscious of benefit received, expressive of thanks, well pleased",
            sentence: "I am thankful for all the blessings in my life.",
            synonym2: "blissful, chuffed",
            synonym5: "blissful, chuffed, delighted, glad, gratified",
          },
          Touched: {
            definition: "emotionally stirred (as with gratitude), slightly unbalanced mentally",
            sentence: "The heartfelt note from her friend touched her deeply.",
            synonym2: "dotty, fey",
            synonym5: "dotty, fey, loopy, off, potty",
          },
        },
      },
      {
        title: "Guilt",
        icon: { primaryMood: GuiltIcon, backgroundColor: "#897476" },
        list: ["Regret", "Remorseful", "Sorry"],
        text: {
          Regret: {
            definition: "to mourn the loss or death of, to miss very much, to be very sorry for",
            sentence: "She felt regret for not speaking up when she had the chance.",
            synonym2: "bemoan, deplore",
            synonym5: "bemoan, deplore, lament, repent, rue",
          },
          Remorseful: {
            definition: "motivated or marked by remorse",
            sentence: "He was remorseful for his actions and wished he could take them back.",
            synonym2: "apologetic, compunctious",
            synonym5: "apologetic, compunctious, contrite, penitent, regretful",
          },
          Sorry: {
            definition: "feeling sorrow or sympathy, feeling regret or penitence",
            sentence: "I am sorry for any pain I may have caused you.",
            synonym2: "cheap, contemptible",
            synonym5: "cheap, contemptible, cruddy, deplorable, despicable",
          },
        },
      },
      {
        title: "Hopeful",
        icon: { primaryMood: HopefulIcon, backgroundColor: "#77B1E4" },
        list: ["Encouraged", "Expectant", "Optimistic", "Trusting"],
        text: {
          Encouraged: {
            definition: "to inspire with courage, spirit, or hope",
            sentence: "The encouraging words from her coach left her feeling uplifted.",
            synonym2: "comforted, emboldened",
            synonym5: "comforted, emboldened, heartened, reassured, unafraid",
          },
          Expectant: {
            definition: "characterized by expectation",
            sentence: "She was expectant of great things to come in the new year.",
            synonym2: "agape, agog",
            synonym5: "agape, agog, anticipant, anticipatory",
          },
          Optimistic: {
            definition: "feeling or showing hope for the future",
            sentence: "Despite the setbacks, she remained optimistic that things would get better.",
            synonym2: "auspicious, bright",
            synonym5: "auspicious, bright, encouraging, fair, golden",
          },
          Trusting: {
            definition: "believe, to place confidence in",
            sentence: "I am trusting that everything will work out in the end.",
            synonym2: "confiding, trustful",
            synonym5: "confiding, trustful",
          },
        },
      },
      {
        title: "Powerless",
        icon: { primaryMood: PowerlessIcon, backgroundColor: "#000002" },
        list: ["Impotent", "Incapable", "Resigned", "Trapped", "Victim"],
        text: {
          Impotent: {
            definition: "lacking in power, strength, or vigor",
            sentence: "She felt impotent to change the situation and make things right.",
            synonym2: "barren, fruitless",
            synonym5: "barren, fruitless, infertile, sterile, unfruitful",
          },
          Incapable: {
            definition: "lacking capacity, ability, or qualification for the purpose or end in view",
            sentence: "He felt incapable of handling the pressure and stress of the job.",
            synonym2: "inapt, incompetent",
            synonym5: "inapt, incompetent, inept, inexpert, unable",
          },
          Resigned: {
            definition: "feeling or showing acceptance that something unwanted or unpleasant will happen or cannot be changed",
            sentence: "She resigned herself to the fact that things would never be the same again.",
            synonym2: "acquiescent, nonresistant",
            synonym5: "acquiescent, nonresistant, passive, tolerant, tolerating",
          },
          Trapped: {
            definition: "entrap, to place in a restricted position",
            sentence: "She felt trapped in the cycle of negativity and couldn't break free.",
            synonym2: "ensnared, bound",
            synonym5: "ensnared, bound, enslaved, indentured, abducted",
          },
          Victim: {
            definition:
              "one that is injured, destroyed, or sacrificed under any of various conditions, one that is subjected to oppression, hardship, or mistreatment",
            sentence: "She felt like a victim of circumstances beyond her control.",
            synonym2: "casualty, fatality",
            synonym5: "casualty, fatality, loss, prey",
          },
        },
      },
      {
        title: "Tender",
        icon: { primaryMood: TenderIcon, backgroundColor: "#AEEE7E" },
        list: ["Calm", "Caring", "Loving", "Reflective", "Self-loving", "Serene", "Vulnerable", "Warm"],
        text: {
          Calm: {
            definition: "free from agitation, excitement, or disturbance",
            sentence: "She felt calm and peaceful as she walked through the park.",
            synonym2: "halcyon, hushed",
            synonym5: "halcyon, hushed, lown, peaceful, placid",
          },
          Caring: {
            definition: "feeling or showing concern for or kindness to others",
            sentence: "She showed caring and compassion towards those in need.",
            synonym2: "attentive, beneficent",
            synonym5: "attentive, beneficent, benevolent, benignant, compassionate",
          },
          Loving: {
            definition: "affectionate, painstaking",
            sentence: "She loved her children more than anything in the world.",
            synonym2: "adoring, affectionate",
            synonym5: "adoring, affectionate, devoted, fond, tender",
          },
          Reflective: {
            definition: "thoughtful, deliberative, of, relating to, or caused by reflection",
            sentence: "He spent some time in reflective solitude, pondering the meaning of life.",
            synonym2: "broody, cogitative",
            synonym5: "broody, cogitative, contemplative, meditative, melancholy",
          },
          "Self-loving": {
            definition:
              "an appreciation of one's own worth or virtue, proper regard for and attention to one's own happiness or well-being",
            sentence: "She learned the importance of self-loving and taking care of herself.",
            synonym2: "egocentric, egoistic",
            synonym5: "egocentric, egoistic, egomaniacal, egotistic, narcissistic",
          },
          Serene: {
            definition: "marked by or suggestive of utter calm and unruffled repose or quietude",
            sentence: "She felt serene as she gazed out at the ocean at sunset.",
            synonym2: "arcadian, calm",
            synonym5: "arcadian, calm, hushed, peaceful, placid",
          },
          Vulnerable: {
            definition: "capable of being physically or emotionally wounded, open to attack or damage",
            sentence: "She felt vulnerable and exposed, but also brave for sharing her story.",
            synonym2: "endangered, exposed",
            synonym5: "endangered, exposed, liable, open, sensitive",
          },
          Warm: {
            definition: "marked by or readily showing affection, gratitude, cordiality, or sympathy",
            sentence: "The warm embrace of her loved ones brought her comfort and joy.",
            synonym2: "heated, hottish",
            synonym5: "heated, hottish, lukewarm, tepid, toasty",
          },
        },
      },
      {
        title: "Stressed/Tense",
        icon: { primaryMood: StressedTenseIcon, backgroundColor: "#E0DC48" },
        list: [
          "Anxious",
          "Burned out",
          "Cranky",
          "Depleted",
          "Edgy",
          "Exhausted",
          "Frazzled",
          "Overwhelm",
          "Rattled",
          "Rejecting",
          "Restless",
          "Shaken",
          "Tight",
          "Weary",
          "Worn out",
        ],
        text: {
          Anxious: {
            definition: "worried, characterized by, resulting from, or causing anxiety",
            sentence: "He was anxious about the outcome of the interview and couldn't seem to calm his racing thoughts.",
            synonym2: "aflutter, antsy",
            synonym5: "aflutter, antsy, atwitter, dithery, edgy",
          },
          "Burned out": {
            definition: "depleted in strength, energy, or freshness",
            sentence: "After working long hours for weeks, she felt completely burned out.",
            synonym2: "worn-out, exhausted",
            synonym5: "drained,  exhausted, overtaxed, spent, worn-out",
          },
          Cranky: {
            definition: "ill-tempered; irritable",
            sentence: "He was feeling cranky and irritable due to lack of sleep.",
            synonym2: "awkward, bunglesome",
            synonym5: "awkward, bunglesome, clumsy, clunky, cumbersome",
          },
          Depleted: {
            definition: "to lessen markedly in quantity, content, power, or value",
            sentence: "The athlete felt depleted after running the marathon.",
            synonym2: "bankrupted, consumed",
            synonym5: "bankrupted, consumed, debilitated, diminished, drained",
          },
          Edgy: {
            definition: "tense, irritable, characterized by tension",
            sentence: "The constant noise in the city made her feel edgy and nervous.",
            synonym2: "aflutter, antsy",
            synonym5: "aflutter, antsy, anxious, atwitter, dithery",
          },
          Exhausted: {
            definition: "extremely tired",
            sentence: "She was so exhausted that she fell asleep as soon as her head hit the pillow.",
            synonym2: "all in, aweary",
            synonym5: "all in, aweary, beat, beaten, bleary",
          },
          Frazzled: {
            definition: "in a state of extreme physical or nervous fatigue and agitation",
            sentence: "The pressure of the upcoming deadline left her feeling frazzled and overwhelmed.",
            synonym2: "abraded, chafed",
            synonym5: "abraded, chafed, corraded, eroded, frayed",
          },
          Overwhelm: {
            definition: "give too much of a thing to (someone)",
            sentence: "She felt a sense of overwhelm and anxiety as she looked at the pile of work in front of her.",
            synonym2: "crush, devastate",
            synonym5: "crush, devastate, floor, grind (down), oppress",
          },
          Rattled: {
            definition: "cause (someone) to feel nervous, worried, or irritated",
            sentence: "The unexpected news left him rattled and disoriented.",
            synonym2: "aggravated, agitated",
            synonym5: "aggravated, agitated, bothered, chagrined, dismayed",
          },
          Rejecting: {
            definition: "to refuse to accept, consider, submit to, take for some purpose, or use,",
            sentence: "She was rejecting all offers of help and wanted to do everything on her own.",
            synonym2: "declining, denying",
            synonym5: "declining, denying, disallowing, disapproving, negativing",
          },
          Restless: {
            definition: "unquiet, characterized by or manifesting unrest especially of mind",
            sentence: "She was feeling restless and couldn't sit still.",
            synonym2: "restive, uneasy",
            synonym5: "restive, uneasy, unquiet, unrestful",
          },
          Shaken: {
            definition: "to lessen the stability of",
            sentence: "The car accident had left her shaken and traumatized.",
            synonym2: "agitated, bucketed",
            synonym5: "agitated, bucketed, convulsed, jerked, jiggled",
          },
          Tight: {
            definition: "emotionally close",
            sentence: "She felt tight and uncomfortable in her new dress.",
            synonym2: "impenetrable, impermeable",
            synonym5: "impenetrable, impermeable, impervious",
          },
          Weary: {
            definition:
              "exhausted in strength, endurance, vigor, or freshness, expressing or characteristic of weariness, having one's patience, tolerance, or pleasure exhausted —used with of",
            sentence: "She was weary after a long day of work.",
            synonym2: "all in, aweary",
            synonym5: "all in, aweary, beat, beaten, bleary",
          },
          "Worn out": {
            definition: "exhausted or used up by",
            sentence: "He was completely worn out after the long hike.",
            synonym2: "all in, aweary",
            synonym5: "all in, aweary, beat, beaten, bleary",
          },
        },
      },
      {
        title: "Unsettled/Doubt",
        icon: { primaryMood: UnsettledDoubtIcon, backgroundColor: "#E57F30" },
        list: [
          "Apprehensive",
          "Concerned",
          "Dissatisfied",
          "Disturbed",
          "Grouchy",
          "Hesitant",
          "Inhibited",
          "Perplexed",
          "Questioning",
          "Rejecting",
          "Reluctant",
          "Shocked",
          "Skeptical",
          "Suspicious",
          "Ungrounded",
          "Unsure",
          "Worried",
        ],
        text: {
          Apprehensive: {
            definition: "viewing the future with anxiety or alarm: feeling or showing fear or apprehension about the future",
            sentence: "He was apprehensive about the outcome of the job interview.",
            synonym2: "alive, aware",
            synonym5: "alive, aware, cognizant, conscious, mindful",
          },
          Concerned: {
            definition: "anxious, worried, interested, interestedly engaged",
            sentence: "She was concerned about her friend's well-being.",
            synonym2: "absorbed, anxious",
            synonym5: "absorbed, anxious, distracted, engaged, engrossed",
          },
          Dissatisfied: {
            definition: "expressing or showing lack of satisfaction, not pleased or satisfied",
            sentence: "The news of the accident had left her disturbed and upset.",
            synonym2: "aggrieved, discontent",
            synonym5: "aggrieved, discontent, discontented, disgruntled, displeased",
          },
          Disturbed: {
            definition: "experiencing or exhibiting emotional disturbance or agitation",
            sentence: "The lack of sleep had made her grouchy and irritable.",
            synonym2: "agitated, distressed",
            synonym5: "agitated, distressed, perturbed, troubled, unsettled",
          },
          Grouchy: {
            definition: "irritable and bad-tempered; grumpy; complaining",
            sentence: "She was hesitant about making the decision without more information.",
            synonym2: "choleric, crabby",
            synonym5: "choleric, crabby, cranky, cross, crotchety",
          },
          Hesitant: {
            definition: "slow to act or proceed (as from fear, indecision, or unwillingness)",
            sentence: "She felt inhibited and shy in social situations.",
            synonym2: "cagey, disinclined",
            synonym5: "cagey, disinclined, dubious, indisposed, loath",
          },
          Inhibited: {
            definition: "to prohibit from doing something, to hold in check",
            sentence: "He was perplexed by the complex instructions.",
            synonym2: "aloof, indrawn",
            synonym5: "aloof, indrawn, introverted, restrained, closemouthed",
          },
          Perplexed: {
            definition: "filled with uncertainty",
            sentence: "The complex math problem left him feeling perplexed and overwhelmed.",
            synonym2: "baffled, confounded",
            synonym5: "baffled, confounded, hard put, hard-pressed, nonplussed",
          },
          Questioning: {
            definition: "expressing or implying a question, inclined to ask questions or seek information",
            sentence: "She was questioning her beliefs and values.",
            synonym2: "disbelieving, distrustful",
            synonym5: "disbelieving, distrustful, doubting, incredulous, mistrustful",
          },
          Rejecting: {
            definition: "to refuse to accept, consider, submit to, take for some purpose, or use",
            sentence: "She was rejecting all forms of authority and control.",
            synonym2: "declining, denying",
            synonym5: "declining, denying, disallowing, disapproving, negativing",
          },
          Reluctant: {
            definition: "feeling or showing aversion, hesitation, or unwillingness",
            sentence: "She was reluctant to try new things and step outside of her comfort zone.",
            synonym2: "cagey, disinclined",
            synonym5: "cagey, disinclined, dubious, hesitant, indisposed",
          },
          Shocked: {
            definition: "surprised and upset.",
            sentence: "The news of the sudden death had left her shocked and speechless.",
            synonym2: "amazed, astonished",
            synonym5: "amazed, astonished, astounded, awestruck, bowled over",
          },
          Skeptical: {
            definition: "not easily convinced; having doubts or reservations",
            sentence: "She was skeptical of the claims made by the advertisement.",
            synonym2: "disbelieving, distrustful",
            synonym5: "disbelieving, distrustful, doubting, incredulous, mistrustful",
          },
          Suspicious: {
            definition: "questionable, disposed to suspect",
            sentence: "The strange behavior of her neighbor had left her suspicious and uneasy.",
            synonym2: "debatable, disputable",
            synonym5: "debatable, disputable, dodgy, doubtable, doubtful",
          },
          Ungrounded: {
            definition: "lacking a sound basis for belief, action, or argument",
            sentence: "She felt ungrounded and disconnected from herself and the world around her.",
            synonym2: "foundationless, unsupported",
            synonym5: "-",
          },
          Unsure: {
            definition: "not marked by or given to feelings of confident certainty",
            sentence: "She was unsure about which path to take in life.",
            synonym2: "distrustful, doubtful",
            synonym5: "distrustful, doubtful, dubious, hinky, mistrustful",
          },
          Worried: {
            definition: "feeling or showing concern or anxiety about what is happening or might happen",
            sentence: "She was worried about the safety of her loved ones.",
            synonym2: "aflutter, antsy",
            synonym5: "aflutter, antsy, anxious, atwitter, dithery",
          },
        },
      },
    ],
  },
  {
    title: "BodySensationGlossary",
    items: [
      {
        listAtoF: [
          "Achy",
          "Airy",
          "Blocked",
          "Breathless",
          "Bruised",
          "Burning",
          "Buzzy",
          "Clammy",
          "Clenched",
          "Cold",
          "Constricted",
          "Contained",
          "Contracted",
          "Dizzy",
          "Drained",
          "Dull",
          "Electric",
          "Empty",
          "Expanded",
          "Flowing",
          "Fluid",
          "Fluttery",
          "Frozen",
          "Full",
        ],
        text: {
          Achy: {
            definition: "suffering from continuous dull pain",
            sentence: "My legs feel achy after running for an hour.",
            synonym2: "aching, afflictive",
            synonym5: "aching, afflictive, hurting, nasty, painful",
          },
          Airy: {
            definition: "of or relating to air : atmospheric",
            sentence: "The airy breeze made me feel refreshed on a hot day.",
            synonym2: "ethereal, fluffy",
            synonym5: "ethereal, fluffy, gossamer, gossamery, light",
          },
          Blocked: {
            definition: "affected by a psychological block",
            sentence: "My nose is blocked due to my cold.",
            synonym2: "hindered, impeded",
            synonym5: "hindered, impeded, obstructed, excluded, ruled out",
          },
          Breathless: {
            definition: "not breathing",
            sentence: "After climbing up the stairs, I feel breathless and tired.",
            synonym2: "close, stifling",
            synonym5: "close, stifling, stuffy, suffocating",
          },
          Bruised: {
            definition: "having a bruise or many bruises : damaged or wounded by or as if by being struck",
            sentence: "I fell off my bike and now my knee is bruised.",
            synonym2: "blemished, broken",
            synonym5: "blemished, broken, damaged, defaced, defiled",
          },
          Burning: {
            definition: "affecting with or as if with heat",
            sentence: "The burning sensation in my throat indicates acid reflux.",
            synonym2: "ablaze, afire",
            synonym5: "ablaze, afire, aflame, alight, blazing",
          },
          Buzzy: {
            definition: "vibrating, electric zap, or tremor feeling",
            sentence: "My throat felt buzzy after my intense therapy session.",
            synonym2: "fizzing, whirring",
            synonym5: "-",
          },
          Clammy: {
            definition: "being damp, soft, sticky, and usually cool",
            sentence: "My palms are clammy due to my anxiety.",
            synonym2: "antiseptic, arctic",
            synonym5: "antiseptic, arctic, brittle, chill, chilly",
          },
          Clenched: {
            definition: "clinch",
            sentence: "I clenched my jaw so tightly that it hurts now.",
            synonym2: "clung (to), clutched",
            synonym5: "clung (to), clutched, gripped, held",
          },
          Cold: {
            definition: "having or being a temperature that is uncomfortably low for humans",
            sentence: "I am shivering because it's cold and clammy outside.",
            synonym2: "algid, arctic",
            synonym5: "algid, arctic, bitter, bone-chilling, chill",
          },
          Constricted: {
            definition: "to make narrow or draw together",
            sentence: "Anxiety is making my chest feel constricted and tight.",
            synonym2: "bottleneck, close",
            synonym5: "bottleneck, close, compressed, condensed, contracted",
          },
          Contained: {
            definition: "restrained",
            sentence: "I am wearing a tight corset that makes me feel contained and restricted.",
            synonym2: "constrained, curbed",
            synonym5: "constrained, curbed, inhibited, repressed, restrained",
          },
          Contracted: {
            definition: "(of a muscle) become shorter and tighter in order to effect movement of part of the body",
            sentence: "I stretched my muscles so much during the workout that they feel contracted and tight.",
            synonym2: "bottleneck, close",
            synonym5: "bottleneck, close, compressed, condensed, constricted",
          },
          Dizzy: {
            definition: "having a whirling sensation in the head with a tendency to fall",
            sentence: "I feel dizzy and lightheaded after getting up too quickly.",
            synonym2: "aswoon, giddy",
            synonym5: "aswoon, giddy, light-headed, reeling, swimmy",
          },
          Drained: {
            definition: "to draw off (liquid) gradually or completely",
            sentence: "I am so drained after a long day of work.",
            synonym2: "all in, aweary",
            synonym5: "all in, aweary, beat, beaten, bleary",
          },
          Dull: {
            definition: "tedious, uninteresting",
            sentence: "The dull pain in my lower back has been bothering me for days.",
            synonym2: "blunt, blunted",
            synonym5: "blunt, blunted, dulled, obtuse",
          },
          Electric: {
            definition: "exciting as if by electric shock",
            sentence: "The electric shock sensation is running through my arm after hitting my funny bone.",
            synonym2: "breathtaking, charged",
            synonym5: "breathtaking, charged, electrifying, exciting, exhilarating",
          },
          Empty: {
            definition: "containing nothing",
            sentence: "My mind feels empty and blank after a long day of studying.",
            synonym2: "bare, blank",
            synonym5: "bare, blank, clean, devoid, stark",
          },
          Expanded: {
            definition: "extended",
            sentence: "I took a deep breath and my chest expanded as my lungs filled with air.",
            synonym2: "ballooned, blown up",
            synonym5: "ballooned, blown up, bloated, blown, distended",
          },
          Flowing: {
            definition: "smooth and graceful",
            sentence: "The blood is flowing through my veins, making me feel alive and energized.",
            synonym2: "fluent, fluid",
            synonym5: "fluent, fluid, liquid",
          },
          Fluid: {
            definition: "characterized by or employing a smooth easy style",
            sentence: "My nose is running and my throat feels fluid and congested.",
            synonym2: "flowing, fluent",
            synonym5: "flowing, fluent, liquid",
          },
          Fluttery: {
            definition: "to move with quick wavering or flapping motions",
            sentence: "My heart is racing and I feel fluttery and anxious.",
            synonym2: "excitable, fiddle-footed",
            synonym5: "excitable, fiddle-footed, flighty, high-strung, hyper",
          },
          Frozen: {
            definition: "subject to long and severe cold",
            sentence: "My fingers feel frozen after playing in the snow for too long.",
            synonym2: "fast, firm",
            synonym5: "fast, firm, jammed, lodged, set",
          },
          Full: {
            definition: "containing as much or as many as is possible",
            sentence: "After eating a big meal, my stomach feels full and bloated.",
            synonym2: "brimful, brimming",
            synonym5: "brimful, brimming, bursting, chockablock, chock-full",
          },
        },
      },
      {
        listGtoL: ["Gentle", "Hard", "Heavy", "Hollow", "Hot", "Icy", "Itchy", "Jumpy", "Knotted", "Light", "Loose"],
        text: {
          Gentle: {
            definition: "free from harshness, sternness, or violence",
            sentence: "The gentle massage relaxed my tense muscles and calmed my mind.",
            synonym2: "balmy, benign",
            synonym5: "balmy, benign, bland, delicate, light",
          },
          Hard: {
            definition: "not easily penetrated : not easily yielding to pressure",
            sentence: "The surface of the desk was hard and uncomfortable to rest my arms on.",
            synonym2: "affectless, callous",
            synonym5: "affectless, callous, case-hardened, cold-blooded, compassionless",
          },
          Heavy: {
            definition: "having great weight",
            sentence: "The heavy weight on my chest makes it hard to breathe.",
            synonym2: "hefty, massive",
            synonym5: "hefty, massive, ponderous, weighty",
          },
          Hollow: {
            definition: "having an unfilled or hollowed-out space within",
            sentence: "The sound of my voice echoed through the hollow room.",
            synonym2: "concave, dented",
            synonym5: "concave, dented, depressed, dished, indented",
          },
          Hot: {
            definition: "having a relatively high temperature",
            sentence: "The summer sun beat down on my skin, making me feel hot and sticky.",
            synonym2: "ardent, boiling",
            synonym5: "ardent, boiling, broiling, burning, fervent",
          },
          Icy: {
            definition: "intensely cold",
            sentence: "My hands are cold and icy from holding the ice cubes for too long.",
            synonym2: "algid, arctic",
            synonym5: "algid, arctic, bitter, bone-chilling, chill",
          },
          Itchy: {
            definition:
              "an uneasy irritating sensation in the upper surface of the skin usually held to result from mild stimulation of pain receptors",
            sentence: "I scratched my mosquito bite and now it's itchy and inflamed.",
            synonym2: "irritating, prickly",
            synonym5: "irritating, prickly, scratchy",
          },
          Jumpy: {
            definition: "nervous, jittery",
            sentence: "I am jumpy and restless due to my anxiety.",
            synonym2: "excitable, fiddle-footed",
            synonym5: "excitable, fiddle-footed, flighty, fluttery, high-strung",
          },
          Knotted: {
            definition: "tied in or with a knot",
            sentence: "The tension in my neck and shoulders is causing a knotted feeling.",
            synonym2: "broken, bumpy",
            synonym5: "broken, bumpy, coarse, irregular, jagged",
          },
          Light: {
            definition: "something that makes vision possible",
            sentence: "The feather feels light and soft in my hand.",
            synonym2: "blaze, flare",
            synonym5: "blaze, flare, fluorescence, glare, gleam",
          },
          Loose: {
            definition: "not rigidly fastened or securely attached",
            sentence: "The loose clothing makes me feel comfortable and relaxed.",
            synonym2: "insecure, lax",
            synonym5: "insecure, lax, loosened, relaxed, slack",
          },
        },
      },
      {
        listMtoR: [
          "Nauseous",
          "Numb",
          "Pain",
          "Pounding",
          "Prickly",
          "Pulsing",
          "Queasy",
          "Radiating",
          "Relaxed",
          "Releasing",
          "Rigid",
        ],
        text: {
          Nauseous: {
            definition: "sense of disgust, retching or vomiting",
            sentence: "The nauseous feeling in my stomach is making me feel sick.",
            synonym2: "queasy, squeamish",
            synonym5: "-",
          },
          Numb: {
            definition: "unable to feel anything in a particular part of your body especially as a result of cold or anesthesia",
            sentence: "My feet are numb after sitting for too long in one position.",
            synonym2: "asleep, benumbed",
            synonym5: "asleep, benumbed, dead, insensitive, numbed",
          },
          Pain: {
            definition:
              "a localized or generalized unpleasant bodily sensation or complex of sensations that causes mild to severe physical discomfort and emotional distress and typically results from bodily disorder (such as injury or disease)",
            sentence: "The sharp pain in my ankle indicates a sprain.",
            synonym2: "ache, pang",
            synonym5: "ache, pang, prick, shoot, smart",
          },
          Pounding: {
            definition: "to reduce to powder or pulp by beating",
            sentence: "The pounding headache is making it hard to concentrate.",
            synonym2: "bashing, battering",
            synonym5: "bashing, battering, bludgeoning, clobbering, hammering",
          },
          Prickly: {
            definition: "full of or covered with prickles",
            sentence: "My skin feels prickly and itchy after getting a sunburn.",
            synonym2: "irritating, itchy",
            synonym5: "irritating, itchy, scratchy",
          },
          Pulsing: {
            definition: "to exhibit a pulse or pulsation : throb",
            sentence: "The pulsing sensation in my head is due to a migraine.",
            synonym2: "beating, palpitating",
            synonym5: "beating, palpitating, pit-a-patting, pitter-pattering, pulsating",
          },
          Queasy: {
            definition: "causing nausea",
            sentence: "The queasy feeling in my stomach tells me I should not have eaten that pizza.",
            synonym2: "ill, nauseated",
            synonym5: "ill, nauseated, nauseous, qualmish, queer",
          },
          Radiating: {
            definition: "to proceed in a direct line from or toward a center",
            sentence: "The warmth from the fire is radiating on my face and making me feel cozy.",
            synonym2: "branching, fanning (out)",
            synonym5: "branching, fanning (out), raying",
          },
          Relaxed: {
            definition: "freed from or lacking in precision or stringency",
            sentence: "The relaxing music and candlelight made me feel relaxed and calm.",
            synonym2: "canny, comfortable",
            synonym5: "canny, comfortable, comfy, cozy, snug",
          },
          Releasing: {
            definition: "to set free from restraint, confinement, or servitude",
            sentence: "The releasing sensation in my muscles after a massage is very soothing.",
            synonym2: "loosening, loosing",
            synonym5: "loosening, loosing, uncorking, unleashing, unlocking",
          },
          Rigid: {
            definition: "deficient in or devoid of flexibility",
            sentence: "The rigid brace is supporting my spine and making me feel stable.",
            synonym2: "brassbound, cast-iron",
            synonym5: "brassbound, cast-iron, exacting, hard-line, inflexible",
          },
        },
      },
      {
        listStoZ: [
          "Sensitive",
          "Settled",
          "Shaky",
          "Shivery",
          "Slow",
          "Smooth",
          "Soft",
          "Sore",
          "Spacey",
          "Spacious",
          "Sparkly",
          "Stiff",
          "Still",
          "Suffocated",
          "Sweaty",
          "Tender",
          "Tense",
          "Throbbing",
          "Tight",
          "Tingling",
          "Trembly",
          "Twitchy",
          "Vibrating",
          "Warm",
          "Wobbly",
          "Wooden",
        ],
        text: {
          Sensitive: {
            definition: "sensory",
            sentence: "My skin is sensitive and sore after getting a sunburn.",
            synonym2: "acute, delicate",
            synonym5: "acute, delicate, fine, keen, perceptive",
          },
          Settled: {
            definition: "to place so as to stay",
            sentence: "I feel settled and peaceful after a long day of hiking.",
            synonym2: "bred-in-the-bone, confirmed",
            synonym5: "bred-in-the-bone, confirmed, deep, deep-rooted, deep-seated",
          },
          Shaky: {
            definition: "characterized by shakes",
            sentence: "The shaky feeling in my hands is due to my nervousness.",
            synonym2: "aquiver, atremble",
            synonym5: "aquiver, atremble, quaking, quavery, quivering",
          },
          Shivery: {
            definition: "easily broken into shivers",
            sentence: "The shivery feeling down my spine indicates that I am scared.",
            synonym2: "algid, arctic",
            synonym5: "algid, arctic, bitter, bone-chilling, chill",
          },
          Slow: {
            definition: "mentally dull",
            sentence: "The slow and steady breaths help me calm down and feel relaxed.",
            synonym2: "crawling, creeping",
            synonym5: "crawling, creeping, dallying, dawdling, dilatory",
          },
          Smooth: {
            definition: "having a continuous even surface",
            sentence: "The smooth and silky fabric feels nice against my skin.",
            synonym2: "debonair, sophisticated",
            synonym5: "debonair, sophisticated, suave, svelte, urbane",
          },
          Soft: {
            definition: "pleasing or agreeable to the senses : bringing ease, comfort, or quiet",
            sentence: "The pillow was soft and fluffy, making it easy to drift off to sleep.",
            synonym2: "dull, gentle",
            synonym5: "dull, gentle, low, quiet",
          },
          Sore: {
            definition: "causing emotional pain or distress",
            sentence: "My muscles were sore after the intense workout, but it was worth it.",
            synonym2: "aching, achy",
            synonym5: "aching, achy, afflictive, hurting, nasty",
          },
          Spacey: {
            definition: "spaced-out",
            sentence: "I felt spacey and disconnected after the long flight.",
            synonym2: "addle, addled",
            synonym5: "addle, addled, addlepated, bedeviled, befogged",
          },
          Spacious: {
            definition: "vast or ample in extent : roomy",
            sentence: "The living room was spacious and airy, with plenty of room to move around.",
            synonym2: "ample, capacious",
            synonym5: "ample, capacious, commodious, roomy",
          },
          Sparkly: {
            definition: "tending to sparkle : shining with or reflecting bright points of light",
            sentence: "The sunshine made the ocean look sparkly and inviting.",
            synonym2: "bright, buoyant",
            synonym5: "bright, buoyant, cheerful, chipper, chirpy",
          },
          Stiff: {
            definition: "not easily bent : rigid",
            sentence: "After sitting at my desk all day, my back became stiff and uncomfortable.",
            synonym2: "inflexible, rigid",
            synonym5: "inflexible, rigid, stiffened, unyielding",
          },
          Still: {
            definition: "devoid of or abstaining from motion",
            sentence: "The forest was so still that you could hear the leaves rustling in the wind.",
            synonym2: "motionlessly, quiet",
            synonym5: "motionlessly, quiet, quietly",
          },
          Suffocated: {
            definition: "to stop the respiration of (as by strangling or asphyxiation)",
            sentence: "The tight space in the elevator made me feel suffocated and claustrophobic.",
            synonym2: "choked, damped",
            synonym5: "choked, damped, dead, doused, extinguished",
          },
          Sweaty: {
            definition: "causing sweat",
            sentence: "I was sweaty and out of breath after running up the hill.",
            synonym2: "arduous, Augean",
            synonym5: "arduous, Augean, backbreaking, challenging, demanding",
          },
          Tender: {
            definition: "marked by, responding to, or expressing the softer emotions : fond, loving",
            sentence: "The kitten was so tender and delicate that I held her carefully.",
            synonym2: "delicate, fragile",
            synonym5: "delicate, fragile, frail, sensitive",
          },
          Tense: {
            definition: "marked by strain or suspense",
            sentence: "The muscles in my neck are tense, causing a lot of discomfort.",
            synonym2: "aflutter, antsy",
            synonym5: "aflutter, antsy, anxious, atwitter, dithery",
          },
          Throbbing: {
            definition: "to pulsate or pound with abnormal force or rapidity",
            sentence: "The throbbing pain in my head is making it difficult to concentrate.",
            synonym2: "palpitating, pulsating",
            synonym5: "palpitating, pulsating, rocky, staggery, unstable",
          },
          Tight: {
            definition: "having elements close together",
            sentence: "My chest feels tight and constricted, making it hard to take deep breaths.",
            synonym2: "impenetrable, impermeable",
            synonym5: "impenetrable, impermeable, impervious",
          },
          Tingling: {
            definition: "to feel a ringing, stinging, prickling, or thrilling sensation",
            sentence: "A tingling sensation in my fingertips usually signals that I'm starting to feel anxious.",
            synonym2: "needlelike, prickly",
            synonym5: "needlelike, prickly, brisk, invigorating, nippy",
          },
          Trembly: {
            definition: "marked by trembling : tremulous",
            sentence: "I feel trembly and shaky after a strong emotional experience like public speaking.",
            synonym2: "aquiver, atremble",
            synonym5: "aquiver, atremble, quaking, quavery, quivering",
          },
          Twitchy: {
            definition: "marked by twitches or jerking movements : tending to twitch",
            sentence: "The twitchy feeling in my leg is a sign that I need to get up and move around.",
            synonym2: "antsy, fidgety",
            synonym5: "antsy, fidgety, squirmy, wiggly, wriggly",
          },
          Vibrating: {
            definition: "to swing or move to and fro",
            sentence: "Sometimes I feel like my body is vibrating with energy after a workout.",
            synonym2: "agitating, bucketing",
            synonym5: "agitating, bucketing, convulsing, jerking, jiggling",
          },
          Warm: {
            definition: "having or giving out heat to a moderate or adequate degree",
            sentence: "My hands feel warm and tingly after spending some time in the sun.",
            synonym2: "heated, hottish",
            synonym5: "heated, hottish, lukewarm, tepid, toasty",
          },
          Wobbly: {
            definition: "tending to move unsteadily from side to side",
            sentence: "I feel wobbly and unsteady on my feet after a long day of hiking.",
            synonym2: "aquiver, atremble",
            synonym5: "aquiver, atremble, quaking, quavery, quivering",
          },
          Wooden: {
            definition: "lacking ease or flexibility : awkwardly stiff",
            sentence: "After sitting for too long, my legs feel wooden and stiff.",
            synonym2: "awkward, clumsy",
            synonym5: "awkward, clumsy, gauche, graceless, inelegant",
          },
        },
      },
    ],
  },
];

export default GLOSSARY_DATA;
