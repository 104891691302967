import { useState } from "react"

export const DetailSubsection = ({name, children}) => {
    const [active, setActive] = useState(false)
    return (
        <div 
            id={name} 
            tabIndex="0"
            style={active ? {border: '2px solid hsl(210, 80%, 40%)'} : {}}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            className="detail-subsection"
        >
            {children}
        </div>
    )
}