import React from "react"

export const DetailSubHeader = ({ title, children }) => {
    return (
        <div className="detail-subheader">
            <div className="top-row">
                <h2>
                    {title}
                </h2>
                {children}
            </div>
            <div className="colorLine" style={{width: "100%", height: "6px"}} />
        </div>

    )
}