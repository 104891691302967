import { useState } from "react"

export const Dropdown = ({ title, handleTitleClick, options, comingSoonOptions, choice, setChoice, comingSoonPosition }) => {
    const [showOptions, setShowOptions] = useState(false)
    return (
        <div
            className="drop-down-unit"
            tabIndex="0"
            onBlur={() => setShowOptions(false)}
        >
            <div 
            onClick={() => setShowOptions(!showOptions)} className="trigger">
                {choice}
                <div className={`chevron-container ${showOptions ? 'up' : 'down'}`} />
            </div>
            {showOptions &&
                <div className="drop-down">
                    {title && 
                        <p 
                            className="title"
                            onClick={() => handleTitleClick()}
                        >
                            {title}
                        </p>
                    }
                    {options.map((option, index) =>
                        <p
                            onClick={() => {
                                setShowOptions(false)
                                setChoice(option)
                                console.log(option);
                            }}
                            key={`option${index}`}
                            className={choice === option ? 'active' : ''}
                        >
                            {option}
                        </p>
                    )}
                    {comingSoonOptions?.map((option, index) =>
                        <p key={`comingSoonOption${index}`}>
                            {option}
                            <span className={`coming-soon-message ${comingSoonPosition || 'right'}`}>Coming soon</span>
                        </p>
                    )}
                </div>
            }
        </div>
    )

}