import { useState, useEffect, useContext } from "react";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import { Link } from "react-router-dom";
import "./index.css";
import DetailHeader from "./DetailHeader";
import DetailFooter from "../Shared/DetailFooter";
import { DetailTitle } from "./DetailTitle";
import { DiseaseSurvey } from "./PageContent/DiseaseSurvey";
import { Verticals } from "./PageContent/Verticals";
import DoComponent from "./DetailContent/Do";
import SayComponent from "./DetailContent/Say";
import SeeComponent from "./DetailContent/See";
import FeelingsFlowQuestion from "../PublicHealth/FeelingsFlowQuestion";
import { BackgroundContext } from "../../context/background.context";
import ProcessScreen from "../PublicHealth/EmotionalHealth/ProcessScreen";
import { API } from "aws-amplify";

// import { dynamoDBFetch, useGetUserDataQuery } from "../../store/emotionalHealthSlice";

function LearnDetail() {
  // const [showVerticals, setShowVerticals] = useState(false);
  // const [page, setPage] = useState("Community Health");
  const [reachedLimit, setReachedLimit] = useState(false);
  const [glossaryOpen, setGlossaryOpen] = useState(false);
  const { blurBackground, isModalOpen } = useContext(BackgroundContext);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [opacityDark, setOpacityDark] = useState(false);


  //disease is specific to community health page
  const location = useLocation();

  // const [emotional] = useOutletContext()
  const { options, countyData,
    countyLabel,
    stateData,
    stateLabel,
    countryData,
    countryLabel,
    comingSoonOptions,
    headerTitle,
    minuteCardOneHeader,
    minuteCardOneText,
    minuteCardTwoHeader,
    minuteCardTwoText,
    sayComponentText,
    saySectionLabel,
    tokenPayload,
  } = location.state;

  const [showVerticals, setShowVerticals] = useState(false);
  const [page, setPage] = useState('do-say-see');

  //disease is specific to community health page
  const [disease, setDisease] = useState("Covid 19");

  //For calendar - Filter data based on date
  const [value, onChange] = useState(new Date());

  const [emotionalData, setEmotionalData] = useState({});
  const [response, setResponse] = useState({});

  const extractMostRecent = (data) => {
    // console.log('most recent', data)
    return data.payload.Items[0].survey;
  }

 //TODO: Filter data based on date

  //Code block to convert Date value to month day style (Feb 1)
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  // make this a question

  let month = ''
  let month2 =''
  let day ='' 
  let day2 ='' 
  let date1 =''
  let date2 =''

  let selectedDate = date1 + ' - ' + date2

  const setDate = ()=>{
   month = value[0]?.getMonth()
   month2 = value[1]?.getMonth()
   day = value[0]?.getDate()
   day2 = value[1]?.getDate()

   date1 = months[month] + ' ' + day
   date2 = months[month2] + ' ' + day2

   selectedDate = date1 + ' - ' + date2
  }

  const filterByDate = (data) => {
    
    let date = ''
    setDate()
    const filter = data.payload.Items.forEach(el => {
    const dataReformatted = new Date(el?.dateAdded)
      // const month = dataReformatted.getMonth()
      // const day = dataReformatted.getDate()
    date = months[month] + ' ' + day

      // If calendar selected date matches date from DB, update data for it.
      console.log('filtering', date, value)
      if (date1 === date) {
        setEmotionalData(el.survey)
        console.log('emotional data new', emotionalData)
      }
      return date
    });

  }


  //TODO: Dynamically fetch via  by entering tokenPayload
  //  const {data: pFeelings} = dynamoDBFetch.endpoints.getPrimaryFeelings.useQuery(tokenPayload.sub)
  //  const {data: aFeelings} = dynamoDBFetch.endpoints.getAssociatedFeelings.useQuery(tokenPayload.sub)

  // console.log('data from RTK', pFeelings)


  useEffect(() => {
    const DynamoFetch = () => {
      // const {tokenPayload} =props
      console.log('tokenPayload db', tokenPayload)
      const userId = tokenPayload.sub;

      // TODO: integrate limit count to dynamically set limit on M's API (civilience)
      const limit = '4'

      if (!userId) {
        return;
      }

      try {
        API.get("civAPI", `/emotional/retrieve/${userId}/`).then((response, request) => {
          console.log('response from civAPI call', response)
          if (!response.error) {
            const mostRecent = extractMostRecent(response);
            filterByDate(response)
            // setEmotionalData(mostRecent);
            setResponse(response)
          } else {
            setEmotionalData(false);
          }
        });
      } catch (error) {
        console.log('response from public: none', error);
      }

    }
    DynamoFetch()
  }
    , [page, value]
  )

  return (
    <div className="LearnDetail">
      {reachedLimit || glossaryOpen || opacityDark ? <div className="LearnDetail_padding" /> : null}
      <DetailHeader setShowVerticals={setShowVerticals} showVerticals={showVerticals} />
      <DetailTitle headerTitle={headerTitle} setShowVerticals={setShowVerticals} setPage={setPage} />
      {!showVerticals & page === 'do-say-see' ? (
        // & page === "Community Health"
        <>
          <div id="DO-SAY-SEE" style={{ display: showVerticals ? "none" : "block" }}>
            <DoComponent
              disease={disease}
              headerTitle={headerTitle}
              minuteCardOneHeader={minuteCardOneHeader}
              minuteCardOneText={minuteCardOneText}
              minuteCardTwoHeader={minuteCardTwoHeader}
              minuteCardTwoText={minuteCardTwoText}
              setDisease={setDisease}
              setPage={setPage}
            />
            <SayComponent
              sayComponentText={sayComponentText}
            />
            <SeeComponent
              headerTitle={headerTitle}
              countyData={countyData}
              countyLabel={countyLabel}
              stateData={stateData}
              stateLabel={stateLabel}
              countryData={countyData}
              countryLabel={countryLabel}
              options={options}
              comingSoonOptions={comingSoonOptions}
              saySectionLabel={saySectionLabel}
              tokenPayload={tokenPayload}
              page={page}
              value={value}
            />

            {/* TODO: Process screen moved here for testing. Move Process screen back to position.  */}
            {/* <ProcessScreen
              options={options}
              value={value}
              onChange={onChange}
              setPage={setPage}
              emotionalData={emotionalData}
              date1={date1}
              date2={date2}
              selectedDate={selectedDate}
            /> */}
          </div>
        </>
      ) : null}

      {/* <Link to={'feelings flow'}>Click for feelings flow </Link> */}

      {(page === "survey" & headerTitle === "Community Health") ? (
        <DiseaseSurvey
          disease={disease}
          setDisease={setDisease}
          showVerticals={showVerticals}
          page={page}
          setPage={setPage} />
      ) : null}
      {(page === "survey" & headerTitle === "Emotional Health") ?
        <FeelingsFlowQuestion
          tokenPayload={tokenPayload}
          setPage={setPage}
          reachedLimit={reachedLimit}
          setReachedLimit={setReachedLimit}
          glossaryOpen={glossaryOpen}
          setGlossaryOpen={setGlossaryOpen}
          isAlertOpen={isAlertOpen}
          setIsAlertOpen={setIsAlertOpen}
          opacityDark={opacityDark}
          setOpacityDark={setOpacityDark} />

        : null}
      {(page==="Process" ) ?
         <ProcessScreen options={options} value={value} onChange= {onChange} setPage={setPage } emotionalData={emotionalData}/>
          : null}

      {showVerticals && <Verticals />}

      <DetailFooter />


      {isAlertOpen && (
        <div className="alert-div alert-message" style={{ position: "absolute", top: "530px", border: "none" }}>
          <span>You may only select 12</span>
          <button className="close-button" style={{ border: "none", marginRight: 5, marginTop: -8 }} onClick={() => { setIsAlertOpen(false); setReachedLimit(false) }}>
            X
          </button>
        </div>
      )}
    </div>
  );
}
export default LearnDetail;
