import GLOSSARY_DATA from "../../glossary-data";
import { useState, useEffect } from "react";

export const AssociatedGlossary = (props) => {
  const [sensationClicked, setSensationClicked] = useState(null);
  // Will hold text for: Definition, Synonym, & Example Sentence
  const [textObj, setTextObj] = useState({});

  // ASSOCIATED GLOSSARY
  const glossaryData = GLOSSARY_DATA[1].items;

  const { findMood, setGlossaryOpen } = props;

  //uSER CHOICE FROM QUESTION ONE
  const userMood = findMood.answer;

  //MATCH USER CHOICE TO ASSOCIATED FEELINGS IN GLOSSARY
  const findListInGlossary = glossaryData.find((mood) => {
    // console.log(`mood.title: ${mood.title} & userMood: ${userMood} `);
    return mood.title === userMood;
  });

  const isDarkBackground = findListInGlossary.title;

  //Background Color to display within Glossary i.e. #F0E3F0
  const glossaryColor = findListInGlossary.icon.backgroundColor;

  //DISPLAY ASSOCIATED MOODS FOR USER'S PRIMARY FEELING
  const listToDisplay = findListInGlossary.list;

  // When sensationClicked changes, update definition, synonym, example sentence
  useEffect(() => {
    // console.log("Finding corresponding text for: ", sensationClicked);
    const glossaryText = findListInGlossary.text;

    const matchedKey = Object.keys(glossaryText).find((key) => key === sensationClicked);

    const matchedText = glossaryText[matchedKey];
    setTextObj(matchedText);
  }, [sensationClicked]);

  //Set background color within glossary
  useEffect(() => {
    setSensationClicked(listToDisplay[0]);
  }, []);

  const handleClick = (word) => {
    setSensationClicked(word);
  };

  //OPEN/CLOSE DEFINITION POPUP
  const popupCallback = () => {
    // setIsPopupOpen(!isPopupOpen);
    //close inside MajorFeelingForm
    props.handlePopup();
    setGlossaryOpen(false);
  };

  return (
    <div className="associated-glosary-container">
      <div className="glossary-header-container">
        <span className="glossary-header">Associated Feelings Definitions</span>
        <span className="glossary-close" onClick={popupCallback}>
          X
        </span>
      </div>

      <div className="glossary-split-container">
        <span className="glossary-split"></span>
      </div>

      <ul className="associated-glossary-list">
        {listToDisplay.map((word, index) => {
          return (
            <li
              className={sensationClicked === word ? "glossary-list-item-clicked" : "glossary-list-item"}
              key={index}
              onClick={() => handleClick(word)}
            >
              {word}
            </li>
          );
        })}
      </ul>

      {/* User Option */}
      <div
        // Darker Background -> white text, Light Background -> black text
        className={
          isDarkBackground === "Powerless" ||
          isDarkBackground === "Accepting/Open" ||
          isDarkBackground === "Fragile" ||
          isDarkBackground === "Despair/Sad" ||
          isDarkBackground === "Angry/Annoyed" ||
          isDarkBackground === "Fear" ||
          isDarkBackground === "Connected/Loving"
            ? "associated-glossary-feeling-light"
            : "associated-glossary-feeling"
        }
        style={{ backgroundColor: `${glossaryColor}` }}
      >
        {sensationClicked ? sensationClicked : null}
      </div>
      {/* Display Clicked Item */}
      <div className="associated-glossary-bottom-container">
        {/* Definition */}
        <div className="associated-glossary-definition">
          <span className="glossary-text-header">Definition:</span>
          <span className="glossary-text">{textObj && textObj.definition}</span>
        </div>

        <div className="glossary-split-container-full">
          <span className="glossary-split"></span>
        </div>

        {/* Synonym */}
        <div className="associated-glossary-synonym">
          <span className="glossary-text-header">Synonym(s):</span>
          {/* Display 2 Synonyms */}
          <span className="glossary-text">{textObj && textObj.synonym2}</span>
          {/* Display 5 Synonyms */}
          {/* <span className="glossary-text">{textObj && textObj.synonym5}</span> */}
        </div>

        <div className="glossary-split-container-full">
          <span className="glossary-split"></span>
        </div>

        {/* Example Sentence */}
        <div className="associated-glossary-sentence">
          <span className="glossary-text-header">Example Sentence:</span>
          <span className="glossary-text">{textObj && textObj.sentence}</span>
        </div>
      </div>
    </div>
  );
};

export default AssociatedGlossary;
