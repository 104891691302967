import { useNavigate } from "react-router-dom"

export const Verticals = () => {
    const navigate = useNavigate()
    const comingSoonVerticals = ['Safety', 'Money & Wealth', 'Civics & Governance', 'Environment & Sustainability', 'Leisure']
    return(
        <div id="verticals">
            <p 
                className="active"
                onClick={() => navigate('/')}
            >
                HEALTH
            </p>
            {comingSoonVerticals.map(vertical => 
                <p className="coming-soon">
                    {vertical.toUpperCase()}<br/>
                    <span>Coming soon!</span>
                </p>
            )}
        </div>
    )
}