import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import downArrow from "../../../images/chevron_down.png";
import upArrow from "../../../images/chevron_up.png";
import Calendar_Icon from "../../../images/Vector.png";
import {
  selectCommunityData,
  selectZipCode,
} from "../../../store/communityHealthSlice";
import { LineChart } from "../LineChart";
import { SeeCircle } from "./SeeCircle";
import { DetailSubHeader } from "./DetailSubHeader";
import { API } from "aws-amplify";
import { Dropdown } from "./Dropdown";
import { DetailSubsection } from "./DetailSubsection";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { dynamoDBFetch } from "../../../store/emotionalHealthSlice";
import { PieChart } from "react-minimal-pie-chart";
import { CarouselItem } from "../../PublicHealth/EmotionalHealth/Process/Carousel";
import Carousel from "../../PublicHealth/EmotionalHealth/Process/Carousel";
import { Slider } from "../../PublicHealth/EmotionalHealth/scroll-menu";
import uuid from "react-uuid";

import GLOSSARY_DATA from "../../PublicHealth/FeelingsFlowTrackerForms/glossary-data";

import "./CalendarStyles.css";

function SeeComponent({
  countyData,
  countyLabel,
  stateData,
  stateLabel,
  countryData,
  countryLabel,
  saySectionLabel,
  headerTitle,
  options,
  comingSoonOptions,
  tokenPayload,
  page,
  value,
}) {
  const data = useSelector(selectCommunityData);
  const [isChartShow, setChartShow] = useState(false);
  const [emotionalData, setEmotionalData] = useState({});
  //Replaced curItem by taking first value of options array.

  const [curItem, setCurItem] = useState(options[0]);
  const [calPopUp, setCalPopUp] = useState(false);
  const [chartData, setChartData] = useState({ 0: 0 });
  const zipCode = useSelector(selectZipCode);

  // loading page
  const [isLoading, setIsLoading] = useState(true);

  // See Section
  const [primary, setPrimary] = useState([]);
  const [associated, setAssociated] = useState([]);
  const [bodySensations, setBodySensations] = useState([]);
  const [uniqueAssociated, setUniqueAssociated] = useState([]);
  const [uniquePrimary, setUniquePrimary] = useState([]);
  const [uniqueBodySensations, setUniqueBodySensations] = useState([]);

  const [countPrimary, setCountPrimary] = useState([]);
  const [countAssociated, setCountAssociated] = useState([]);
  const [countBodySensations, setCountBodySensations] = useState([]);

  const [view, setView] = useState("Thoughts");

  const [associatedMe, setAssociatedMe] = useState(false);
  const [associatedCommunity, setAssociatedCommunity] = useState(false);

  const shiftSize = 3;

  // calender
  const [showCalendar, setShowCalendar] = useState(false);

  const handleButtonClick = () => {
    setShowCalendar(!showCalendar);
  };

  //TODO: Code block to convert Date value to month day style (Feb 1)
  // React Cal: Sat May 20 2023 00:00:00 GMT-0400 (Eastern Daylight Time),
  // From db: 2023-05-12T03:04:44.483Z

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month = value[0]?.getMonth();
  const month2 = value[1]?.getMonth();
  const day = value[0]?.getDate();
  const day2 = value[1]?.getDate();
  const year = value[0]?.getYear();
  const year2 = value[1]?.getYear();

  const date1 = months[month] + " " + day;
  const date2 = months[month2] + " " + day2;

  const selectedDate = date1 + " - " + date2;

  const dateForFetch = year + "-" + day + "-" + month;
  console.log(dateForFetch);

  // *************************************

  //Retrieve corresponding Primary Feeling color from Glossary Data
  //Create an array in this shape: [primaryFeeling, hexcode]

  let GLOSSARY_DATA_PRIMARY_COLORS = [];
  const primaryColors = () => {
    GLOSSARY_DATA[0]["items"].forEach((e) => {
      return GLOSSARY_DATA_PRIMARY_COLORS.push([
        e["headerDescription"],
        e["activeColor"],
      ]);
    });
  };
  primaryColors();

  // *************************************

  useEffect(() => {
    if (!zipCode) {
      return;
    }

    const payload = {
      body: { ZIP: zipCode },
    };

    API.post("civAPI", "/historical", payload).then((response) => {
      if (!response.error) {
        console.log("response", response);
        setChartData(response.payload);
      } else {
        console.log(response);
      }
    });
  }, [zipCode]);

  //May want to combine this with the above useEffect, and use if statement to check if headerTitle is Community Health or Emotional Health
  useEffect(() => {
    function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    }

    //Can also fetch data if headerTitle is Community Health, in the same useEffect
    if (headerTitle === "Emotional Health" && page === "do-say-see") {
      const userId = tokenPayload.sub;
      if (!userId) {
        return;
      }

      // response -> primary  -> unique Primary
      async function emotionalHealthApiCall() {
        try {
          await API.get("civAPI", `/emotional/retrieve/${userId}`).then(
            (response) => {
              if (!response.error) {
                const mostRecent = extractMostRecent(response);
                console.log("response from api call", response);
                setEmotionalData(mostRecent);

                // Filter for Primary
                const Items = response.payload.Items;
                const primaryFeelings = [];

                Items.map(async (item, key) => {
                  const Primary = await item.survey.q1;
                  await primaryFeelings.push(Primary);
                  return primaryFeelings;
                });
                setPrimary(primaryFeelings);
                //Data Filtering for Unique Values of Primary Feelings
                setUniquePrimary(primary.filter(onlyUnique));

                //Filter For Associated
                const associatedFeelings = [];
                Items.map(async (item, key) => {
                  const Associated = await item.survey.q2;
                  await associatedFeelings.push(Associated);
                  return associatedFeelings.flat(2);
                });
                setAssociated(associatedFeelings);
                // Data Filtering for Unique Values of Associated Feelings
                setUniqueAssociated(associated.flat().filter(onlyUnique));

                //Filter For Body Sensations
                const bodySensations = [];
                Items.map(async (item, key) => {
                  const bodySensationsRaw = await item.survey.q4;
                  await bodySensations.push(bodySensationsRaw);
                  return bodySensations;
                });
                setBodySensations(bodySensations);
                // Data Filtering for Unique Values of bodySensations Feelings
                setUniqueBodySensations(
                  bodySensations.flat().filter(onlyUnique)
                );
              } else {
                setEmotionalData(false);
                console.log("response");
              }
            }
          );
        } catch (error) {
          console.log(error);
        }
      }

      emotionalHealthApiCall();
      findAll();
    }
  }, [headerTitle, tokenPayload.sub, page, view]);

  // useEffect(() => {
  //   findAll()
  //   console.log("Body_Sensation_data?:", bodySensations)
  //   console.log("Feeligs_data?:", countPrimary)
  //   setIsLoading(false);
  // }, [bodySensations, countPrimary]);

  //Can modify this function to extract more than one survey result if needed
  const extractMostRecent = (data) => {
    return data.payload.Items[0].survey;
  };

  const linkToStateMap = () => {
    window.open(data.url_state);
  };

  const linkToNationalMap = () => {
    window.open(data.url_USA);
  };

  // FIND ALL FUNCTION DOS THIS
  // original array: ['Accepting/Open','Aliveness/Joy','Aliveness/Joy', 'Aliveness/Joy', 'Angry/Annoyed', 'Angry/Annoyed','Accepting/Open']
  // unique: ['Accepting/Open','Aliveness/Joy', 'Angry/Annoyed']
  // # of instances: [2,3,2]
  // unique-primary-values -> [unique-primary-element, # of instances in original array, color]
  // Sample: countPrimary = ['Accepting/Open', 2, "#362584"]
  // What % of whole: final countPrimary shape: [element, number of instances, color, percentage]

  const findAll = async () => {
    const uniquePrimaryValues = await uniquePrimary;
    const uniqueAssociatedValues = await uniqueAssociated;
    const uniqueBodySensationValues = await uniqueBodySensations;

    uniquePrimaryValues?.forEach((element) => {
      const color = GLOSSARY_DATA_PRIMARY_COLORS?.filter((el) => {
        return el[0] === element;
      });
      const instanceNum = primary?.filter((el) => {
        return el.indexOf(element) !== -1;
      });
      // countPrimary.push([element, instanceNum.length, color])
      setCountPrimary((countPrimary) => [[element, instanceNum.length, color]]);
    });

    uniqueAssociatedValues?.forEach((element) => {
      const instanceNum = associated?.filter((el) => {
        return el.indexOf(element) !== -1;
      });
      // countAssociated.push([element, instanceNum.length])
      setCountAssociated((countAssociated) => [[element, instanceNum.length]]);
    });

    uniqueBodySensationValues?.forEach((element) => {
      const instanceNum = bodySensations?.filter((el) => {
        return el.indexOf(element) != -1;
      });
      // countBodySensations.push([element, instanceNum.length])
      setCountBodySensations((countBodySensations) => [
        [element, instanceNum.length],
      ]);
    });

    const PRIMARY_VALUES = await countPrimary;
    const ASSOCIATED_VALUES = await countAssociated;
    const BODY_SENSATIONS_VALUES = await countBodySensations;

    // For each primary, associated and body sensations selected, the following 3 blocks calculate what % of the whole the unique value makes up.
    // Final countPrimary shape: [element, number of instances, color, percentage]

    PRIMARY_VALUES?.forEach((el) => {
      const percentPrimary = (Number(el[1]) / primary?.length) * 100;
      return setCountPrimary((countPrimary) => [
        ...countPrimary,
        Math.floor(percentPrimary),
      ]);
      // el.push(Math.floor(percentPrimary))
    });

    ASSOCIATED_VALUES?.forEach((el) => {
      const percentAssociated = (Number(el[1]) / associated?.length) * 100;
      return setCountAssociated((countAssociated) => [
        ...countAssociated,
        Math.floor(percentAssociated),
      ]);
      // el.push(Math.floor(percentAssociated))
    });

    BODY_SENSATIONS_VALUES?.forEach((el) => {
      const percentBodySensation =
        (Number(el[1]) / bodySensations?.length) * 100;
      return el.push(Math.floor(percentBodySensation));

      //setCountBodySensations(countBodySensations => [percentBodySensation])
    });
    return countAssociated, countPrimary, countBodySensations;
  };

  return (
    <>
      <DetailSubsection name="See">
        {headerTitle === "Emotional Health" && (
          <>
            <DetailSubHeader title="SEE">
              <div
                className="label-and-dropdown emotional"
                style={{ zIndex: 100 }}
              >
                {
                  <Dropdown
                    options={options}
                    choice={curItem}
                    setChoice={setCurItem}
                    comingSoonPosition="left"
                  />
                }
                {curItem === options[3] ? (
                  <Calendar className="react-calendar" selectRange={true} />
                ) : (
                  <>
                    <button
                      onClick={handleButtonClick}
                      style={{
                        width: "40px",
                        height: "25px",
                        backgroundColor: "#2449A7",
                        borderRadius: "33%",
                        border: "none",
                        zIndex: "10",
                      }}
                    >
                      <img
                        src={Calendar_Icon}
                        width={18}
                        style={{ position: "relative", top: "-1.5px" }}
                      />
                    </button>
                  </>
                )}
                {showCalendar ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "50px",
                      left: "-100px",
                    }}
                  >
                    <Calendar className="react-calendar" selectRange={true} />
                  </div>
                ) : null}
              </div>
            </DetailSubHeader>
            <div className="tabs">
              <p
                onClick={() => setView("Thoughts")}
                className={`${
                  view === "Thoughts" ? "active-tab" : "menu-item"
                } `}
              >
                Thoughts
              </p>

              <p
                onClick={() => setView("Feelings")}
                className={`${
                  view === "Feelings" ? "active-tab" : "menu-item"
                } `}
              >
                Feelings
              </p>

              <p
                onClick={() => {
                  setView("Sensations");
                  console.log("dlrh", countPrimary);
                }}
                className={`${
                  view === "Sensations" ? "active-tab" : "menu-item"
                } `}
                style={{ zIndex: 10 }}
              >
                Body Sensations
              </p>

              <p
                onClick={() => setView("Actions")}
                className={`${
                  view === "Actions" ? "active-tab" : "menu-item"
                } `}
              >
                Actions
              </p>
            </div>
            <div className="emotional-selection"></div>

            <div className="data-breakdown">
              <div className="section-header">
                <h3>ME</h3>
              </div>

              <div className="Me-data-breakdown">
                {countPrimary.length === 0 && view === "Feelings" ? (
                  <div>Loading</div>
                ) : (
                  view === "Feelings" && (
                    <div className="Feelings">
                      <p className="title">Primary Feelings</p>

                      <div className=" Primary">
                        {countPrimary && countPrimary.length > 0 && (
                          <PieChart
                            center={[50, 50]}
                            lengthAngle={360}
                            // paddingAngle={5}
                            lineWidth={45}
                            radius={50}
                            segmentsShift={(index) =>
                              index === 1 ? shiftSize : 0.5
                            }
                            segmentsStyle={(index) =>
                              index === 1 ? { backgroundColor: "#99C492" } : ""
                            }
                            className="pie-chart"
                            // countPrimary shape: [element, number of instances, color, percentage]
                            if
                            countPrimary
                            data={[
                              {
                                title: countPrimary[0]?.[0],
                                value: countPrimary[0]?.[1],
                                color: "#99C492",
                              },
                              {
                                title: countPrimary[1]?.[0],
                                value: countPrimary[1]?.[1],
                                color: "#E38627",
                              },
                              {
                                title: countPrimary[2]?.[0],
                                value: countPrimary[2]?.[1],
                                color: countPrimary[2]?.[2]?.[0]?.[1],
                              },
                              {
                                title: countPrimary[3]?.[0],
                                value: countPrimary[3]?.[1],
                                color: countPrimary[3]?.[2]?.[0]?.[1],
                              },
                            ]}
                          />
                        )}
                        {/* Carousel-menu */}
                        <div className="feelings">
                          {countPrimary?.map((item) => {
                            return (
                              <p style={{}}>
                                {item[3]}% {item[0]}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                      <div className="Associated">
                        <div className="title-and-header">
                          <p className="title">Associated Feelings</p>
                          <img
                            style={{ height: "1em", width: "1em" }}
                            src={`${associatedMe ? upArrow : downArrow}`}
                            alt="down arrow"
                            onClick={() => setAssociatedMe(!associatedMe)}
                          />
                        </div>
                        <div
                          className={`${
                            associatedMe ? "display-emotions" : "feelings"
                          }`}
                        >
                          {countPrimary?.map((item) => {
                            console.log(countPrimary?.indexOf(item));
                            return (
                              <div>
                                <p>{item[0]}</p>
                                <div
                                  key={countPrimary?.indexOf(item)}
                                  style={{
                                    background: `linear-gradient(to left, lightgray ${
                                      100 - item[3]
                                    }%, #2449A7 ${item[3]}% `,
                                  }}
                                  className="bar"
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>

              {view === "Thoughts" && (
                <Carousel>
                  <CarouselItem>
                    <p key={uuid()}>
                      {" "}
                      Career and professional development (job duties, learning
                      new skills, back-end technologies)
                    </p>
                  </CarouselItem>
                  <CarouselItem />
                </Carousel>
              )}
              {bodySensations.length === 0 && view === "Sensations" ? (
                <div>Loading</div>
              ) : (
                view === "Sensations" && (
                  <div className="Feelings">
                    <div className="Primary">
                      {countBodySensations &&
                        countBodySensations.length > 0 && (
                          <PieChart
                            center={[50, 50]}
                            lengthAngle={360}
                            paddingAngle={5}
                            lineWidth={45}
                            radius={50}
                            startAngle={180}
                            className="pie-chart"
                            // TODO: Replace Sample Data with Body sensations data
                            data={[
                              {
                                title: countBodySensations[0][0],
                                value: countBodySensations[0][1],
                                color: "#E38627",
                              },
                            ]}
                          />
                        )}
                      <Slider
                        // className="feelings"
                        count={countBodySensations}
                      />
                      {/* <div >
                    {countBodySensations?.map((item, i) => {
                      return  <p style={{}}>{item[2]}% {item[0]}</p>
                    })}
                  </div> */}
                    </div>
                  </div>
                )
              )}
              {view === "Actions" && (
                <Carousel>
                  <CarouselItem>
                    <p> Coming Soon </p>
                  </CarouselItem>
                  <CarouselItem />
                  <CarouselItem />
                </Carousel>
              )}
              <div className="section-header">
                <h3>COMMUNITY</h3>
                {/* TODO: value of drop-down messes up see header drop down choice (next to calendar) */}

                <Dropdown
                  // TODO: style geolocation dropdown
                  choice={["Geolocation"]}
                  options={["Geolocation"]}
                  comingSoonOptions={["Affiliations", "Personal Groups"]}
                  setChoice={setCurItem}
                  curItem={curItem}
                  comingSoonPosition={"left"}
                />
              </div>
              <div className="Me-data-breakdown">
                {view === "Thoughts" && (
                  <Carousel>
                    <CarouselItem>
                      <p>
                        {" "}
                        Career and professional development (job duties,
                        learning new skills, back-end technologies)
                      </p>
                    </CarouselItem>
                    <CarouselItem />
                    <CarouselItem />
                  </Carousel>
                )}

                {countPrimary.length === 0 && view === "Feelings" ? (
                  <div>Loading</div>
                ) : (
                  view === "Feelings" && (
                    <div className="Feelings">
                      <p className="title">Primary Feelings</p>
                      <div className=" Primary">
                        <PieChart
                          className="pie-chart"
                          center={[50, 50]}
                          lengthAngle={360}
                          paddingAngle={5}
                          lineWidth={45}
                          radius={50}
                          data={[
                            {
                              title: countPrimary[0]?.[0],
                              value: countPrimary[0]?.[1],
                              color: "#E38627",
                            },
                            {
                              title: countPrimary[1]?.[0],
                              value: countPrimary[1]?.[1],
                              color: "#C13C37",
                            },
                          ]}
                        />
                        <div className="feelings">
                          {countPrimary?.map((item) => {
                            return (
                              <p style={{}}>
                                {item[3]}% {item[0]}
                              </p>
                            );
                          })}
                        </div>
                        {/* emotional wheel */}
                      </div>

                      <div className="title-and-header">
                        <p className="title">Associated Feelings</p>
                        <img
                          style={{ height: "1em", width: "1em" }}
                          src={`${associatedCommunity ? upArrow : downArrow}`}
                          alt="down arrow"
                          onClick={() => {
                            setAssociatedCommunity(!associatedCommunity);
                          }}
                        />
                      </div>
                      <div className="Associated">
                        <div
                          className={` ${
                            associatedCommunity
                              ? "display-emotions"
                              : "feelings"
                          }`}
                        >
                          {countPrimary.map((item) => {
                            return (
                              <div>
                                <p>{item[0]}</p>
                                <div
                                  style={{
                                    background: `linear-gradient(to left, lightgray ${
                                      100 - item[3]
                                    }%, #2449A7 ${item[3]}% `,
                                  }}
                                  className="bar"
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )
                )}
                {bodySensations.length === 0 && view === "Sensations" ? (
                  <div>Loading</div>
                ) : (
                  view === "Sensations" && (
                    <div className="Feelings">
                      {countBodySensations &&
                        countBodySensations.length > 0 && (
                          <div className="Primary">
                            <PieChart
                              center={[50, 50]}
                              lengthAngle={360}
                              paddingAngle={5}
                              lineWidth={45}
                              radius={50}
                              className="pie-chart"
                              data={[
                                {
                                  title: countBodySensations[0][0],
                                  value: countBodySensations[0][1],
                                  color: "#E38627",
                                },
                              ]}
                            />
                            <div className="feelings">
                              {countBodySensations?.map((item) => {
                                return (
                                  <p style={{}}>
                                    {item[2]}% {item[0]}
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        )}
                    </div>
                  )
                )}
                {view === "Actions" && (
                  <Carousel>
                    <CarouselItem>
                      <p> Coming Soon</p>
                    </CarouselItem>
                    <CarouselItem>
                      <p> Coming Soon</p>
                    </CarouselItem>
                  </Carousel>
                )}
              </div>
            </div>
          </>
        )}
        {headerTitle === "Community Health" && (
          <div className="content">
            <DetailSubHeader title="SEE">
              <div className="label-and-dropdown">
                <label>{saySectionLabel}</label>
                {
                  <Dropdown
                    options={options}
                    comingSoonOptions={comingSoonOptions}
                    choice={curItem}
                    setChoice={setCurItem}
                    comingSoonPosition="left"
                  />
                }
              </div>
            </DetailSubHeader>
            {/* First three round numbers */}
            <div className="risk-circles">
              <SeeCircle
                data={countyData}
                label={countyLabel}
                handleClick={() => setChartShow(true)}
              />

              <SeeCircle
                data={stateData}
                label={stateLabel}
                handleClick={linkToStateMap}
              />
              <SeeCircle
                data={countryData}
                label={countryLabel}
                handleClick={linkToNationalMap}
              />
            </div>
            {isChartShow && (
              <div className="lineChart position-relative">
                <button
                  onClick={() => setChartShow(false)}
                  className="position-absolute end-0 btn-sm btn-primary lineChart-close"
                >
                  X
                </button>
                <LineChart chartData={chartData} cData={data} />
              </div>
            )}
          </div>
        )}
      </DetailSubsection>
    </>
  );
}

export default SeeComponent;
