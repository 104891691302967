export const MinuteCard = ({header, text, time, onClick, children}) => {
    
    console.log('head',header)

    return (
        
    <div 
        className="minute-card"
        onClick={onClick} 
    >
        <div className="cvl-container">CVL</div>
        <h4 className="fw-bold" style={{ marginTop: "0.5rem" }}>{header}</h4>
        <p>{text}</p>
        {/* {text.map(line => <p>{line}</p>)} */}
        <p>🕙 {time} min</p>
        {children}
    </div>
    )
}