import React, { useRef, useState } from "react";
import ChevronDown from "../../images/chevron_down.png";
import ChevronUp from "../../images/chevron_up.png";

export const WidgetContainer = ({ name, comingSoon, children }) => {
  // const [expanded, setExpanded] = useState(name === 'Community Health' ? true : false)
  const [expanded, setExpanded] = useState(name === "Community Health" || name === "Emotional Health" ? true : false);
  const soon = useRef();
  return (
    <section className={`widget-container ${expanded && "expanded"} ${comingSoon && "coming-soon"}`}>
      <div className="top-section">
        <h2>
          {name.split(" ").map((word, index) => (
            <span key={index}>
              {word} <br />
            </span>
          ))}
          {comingSoon && (
            <span className="coming-soon" ref={soon}>
              Coming Soon!
            </span>
          )}
        </h2>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (!comingSoon) {
              //if this isn't a coming soon widget, expand it
              setExpanded(!expanded);
            } else {
              //display coming soon
              soon.current.style.display = "block";
            }
          }}
          className="chevron-button"
        >
          <img className="chevron" src={expanded ? ChevronUp : ChevronDown} alt="navigation arrow" />
        </button>
      </div>
      <div style={expanded ? { display: "block" } : { display: "none" }} className="content">
        {children}
      </div>
    </section>
  );
};
