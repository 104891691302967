import { useContext } from "react";
import { QuestionContext } from "../../../../../context/question.context";
import "../../../index.css";

const NavigationButtons = (props) => {
  const { resultsList, currentQuestion, changeQuestion } = useContext(QuestionContext);
  const { setReachedLimit, glossaryOpen, setIsAlertOpen } = props;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional, smooth scrolling animation
    });
  };

  const handleBack = () => {
    if (!glossaryOpen) {
      scrollToTop();
      if (currentQuestion > 1) changeQuestion(currentQuestion - 1);
      if (currentQuestion === 2 || currentQuestion === 4) {
        setReachedLimit(false);
      }
    }
    if (setIsAlertOpen) {
      setIsAlertOpen(false);
    }
  };

  // For Testing Purposes - Remove HandleBack once back/next logic is complete
  const handleNext = () => {
    if (!glossaryOpen) {
      scrollToTop();
      if (currentQuestion < 6 && resultsList.length >= currentQuestion) changeQuestion(currentQuestion + 1);
      if (currentQuestion === 2 || currentQuestion === 4) {
        setReachedLimit(false);
      }
    }
    if (setIsAlertOpen) {
      setIsAlertOpen(false);
    }
  };

  return (
    <div>
      {currentQuestion < 6 ? (
        <div className="ff-btn-container">
          {currentQuestion > 1 ? (
            <button className="ff-btn" onClick={handleBack} style={{ opacity: glossaryOpen ? 0.5 : 1 }}>
              Back
            </button>
          ) : null}
          <button
            className="ff-btn"
            style={{
              opacity: resultsList.length >= currentQuestion && !glossaryOpen ? "1" : "0.5",
            }}
            onClick={handleNext}
          >
            {" "}
            Next
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default NavigationButtons;
