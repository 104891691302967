import React from "react"
import { useEffect, useRef, useState } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"


import { Line } from "react-chartjs-2"

import { getColorFromValue } from "./DetailContent/getColorFromValue"

import Up from '../../images/chevron_up.png'
import Down from '../../images/chevron_down.png'

//imports to use data to inform y-axis increments
import { useSelector } from "react-redux";
import { selectCommunityData } from "../../store/communityHealthSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export function LineChart({ chartData, cData }) {
  const riskValue = useSelector(selectCommunityData)

  const [thisischart, setChart] = useState(null);

  const isValidChartData = chartData["0"] !== 0
  const values = [chartData.kUSHistorical, chartData.kStateHistorical, chartData.kValues]

  let data = {}
  let options = {}

  const chartRef = useRef(null);

  useEffect((thisischart) => {
    const chart = chartRef.current;
    const upArrow = new Image();
    upArrow.src = Up

    const downArrow = new Image();
    downArrow.src = Down

    // https://civilience.maps.arcgis.com/apps/instant/basic/index.html?appid=cb034431e2cb4a00903736cc62a6ca78
    
    if (chart) {
      setChart(chart)

      ChartJS.register({
        id: 'customLegend',
        afterDraw: function (thisischart, chart, easing) {

          const { ctx, chartArea: { left, right, top, bottom }, scales:
            { x, y }, _metasets } = thisischart;

            const stateLabel= _metasets[1].label;
            const countryLabel = _metasets[2].label

            const countyLabelLength = ctx.measureText('County').width
            const stateLabelLength = ctx.measureText(stateLabel).width
            const countryLabelLength = ctx.measureText(countryLabel).width

          ctx.save();
          ctx.font = 'bolder 12px sans-serif';
          ctx.fillStyle = '#36454F';
          // County
          ctx.fillText('County', right + 10, _metasets[0].data[6].y)
          // State
          ctx.fillText(stateLabel, right + 12 , _metasets[1].data[6].y);
          // Country
          ctx.fillText(countryLabel, right + 14, _metasets[2].data[6].y);

          {/* <a target="_blank" href="https://icons8.com/icon/LJqfsKb7ioRy/chevron">chevron</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a> */ }

          // Placing uppermost and lowest values and arrows
          ctx.fillStyle = '##818589';
          ctx.font = 'bolder 14px sans-serif';
          ctx.fillText('100', left - 11 , top - 40);
          ctx.fillText('0', left - 5, bottom + 64);
         
          //top extension of y-axis
          ctx.beginPath()
          ctx.lineWidth = 2;
          ctx.moveTo(left, top);
          ctx.lineTo(left, top-26);
          ctx.stroke();

          //bottom extension of y-axis
          ctx.beginPath()
          ctx.lineWidth = 2;
          ctx.moveTo(left, bottom+25);
          ctx.lineTo(left, bottom+43);
          ctx.stroke();

          // ctx.drawImage( LineImg, left-5 , top, 10, 10);

          ctx.drawImage(upArrow, left-5, top-29, 10, 10); 
          ctx.drawImage(downArrow, left-5, bottom + 37, 10, 10);

        },
        
      })
    }

  }, []);

  console.log(thisischart)

  if (isValidChartData) {
    const labels = Object.keys(chartData.K)

    Object.entries(labels).forEach(([key, value]) => {
      delete labels[key]
      const dt = value.substring(2)
      labels[key] = dt.substring(0, 2) + "/" + dt.substring(2, 4)
    })

    const kValues = Object.values(chartData.K)
    const kStateHistorical = Object.values(chartData.state_historical)
    const kUSHistorical = Object.values(chartData.us_historical)

    options = {
      responsive: true,
      layout: {
        padding: {
          right: (context) => {
            return (('context', context.chart.ctx.measureText('County').width + 40))
          },
          left: 20,
          bottom: 46,
          top:15
        }
      },
      scales: {
       
        y: {
          ticks: {
            stepSize: 2,
          },
          grid: {
          color: ['black','#E3E3E3','#E3E3E3','#E3E3E3','#E3E3E3','#E3E3E3'],
          lineWidth: 2
        }
      },
      x: {
        grid: {
          // tickLength:0, 
          color: ['black','#E3E3E3','#E3E3E3','#E3E3E3','#E3E3E3', '#E3E3E3','#E3E3E3'],
          lineWidth: 2
        }
      }
      
        
      },
      
      plugins: {
        title: {
          display: true,
          text: "Risk Over Time",
          font: {
            size: 20,
          },
        },
        legend: {
          display: false,
          position: 'top',
          labels: {
            usePointStyle: true,
          },
        },

      },
    }
    data = {
      labels,
      datasets: [
        {
          label: chartData.county_name,
          data: kValues,
          borderColor: getColorFromValue(
            cData.K_historical
          ),
          backgroundColor: getColorFromValue(
            cData.K_historical
          ),
          pointStyle: "rect",
          // yAxisID: 'y',
        },
        {
          label: chartData.state,
          data: kStateHistorical,
          borderColor: getColorFromValue(cData.K_state),
          backgroundColor: getColorFromValue(
            cData.K_state
          ),
          pointStyle: "rect",
          // yAxisID: 'y1',
        },
        {
          label: "USA",
          data: kUSHistorical,
          borderColor: getColorFromValue(
            cData.k_US
          ),
          backgroundColor: getColorFromValue(
            cData.k_US
          ),
          pointStyle: "rect",
          // yAxisID: 'y1',
        },
      ],
    }
  }
  // console.log('thisischart', thisischart)
  return !isValidChartData ?
    <span>Data for this county is currently unavailable</span> :
    <Line options={options} ref={chartRef} data={data}
    />

}