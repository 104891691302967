import { configureStore } from "@reduxjs/toolkit";
import communityHealthReducer from "./communityHealthSlice"
// import { dynamoDBFetch } from "./emotionalHealthSlice";

const store = configureStore({
  reducer: {
    communityHealth: communityHealthReducer,
  },
})

export default store;
