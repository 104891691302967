import React, { useState } from "react";
import { DetailSubHeader } from "./DetailSubHeader";
import { SayCategoryForm } from "./SayCategoryForm";
import { DetailSubsection } from "./DetailSubsection";

function SayComponent({sayComponentText}) {
    const [category, setCategory] = useState();

    return (
        <DetailSubsection name="Say">
            <DetailSubHeader title="SAY">
                <SayCategoryForm category={category} setCategory={setCategory} />
            </DetailSubHeader>
            <div className="content">
                <h4 className="question Mytooltip">
                    {sayComponentText}
                    <p className="Mytooltiptext wide">
                        You can submit only one answer for each weekly question, so make it count! As always, please keep your comments civil and on-topic.
                    </p>
                </h4>
                <iframe
                    id="say-survey"
                    src="https://main.d26fb7k5zbcwdt.amplifyapp.com/html/say/index.html"
                    //src="http://127.0.0.1:5500/html/say/index.html"
                    width="100%"
                    height="215px"
                    title="One Minute Survey"
                    allowFullScreen="allowfullscreen"
                ></iframe>
            </div>
        </DetailSubsection>
    );
}

export default SayComponent;
