import GLOSSARY_DATA from "../../glossary-data";
import { useState } from "react";
import { majorList } from "../../MajorFeelingForm";
import LeftArrow from "./../../../../../images/left-arrow.png";
import RightArrow from "./../../../../../images/right-arrow.png";

export const PrimaryGlossaryV2 = (props) => {
  // All Primary Glossary Items
  // Collects ALL information corresponding to the primary mood the user chose
  const glossaryData = GLOSSARY_DATA[0].items.find((item) => item.id === props.id);
  // console.log("glossaryData: ", glossaryData);

  //description OR altdescription text - will switch
  const [displayData, setDisplayData] = useState("description");
  // Passing Mood id to display the correct mood information and scale the corresponding color
  const [colorId, setColorId] = useState(props.id);
  const [scaleColor, setScaleColor] = useState(props.id);
  // Used for changing color between the 1-2 options
  const [activeDescription, setActiveDescription] = useState("description");

  //OPEN/CLOSE DEFINITION POPUP
  const popupCallback = () => {
    // setIsPopupOpen(!isPopupOpen);

    //close GlossaryPopup MajorFeelingForm
    props.handleGlossaryPopup();

    // Resets DisplayGlossary to Null so we can see the normal primary feeling
    props.handleDisplayGlossary();
  };

  //MATCH DEFINITION TO DISPLAY & SCALE COLOR ICON
  const handleColorChange = (id) => {
    setColorId(id);
  };

  //DISPLAY DEFINITION, SYNONYM, EXAMPLE SENTENCE FOR FIRST/SECOND FEELING OPTIONS
  const handleChange = (infoToDisplay) => {
    infoToDisplay === "description" ? setDisplayData("description") : setDisplayData("altdescription");
  };

  return (
    <div className="primary-glossary-container">
      <div className="primary-header-container" style={{ backgroundColor: `${glossaryData.activeColor}` }}>
        <img src={glossaryData.icon} className="primary-glossary-icon" alt="mood icon" />
        <span className="primary-header" style={{ color: `${glossaryData.fontColor}` }}>
          {glossaryData.headerDescription}
        </span>
        <div className="primary-glossary-close-container" onClick={popupCallback}>
          <span className="primary-glossary-close"></span>
        </div>
      </div>

      <div className="glossary-split-container">
        <span className="glossary-split"></span>
      </div>

      {/* Left-Right Container */}
      <div className="primary-glossary-item-container">
        {/* Left - Arrows */}
        <div className="glossary-arrow-container">
          <div className="glossary-left-arrow-container">
            <span className="glossary-arrow-text">Pleasant Feelings</span>
            <svg width="50%" height="5">
              <image href={LeftArrow} className="glossary-arrow" />
            </svg>
          </div>
          <div className="glossary-color-container">
            {majorList.map((color) => {
              return (
                <div
                  className={`glossary-color ${scaleColor === color.id ? "glossary-scale" : ""}`}
                  style={{ backgroundColor: `${color.activeColor}` }}
                  key={color.id}
                ></div>
              );
            })}
          </div>
          <div className="glossary-right-arrow-container">
            <svg width="50%" height="5">
              <image href={RightArrow} className="glossary-arrow" />
            </svg>
            <span className="glossary-arrow-text">Unpleasant Feelings</span>
          </div>
        </div>

        {/* 1-2 Description Container i.e. Aliveness / Joy */}
        <div className="glossary-right-container">
          {/* Description */}
          <div className="glossary-description-container">
            <div className="glossary-item-container" style={{ border: `1px solid ${glossaryData.activeColor}`, borderRadius: "25px" }}>
              <div
                className={"glossary-description-first"}
                style={{
                  backgroundColor: `${
                    activeDescription === "description" || typeof glossaryData.altdefinition === "undefined"
                      ? glossaryData.activeColor
                      : ""
                  }`,
                  color: `${activeDescription === "description" ? `${glossaryData.fontColor}` : "#000000"}`,
                }}
                onClick={() => {
                  handleChange("description");
                  setActiveDescription("description");
                }}
              >
                <span className="glossary-description">{glossaryData.description}</span>
              </div>

              {/* Alt Description If it exists */}
              {typeof glossaryData.altdescription !== "undefined" ? (
                <div
                  className="glossary-description-second"
                  style={{
                    backgroundColor: `${activeDescription === "altdescription" ? glossaryData.activeColor : ""}`,
                    color: `${activeDescription === "altdescription" ? `${glossaryData.fontColor}` : "#000000"}`,
                  }}
                  onClick={() => {
                    handleChange("altdescription");
                    setActiveDescription("altdescription");
                  }}
                >
                  <span>{glossaryData.altdescription}</span>
                </div>
              ) : (
                <div className="glossary-description-second"></div>
              )}
            </div>
          </div>

          {/* Definition */}
          <div className="primary-glossary-definition-container">
            <span className="primary-glossary-text-header">Definition:</span>
            <span className="glossary-text">
              {/* || typeof glossaryData.altdefinition === "undefined" -> sets data to first if user changes color after previously choosing an alt definition */}
              {displayData === "description" || typeof glossaryData.altdefinition === "undefined"
                ? glossaryData.definition
                : glossaryData.altdefinition}
            </span>
          </div>

          {/* Synonym */}
          <div className="primary-glossary-synonym-container">
            <span className="primary-glossary-text-header">Synonyms</span>
            <span className="glossary-text">
              {displayData === "description" || typeof glossaryData.altdefinition === "undefined"
                ? glossaryData.synonym
                : glossaryData.altsynonym}
            </span>
          </div>

          {/* Example Sentence */}
          <div className="glossary-sentence-container">
            <span className="primary-glossary-text-header">Example Sentence</span>
            <span className="glossary-text">
              {displayData === "description" || typeof glossaryData.altdefinition === "undefined"
                ? glossaryData.sentence
                : glossaryData.altsentence}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrimaryGlossaryV2;
