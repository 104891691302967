import { createContext, useState } from "react";

// Holds users choice for questions Flow tracker questions
export const BackgroundContext = createContext({
  isModalOpen: false,
});

export const BackgroundProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  //Pass boolean true = blur background, false = remove background
  const applyOpacity = () => {
    setIsModalOpen(true);
  };

  const removeOpacity = () => {
    setIsModalOpen(false);
  };

  // Pass list and functions as props to our forms
  const value = { isModalOpen, removeOpacity, applyOpacity };

  return <BackgroundContext.Provider value={value}>{children}</BackgroundContext.Provider>;
};
