import "../index.css";
import { useContext, useState, useCallback, useEffect } from "react";
import { QuestionContext } from "../../../context/question.context";
import Myactionsicons from "./progressbar/Myactionsicons";
import NavigationButtons from "./components/buttons/NavigationButtons";
import CVLIcon from "./../../../images/cvl-icon.svg";

const ActionForm = ({ displayProgress }) => {
  const { resultsList, addResultToList, changeQuestion, currentQuestion } = useContext(QuestionContext);
  const [text, setText] = useState("");
  const [charCount, setCharCount] = useState(0);

  const [content, setContent] = useState("");

  //Initialize form with answer if user has already answered this question
  useEffect(() => {
    if (resultsList.length > 4) {
      let currentAnswer = resultsList[4].answer;
      setContent(currentAnswer);
    }
  }, []);

  //restricts textarea characters displayed to 500 chars
  const setFormattedContent = useCallback(
    (e) => {
      let text = e.target.value;

      //Only first 100 characters displayed on screen with textarea
      setContent(text.slice(0, 100));
    },
    [setContent]
  );

  // User input
  const handleChange = (e) => {
    //Only pass first 100 characters to resultList
    const formAnswer = e.target.value.slice(0, 100);

    const formText = { answer: formAnswer, question: 5 };
    setText(formText);

    if (formAnswer.length <= 100) {
      setCharCount(formAnswer.length);

      formAnswer.length > 0 ? addResultToList(formText) : resultsList.splice(currentQuestion - 1, 1);
    }
  };

  // Once user hits submit/replace with next then we will
  const handleSubmit = (e) => {
    e.preventDefault();

    // Passing Context User's Choice - Only update if user add text
    if (text !== "") {
      // Increment Question to render next question
      changeQuestion(currentQuestion + 1);
    }
  };

  return (
    <div>
      <div className="ff-header-container" style={{ marginLeft: "20px"}}>
        <p className="ff-header" style={{fontSize: "25px"}}>My Feelings Flow Tracker</p>
        <div className="ff-img-container" style={{marginRight: "20px"}}>
          <svg width="21" height="20">
            <image href={CVLIcon} />
          </svg>
        </div>
      </div>

      {displayProgress ? <Myactionsicons /> : null}

      <div className="ff-split"></div>

      <div>
        <div className="ff-subheader-container">
          <h2 className="ff-subheader">Action(s)</h2>
        </div>

        <p className="ff-question">What actions did you take, to process those feelings?</p>
      </div>
      <div className="action-form-container">
        <form className="action-form" onSubmit={handleSubmit}>
          <div className="textarea-container">
            <textarea
              rows={14}
              placeholder="I went for a run..."
              onChange={(e) => {
                handleChange(e);
                setFormattedContent(e);
              }}
              value={content}
            ></textarea>
            <div className="textarea-count" style={{ color: `${charCount === 0 ? "#ABABAB" : "#000000"}` }}>
              Characters with spaces {charCount}/100
            </div>
          </div>

          <div>
            <NavigationButtons />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ActionForm;
